import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { FeatureFlagGate } from "@utils/FeatureFlags/FeatureFlagGate";
import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { formatDurationToMinSec, formatVideoSecondsToHHMMSS } from "@utils/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { PopoverPanel, Small, VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

import { ApiMediaCommentsCommentTypeChoices } from "@api/graphql/types-and-hooks";
import { EmojiCommentCard } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentCard";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { StatCard } from "@pages/VideoSessions/components/StatCard/StatCard";
import { EmojiButton } from "@pages/VideoSessions/components/Utils/EmojiButton";
import { EMOJI_BUTTONS } from "@pages/VideoSessions/const";
import { EmojiButtonInterface } from "@pages/VideoSessions/types";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface EmojiCommentButtonsProps {
  onEmojiClick: (emoji: EmojiButtonInterface) => void;
  disabled: boolean;
  selectedEmoji: EmojiButtonInterface | null;
  onCommentSubmit: (comment: string, timestamp: number) => void;
  onClose: () => void;
  getCurrentTime: () => number;
  playerRef: React.RefObject<VideoPlayerHandle>;
  commentCount?: string;
  media: MediaCardType;
}

type CommentCount = {
  comment_type: string;
  count: number;
};

const getEmojiCount = (value: string, commentsCount?: string): number => {
  if (!commentsCount) return 0;
  const parsedCounts: CommentCount[] = JSON.parse(commentsCount);
  return parsedCounts.find((comment) => comment.comment_type.toLowerCase() === value.toLowerCase())?.count || 0;
};

const getRedirectUrl = (
  pathname: string,
  mediaId: string,
  startTime: number,
  endTime: number,
  isVideoDetailsPage: boolean,
) =>
  isVideoDetailsPage
    ? `${pathname}?startTime=${startTime}&endTime=${endTime}`
    : `${pathname}/${mediaId}?startTime=${startTime}&endTime=${endTime}`;

export const EmojiCommentButtons: React.FC<EmojiCommentButtonsProps> = ({
  onEmojiClick,
  disabled = false,
  selectedEmoji,
  onCommentSubmit,
  onClose,
  getCurrentTime,
  playerRef,
  commentCount,
  media,
}) => {
  const { pathname } = useLocation();
  const { isUserRBT, isVideoDetailsPage } = useVideoSessionData();
  const mediaId = media.id;
  const [aggressionFocused, setAggressionFocused] = useState<boolean>(false);
  const [cryingFocused, setCryingFocused] = useState<boolean>(false);

  const flags = useFlags<FeatureFlags>();
  const maladaptiveBehaviorEnabled = flags["maladaptive-behaviors"];

  const handlePlayPause = (pause: boolean) => {
    const player = playerRef.current?.player;
    if (player) pause ? player.pause() : player.play();
  };

  const handleEmojiButtonClick = (emoji: EmojiButtonInterface) => {
    handlePlayPause(true);
    onEmojiClick(emoji);
  };

  const handleClose = () => {
    onClose();
    handlePlayPause(false);
  };

  const handleSubmit = (comment: string, timestamp: number) => {
    onCommentSubmit(comment, timestamp);
    handlePlayPause(false);
  };

  const buttonList = useMemo(
    () =>
      isVideoDetailsPage && isUserRBT
        ? EMOJI_BUTTONS.filter((item) => item.value === ApiMediaCommentsCommentTypeChoices.Question)
        : EMOJI_BUTTONS,
    [isVideoDetailsPage, isUserRBT],
  );

  const isNonContinuousInteractionsIntervals =
    media.mediaClassifications?.nonContinuousInteractions?.[0]?.intervals || [];
  const isContinuousInteractionsIntervals = media.mediaClassifications?.continuousInteractions?.[0]?.intervals || [];

  const aggressionPopoverContent = useMemo(
    () => (
      <div>
        <Small displayType="normal" colorType="secondary">
          Aggression Detected
        </Small>
        <Small displayType="normal" colorType="primary">
          Detected {media.mediaClassifications?.nonContinuousInteractions?.[0]?.interactionCount || 0} moments of client
          engaging in kicking or punching another child.
        </Small>
        {isNonContinuousInteractionsIntervals.length > 0 && (
          <>
            <Small displayType="normal" colorType="secondary" className="mt-2">
              Events
            </Small>
            <Small displayType="normal" colorType="primary">
              {isNonContinuousInteractionsIntervals.map((item, index) => (
                <span
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.location.href = getRedirectUrl(
                      pathname,
                      mediaId,
                      item?.startTime || 0,
                      item?.endTime || 0,
                      isVideoDetailsPage,
                    );
                  }}
                  className="text-green-700 cursor-pointer"
                >
                  ({formatVideoSecondsToHHMMSS(item?.startTime || 0)} - {formatVideoSecondsToHHMMSS(item?.endTime || 0)}
                  ){index < isNonContinuousInteractionsIntervals.length - 1 ? "," : ""}
                  &nbsp;
                </span>
              ))}
            </Small>
          </>
        )}
      </div>
    ),
    [isNonContinuousInteractionsIntervals, pathname, mediaId, isVideoDetailsPage],
  );

  const cryingPopoverContent = useMemo(
    () => (
      <div>
        <Small displayType="normal" colorType="secondary">
          Crying/Screaming Moments
        </Small>
        <Small displayType="normal" colorType="primary">
          Detected {isContinuousInteractionsIntervals.length} distinct periods of client engaging in crying or screaming
        </Small>
        {isContinuousInteractionsIntervals.length > 0 && (
          <>
            <Small displayType="normal" colorType="secondary" className="mt-2">
              Events
            </Small>
            <Small displayType="normal" colorType="primary">
              {isContinuousInteractionsIntervals.map((item, index) => (
                <span
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.location.href = getRedirectUrl(
                      pathname,
                      mediaId,
                      item?.startTime || 0,
                      item?.endTime || 0,
                      isVideoDetailsPage,
                    );
                  }}
                  className="text-green-700 cursor-pointer"
                >
                  ({formatVideoSecondsToHHMMSS(item?.startTime || 0)} - {formatVideoSecondsToHHMMSS(item?.endTime || 0)}
                  ){index < isContinuousInteractionsIntervals.length - 1 ? "," : null}
                  &nbsp;
                </span>
              ))}
            </Small>
          </>
        )}
      </div>
    ),
    [isContinuousInteractionsIntervals, pathname, mediaId, isVideoDetailsPage],
  );

  const infoPopoverContent = useMemo(
    () => (
      <div>
        <Small displayType="normal" colorType="secondary">
          Data Definitions
        </Small>
        <Small displayType="normal" colorType="primary">
          <b>Aggression Moments:</b> counts 5-second intervals of where any instance of aggression was identified.
        </Small>
        <Small displayType="normal" colorType="primary" className="mt-4">
          <b>Crying/Screaming Duration:</b> Total elapsed time the client was crying during the session.
        </Small>
      </div>
    ),
    [],
  );

  return (
    <div className="relative z-50">
      {selectedEmoji && (
        <EmojiCommentCard
          selectedEmoji={selectedEmoji}
          currentTime={getCurrentTime()}
          onSubmit={handleSubmit}
          onClose={handleClose}
          duration={playerRef.current?.player?.duration()}
          className="absolute -top-40 left-1/2 transform -translate-x-1/2 z-10"
        />
      )}
      <div
        className={`lg:-ml-4 sm:p-4 flex flex-wrap ${maladaptiveBehaviorEnabled ? "justify-between" : "justify-center"}  gap-2 sm:gap-4`}
      >
        <div className="flex gap-2">
          {buttonList.map((button) => (
            <EmojiButton
              key={button.value}
              button={button}
              onEmojiClick={handleEmojiButtonClick}
              disabled={disabled}
              count={commentCount && !mediaId ? getEmojiCount(button.value, commentCount) : null}
            />
          ))}
          {isVideoDetailsPage && isUserRBT && (
            <div className="flex items-center justify-start">
              <Small className="italic">Questions will be sent directly to your BCBA.</Small>
            </div>
          )}
        </div>
        <FeatureFlagGate flagKey={"maladaptive-behaviors"}>
          <div className="flex gap-2 items-center justify-end">
            <PopoverPanel onBlur={() => setAggressionFocused(false)} content={aggressionPopoverContent}>
              <StatCard
                label="Aggression Moments:"
                onClick={() => setAggressionFocused((prev) => !prev)}
                focused={aggressionFocused}
                value={media.mediaClassifications?.nonContinuousInteractions?.[0]?.interactionCount || 0}
              />
            </PopoverPanel>
            <PopoverPanel onBlur={() => setCryingFocused(false)} content={cryingPopoverContent}>
              <StatCard
                focused={cryingFocused}
                onClick={() => setCryingFocused((prev) => !prev)}
                icon={<img src="/cry-icon.png" className="h-4 w-4 text-red-500 -mt-1" />}
                value={formatDurationToMinSec(
                  media.mediaClassifications?.continuousInteractions?.[0]?.totalDuration || 0,
                )}
              />
            </PopoverPanel>
            <PopoverPanel content={infoPopoverContent}>
              <QuestionMarkCircleIcon className="h-6 w-6 text-limestone-600 mt-1" />
            </PopoverPanel>
          </div>
        </FeatureFlagGate>
      </div>
    </div>
  );
};
