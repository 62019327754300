import { Navigate, useLocation } from "react-router-dom";

import { AssessmentType } from "@api/graphql/types-and-hooks";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export const getProperABRedirectPathFromReport = (report: AssessmentType) => {
  const checkStatus = (status: string | undefined, validStates: string[]): boolean =>
    status !== undefined && validStates.includes(status);

  const getReportSection = () => {
    const statusChecks = [
      {
        condition: report.assessmentStatus === "COMPLETED",
        path: "review-and-sign",
      },
      {
        condition: checkStatus(report.aiReport2PredictionStatus, [
          "REPORT_FIELD_PREDICTION_PENDING",
          "REPORT_FIELD_PREDICTION_PREDICTED",
          "FAILURE",
        ]),
        path: "report-summary-part-2",
      },
      {
        condition: checkStatus(report.aiStgPredictionStatus, [
          "GOAL_PREDICTION_PENDING",
          "GOAL_PREDICTION_PREDICTED",
          "FAILURE",
        ]),
        path: "short-term-goals",
      },
      {
        condition: checkStatus(report.aiLtgPredictionStatus, [
          "GOAL_PREDICTION_PENDING",
          "GOAL_PREDICTION_PREDICTED",
          "FAILURE",
        ]),
        path: "long-term-goals",
      },
      {
        condition: checkStatus(report.aiReport1PredictionStatus, [
          "REPORT_FIELD_PREDICTION_PENDING",
          "REPORT_FIELD_PREDICTION_PREDICTED",
          "FAILURE",
        ]),
        path: "report-summary-part-1",
      },
      {
        condition: !!report?.learner?.id,
        path: "upload-files",
      },
    ];

    const matchedSection = statusChecks.find((check) => check.condition);
    return matchedSection?.path || "client-details";
  };

  return getReportSection();
};

export const AssessmentReportDetailsRedirect = () => {
  const { state } = useLocation();
  const { assessmentReport } = useAssessmentBuilderData();

  const pathToRedirectTo = getProperABRedirectPathFromReport(assessmentReport);

  return <Navigate to={pathToRedirectTo} replace state={state} />;
};
