import { Navigate, useLocation } from "react-router-dom";

import {
  ApiReportReportStatusChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  ApiReportTreatmentPlanAiLtgPredictionStatusChoices,
  ApiReportTreatmentPlanAiStgPredictionStatusChoices,
  ApiReportTreatmentPlanAiTargetPredictionStatusChoices,
  ReportSectionsType,
  ReportType,
} from "@api/graphql/types-and-hooks";
import { StatusType } from "@components/AssessmentReportsTable/AssessmentReportsTableV2";
import { InitialAssessmentPageRoute } from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { useAssessmentBuilderData as useAssessmentBuilderV2Data } from "@providers/AssessmentBuilderV2Provider.tsx";

// TODO: BACKPORT: Will need to figure out a generic method for computing pages depending on report type
// NOTE: This is part of a much bigger pattern that needs to get figured out on the UI side
// which is how to handle dynamic routing based on report types
export const AssessmentReportV2DetailsRedirect = () => {
  const { state } = useLocation();
  const { assessmentQuery } = useAssessmentBuilderV2Data();
  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node as ReportType;

  const pathToRedirectTo = getStatusAndRedirectRouteFromReport(assessment).redirectRoute;

  return <Navigate to={pathToRedirectTo} replace state={state} />;
};

export const getStatusAndRedirectRouteFromReport = (
  report?: ReportType,
): { status: StatusType; redirectRoute: InitialAssessmentPageRoute } => {
  const getFilteredSections = (location: string): ReportSectionsType[] =>
    report?.reportSections?.edges
      ?.map((edge) => edge?.node)
      .filter((section): section is ReportSectionsType => !!section && section.pageLocation === location) ?? [];

  const isSectionErrored = (sections: ReportSectionsType[]): boolean =>
    sections.some((section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure);

  const isSectionGenerated = (sections: ReportSectionsType[]): boolean =>
    sections.some(
      (section) =>
        section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted ||
        section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Pending,
    );

  const isSectionGenerating = (sections: ReportSectionsType[]): boolean =>
    sections.some(
      (section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
    );

  const isSectionPending = (sections: ReportSectionsType[]): boolean =>
    sections.length === 0 ||
    sections.every((section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Pending);

  const reportFiles = report?.reportFiles?.edges?.map((edge) => edge?.node) ?? [];
  const page1Sections = getFilteredSections("report-part-1");
  const page2Sections = getFilteredSections("report-part-2");
  const treatmentPlan = report?.reportTreatmentPlan;

  type StatusRule = {
    condition: () => boolean;
    result: { status: StatusType; redirectRoute: InitialAssessmentPageRoute };
  };

  const statusRules: StatusRule[] = [
    {
      condition: () => report?.reportStatus === ApiReportReportStatusChoices.Completed,
      result: { status: "Completed", redirectRoute: "review-and-sign" },
    },
    {
      condition: () => isSectionErrored(page2Sections),
      result: { status: "Error on Report Page 2", redirectRoute: "report-part-2" },
    },
    {
      condition: () => isSectionGenerating(page2Sections),
      result: { status: "Generating Report Page 2", redirectRoute: "report-part-2" },
    },
    {
      condition: () =>
        treatmentPlan?.aiStgPredictionStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices.Failure,
      result: { status: "Error Generating Short Term Goals", redirectRoute: "short-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiTargetPredictionStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices.Failure,
      result: { status: "Error Generating Targets", redirectRoute: "short-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiTargetPredictionStatus ===
        ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPending,
      result: { status: "Generating Targets", redirectRoute: "short-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiStgPredictionStatus ===
        ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPending,
      result: { status: "Generating Short Term Goals", redirectRoute: "short-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiStgPredictionStatus ===
          ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPredicted &&
        treatmentPlan?.aiTargetPredictionStatus ===
          ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPredicted,
      result: { status: "At Short Term Goals", redirectRoute: "short-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiLtgPredictionStatus === ApiReportTreatmentPlanAiLtgPredictionStatusChoices.Failure,
      result: { status: "Error Generating Long Term Goals", redirectRoute: "long-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiLtgPredictionStatus ===
        ApiReportTreatmentPlanAiLtgPredictionStatusChoices.GoalPredictionPending,
      result: { status: "Generating Long Term Goals", redirectRoute: "long-term-goals" },
    },
    {
      condition: () =>
        treatmentPlan?.aiLtgPredictionStatus ===
        ApiReportTreatmentPlanAiLtgPredictionStatusChoices.GoalPredictionPredicted,
      result: { status: "At Long Term Goals", redirectRoute: "long-term-goals" },
    },
    {
      condition: () => isSectionErrored(page1Sections),
      result: { status: "Error on Report Page 1", redirectRoute: "report-part-1" },
    },
    {
      condition: () => isSectionGenerating(page1Sections),
      result: { status: "Generating Report Page 1", redirectRoute: "report-part-1" },
    },
    {
      condition: () => isSectionGenerated(page1Sections) || page1Sections.length > 0,
      result: { status: "At Report Page 1", redirectRoute: "report-part-1" },
    },
    {
      condition: () => isSectionGenerated(page2Sections),
      result: { status: "At Report Page 2", redirectRoute: "report-part-2" },
    },
    {
      condition: () => !report?.reportClientDetails,
      result: { status: "Client Details Needed", redirectRoute: "client-details" },
    },
    {
      condition: () => reportFiles.length === 0,
      result: { status: "Clinical Files Needed", redirectRoute: "upload-files" },
    },
    {
      condition: () => reportFiles.length > 0 && isSectionPending(page1Sections),
      result: { status: "At Upload Files", redirectRoute: "upload-files" },
    },
  ];

  // Find first matching rule or return default
  const defaultResult: { status: StatusType; redirectRoute: InitialAssessmentPageRoute } = {
    status: "Unknown Status",
    redirectRoute: "upload-files",
  };

  return statusRules.find((rule) => rule.condition())?.result ?? defaultResult;
};
