import React from "react";

import { Small } from "@fronterahealth/frontera-ui-components";

interface StatCardProps {
  label?: string;
  value?: string | number;
  icon?: React.ReactNode;
  focused: boolean;
  onClick?: () => void;
}

export const StatCard: React.FC<StatCardProps> = ({ label, value, icon, focused, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center gap-2 rounded-lg border transition-all ${
        focused ? "border-limestone-900 ring-0.5 ring-limestone-900" : "border-[#EDEAE7]"
      } h-8 bg-gray-50 px-3 text-gray-700 cursor-pointer focus:outline-none`}
    >
      {icon ? <span className="text-xl">{icon}</span> : null}
      {label ? (
        <Small displayType="normal" className="text-limestone-800">
          {label}
        </Small>
      ) : null}
      {value ? (
        <Small displayType="normal" className="text-limestone-800">
          {value}
        </Small>
      ) : null}
    </div>
  );
};
