import { useCreateReportSectionsMutation } from "@api/graphql/types-and-hooks";
import { InitialAssessmentPageRoute } from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface useAddSectionProps {
  pageLocation: InitialAssessmentPageRoute;
  defaultNewSectionTitle: string;
  position?: "left" | "center" | "right";
}

export const useAddSection = ({ defaultNewSectionTitle, pageLocation }: useAddSectionProps) => {
  const { assessmentQuery } = useAssessmentBuilderData();

  const assessment = assessmentQuery.data?.getReports?.edges[0]?.node;
  if (!assessment)
    return {
      addSection: () => {
        throw new Error("Missing Assessment from useAddSection");
      },
      isAddingSection: false,
    };

  const createSection = useCreateReportSectionsMutation({});
  const { isPending } = createSection;
  const addSection = async (rankOrder: number) => {
    await createSection
      .mutateAsync({
        reportId: assessment.id,
        runWorkflow: false,
        sections: [{ title: defaultNewSectionTitle, pageLocation: pageLocation, rankOrder }],
      })
      .then(async (data) => {
        if (data.createReportSections?.status) {
          await assessmentQuery?.refetch();
        }
      });
  };

  return { addSection, isAddingSection: isPending || assessmentQuery.isFetching };
};
