import { XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useGetMediaSignedUrlQuery } from "@api/graphql/types-and-hooks";
import { VideoSideBar } from "@components/VideoSideBar/VideoSideBar";
import { VideoComments } from "@pages/VideoSessions/components/Comments/VideoComments";
import { HeaderContent } from "@pages/VideoSessions/components/HeaderContent";
import { VideoMoments } from "@pages/VideoSessions/components/Moments/VideoMoments";
import { VideoSummary } from "@pages/VideoSessions/components/Summary/VideoSummary";
import { VideoTranscript } from "@pages/VideoSessions/components/Transcript/VideoTranscript";
import { displayDate } from "@pages/VideoSessions/components/Utils/formatDate";
import { VideoContainer } from "@pages/VideoSessions/components/VideoContainer";
import { VideoPlayerReadyEvent } from "@pages/VideoSessions/components/VideoPlayerContent";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface VideoPlayerReadyEventI {
  duration: number;
}
declare global {
  interface WindowEventMap {
    videoPlayerReady: CustomEvent<VideoPlayerReadyEventI>;
  }
}

export const VideoSessionDetails = () => {
  const { mediaId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const startTime = parseFloat(searchParams.get("startTime") || "0");
  const endTime = parseFloat(searchParams.get("endTime") || "0");
  const [videoDuration, setVideoDuration] = useState(0);

  const [videoSeeking, setVideoSeeking] = useState<boolean>(false);

  const [activeCommentId, setActiveCommentId] = useState<string | null>(null);
  const { selectedMedia, selectedTab, currentTime, playerRef, clearFilters, isVideoDetailsPage } =
    useVideoSessionData();
  const { data, isFetching } = useGetMediaSignedUrlQuery(
    { mediaId: mediaId ?? "" },
    {
      enabled: Boolean(mediaId),
    },
  );
  const videoUrl = data?.getMediaByProviderByMediaId?.signedUploadUrl || "";

  const handleDurationChange = (duration: number) => {
    setVideoDuration(duration);
  };

  useEffect(() => {
    const handlePlayerReady = (e: VideoPlayerReadyEvent) => {
      setVideoDuration(e.detail.duration);
      if (location.state?.timestamp && playerRef.current && videoUrl) {
        // Add small delay to ensure player is fully initialized
        setTimeout(() => {
          playerRef.current?.seekTo(location.state.timestamp);

          if (location.state.shouldAutoPlay === false && playerRef.current?.player) {
            playerRef.current?.player.pause();
          }

          if (location.state.commentId) {
            setActiveCommentId(location.state.commentId);
          }
        }, 100);
      }
    };
    // Listen for player ready event
    window.addEventListener("videoPlayerReady", handlePlayerReady);

    return () => {
      window.removeEventListener("videoPlayerReady", handlePlayerReady);
    };
  }, [location.state, playerRef, videoUrl]);

  if (!selectedMedia || !selectedMedia.therapySession) return null;

  const handleTimeClick = (time: number) => {
    if (playerRef.current?.seekTo) {
      playerRef.current.seekTo(time);
    }
  };

  const handleMarkerClick = (timestamp: number, commentId: string) => {
    if (playerRef.current) {
      playerRef.current.seekTo(timestamp);
      if (playerRef.current.player) {
        playerRef.current.player.pause();
      }
    }
    setActiveCommentId(commentId);
  };

  const learnerName = selectedMedia.therapySession.sessionlearnerSet?.edges[0]?.node?.sessionLearners?.learnermetadata;
  const learnerFullName = learnerName ? `${learnerName.firstName} ${learnerName.lastName}` : "";
  const providerMeta = selectedMedia.therapySession.sessionproviderSet?.edges[0]?.node?.provider?.providermetadata;
  const sessionDate = selectedMedia.therapySession.createdDate;
  const startDate = new Date(sessionDate);
  const formattedDate = displayDate(videoDuration, startDate);

  return (
    <div className="fixed inset-0 z-50 bg-white flex flex-col">
      <div className="border-b border-gray-200 flex-shrink-0">
        <div className="px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-center">
            <button
              onClick={() => {
                clearFilters();
                navigate("/video-sessions");
              }}
              className="mr-4 p-2 rounded-full hover:bg-gray-100 transition-colors"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
            <HeaderContent
              learnerFullName={learnerFullName}
              providerLastName={providerMeta?.lastName ?? ""}
              providerFirstName={providerMeta?.firstName ?? ""}
              date={formattedDate}
              sessionRating={selectedMedia.therapySession.sessionRating ?? undefined}
            />
          </div>
        </div>
      </div>

      <div
        className={`flex-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-2 border-t border-limestone-200 overflow-y-auto ${isVideoDetailsPage ? "max-lg:min-h-[calc(100vh-18rem)] lg:overflow-y-auto lg:h-[calc(100vh-10rem)]" : ""}`}
      >
        <div className="col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2 bg-limestone-50 flex flex-col lg:overflow-y-auto lg:py-4 lg:px-4">
          <div className="w-full lg:max-w-5xl lg:mx-auto rounded-lg sm:rounded-2xl bg-transparent lg:pb-8 lg:pt-4 lg:px-6 px-0 flex flex-col">
            <div className="w-full space-y-4 lg:space-y-4">
              <div className={`relative w-full `}>
                {isFetching ? (
                  <div className="w-full h-[400px] flex items-center justify-center bg-limestone-50 rounded-lg">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary" />
                  </div>
                ) : (
                  <div className="relative" data-video-player>
                    {videoUrl && (
                      <VideoContainer
                        videoUrl={videoUrl}
                        onDurationChange={handleDurationChange}
                        onSeeking={() => setVideoSeeking(true)}
                        onSeeked={() => setVideoSeeking(false)}
                        media={selectedMedia}
                        onMarkerClick={handleMarkerClick}
                        onTimelineClick={handleTimeClick}
                        enabledFocus={false}
                        externalPlayerRef={playerRef}
                        data-video-container
                        onPlayerReady={() => {
                          if (startTime && endTime) {
                            playerRef.current?.seekTo(startTime);
                            playerRef.current?.playInRange(startTime, endTime);
                          }
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-1 bg-white lg:overflow-y-auto">
          <VideoSideBar currentTime={currentTime} />
          {selectedTab === "activity" ? (
            <VideoComments onClick={handleTimeClick} activeCommentId={activeCommentId ?? ""} />
          ) : selectedTab === "transcript" ? (
            <VideoTranscript currentTime={currentTime} onClick={(time) => playerRef.current?.seekTo(time)} />
          ) : selectedTab === "moments" ? (
            <VideoMoments videoSeeking={videoSeeking} media={selectedMedia} />
          ) : (
            <VideoSummary />
          )}
        </div>
      </div>
    </div>
  );
};
