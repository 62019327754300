import React from "react";

import { ClassificationSubClassEnum } from "@api/graphql/types-and-hooks";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { MomentCard } from "@pages/VideoSessions/components/Moments/MomentCard";
import { NoData } from "@pages/VideoSessions/components/NoData/NoData";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";

interface VideoMomentsProps {
  videoSeeking: boolean;
  media: MediaCardType;
}

export interface VideoMomentInterface {
  startTime: number;
  endTime: number;
  confidenceLevel: number;
  type?: ClassificationSubClassEnum | null;
}

export const convertMediaCardInteractionsToVideoMoments = (media: MediaCardType): VideoMomentInterface[] => {
  const continuousInteractions: VideoMomentInterface[] = (
    media.mediaClassifications?.continuousInteractions?.map(
      (interaction) =>
        interaction?.intervals?.map((interval) => ({
          startTime: interval?.startTime || 0,
          endTime: interval?.endTime || 0,
          confidenceLevel: interval?.confidenceLevel || 0,
          type: interaction.subclass,
        })) || [],
    ) || []
  ).flat();
  const nonContinuousInteractions: VideoMomentInterface[] = (
    media.mediaClassifications?.nonContinuousInteractions?.map(
      (interaction) =>
        interaction?.intervals?.map((interval) => ({
          startTime: interval?.startTime || 0,
          endTime: interval?.endTime || 0,
          confidenceLevel: interval?.confidenceLevel || 0,
          type: interaction.subclass as ClassificationSubClassEnum,
        })) || [],
    ) || []
  ).flat();
  return [...continuousInteractions, ...nonContinuousInteractions].sort((a, b) => a.startTime - b.startTime);
};

export const VideoMoments: React.FC<VideoMomentsProps> = ({ videoSeeking, media }) => {
  const momentsData: VideoMomentInterface[] = convertMediaCardInteractionsToVideoMoments(media);

  const isFetching = false;

  return (
    <div className="bg-white rounded-lg p-4 pt-4 gap-3 grid grid-flow-row auto-rows-max cursor-pointer max-lg:min-h-[calc(100vh-18rem)] lg:overflow-y-auto lg:h-[calc(100vh-10rem)]">
      <div className="flex flex-col max-[640px]:gap-4 gap-2">
        {isFetching ? (
          <DataLoading text="Activity Loading" />
        ) : momentsData.length ? (
          <div className="lg:overflow-y-auto lg:h-[calc(100vh-18rem)] flex flex-col sm:mt-3 gap-y-4 !overflow-visible">
            {momentsData.map((moment, index) => {
              return (
                <MomentCard
                  key={`${moment.startTime}-${moment.endTime}-${index}-${moment.type}`}
                  moment={moment}
                  onClick={() => {}}
                  videoSeeking={videoSeeking}
                />
              );
            })}
          </div>
        ) : momentsData.length ? (
          <NoData
            title="No Comments Found"
            description="No comments matched your filters. You can try broadening your filter choices to see activity."
            showBorder={false}
          />
        ) : (
          <NoData
            title="No Moments Detected"
            description="It looks like no moments were detected in this video, or we haven't processed this video yet. Moments are detected based on the interactions that occur in the video."
          />
        )}
      </div>
    </div>
  );
};
