import * as d3 from "d3";
import { useEffect, useState } from "react";

/**
 * Given a total duration in seconds,
 * return a "nice" step size so that
 * we get ~10–15 tick marks.
 */
const getTickStep = (durationInSeconds: number) => {
  // Convert duration to minutes for easier reading
  const durationInMinutes = durationInSeconds / 60;

  if (durationInMinutes <= 20) {
    // For videos up to ~20 minutes => 1 minute steps
    return 60; // (in seconds)
  } else if (durationInMinutes <= 50) {
    // For ~20–50 minutes => 2.5 minute steps
    return 150; // (2.5 * 60)
  } else {
    // More than 50 => 5 minute steps
    return 300; // (5 * 60)
  }
};

export const useGetTickMarks = (duration: number, dataPoints: unknown[], isLoading: boolean) => {
  const [xTicks, setXTicks] = useState<number[]>([]);

  useEffect(() => {
    const tickStep = getTickStep(duration);
    const xTicks = d3.range(0, duration, tickStep); // goes from 0, tickStep, 2*tickStep, ...
    setXTicks(xTicks);
  }, [duration, JSON.stringify(dataPoints), isLoading]);

  return xTicks;
};

export function formatTime(seconds: number): string {
  const totalSeconds = Math.floor(seconds);
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
}
