import { config } from "@config/config";
import { UseQueryResult } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ApiReportSectionsAiGeneratedStatusChoices,
  ApiReportTreatmentPlanAiLtgPredictionStatusChoices,
  ApiReportTreatmentPlanAiStgPredictionStatusChoices,
  ApiReportTreatmentPlanAiTargetPredictionStatusChoices,
  GetReportByIdQuery,
  ReportType,
  ReportTypeEnums,
  useGetReportByIdQuery,
} from "@api/graphql/types-and-hooks";
import { InitialAssessmentPageRoute } from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { getFilteredPageSectionsByLocation } from "@pages/AssessmentReportV2Details/Components/helper";

interface AssessmentBuilderDataI {
  assessmentQuery: UseQueryResult<GetReportByIdQuery, unknown>;
}

export const AssessmentBuilderContext = createContext<AssessmentBuilderDataI | undefined>(undefined);

export const useAssessmentBuilderData = () => {
  const context = useContext(AssessmentBuilderContext);
  if (context === undefined) {
    throw new Error("AssessmentReportDetails must be wrapped with AssessmentBuilderProvider");
  }
  return context;
};

export const AssessmentBuilderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { assessmentId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const assessmentQuery = useGetReportByIdQuery(
    {
      reportId: assessmentId || "<missing-assessment-id>",
      reportType: ReportTypeEnums.InitialAssessment,
    },
    {
      queryKey: ["get-report-by-id", assessmentId, pathname],
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: (query) => {
        const report = query?.state?.data?.getReports?.edges?.[0]?.node as ReportType;
        if (!report) return false;
        const reportTreatmentPlan = query?.state?.data?.getReports?.edges?.map((edge) => edge?.node)?.[0]
          ?.reportTreatmentPlan;

        const stgStatus = reportTreatmentPlan?.aiStgPredictionStatus;
        const ltgStatus = reportTreatmentPlan?.aiLtgPredictionStatus;
        const targetStatus = reportTreatmentPlan?.aiTargetPredictionStatus;

        const currentRoute =
          (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
          ("upload-files" as InitialAssessmentPageRoute);

        switch (currentRoute) {
          // Client Details, Upload Files, Review & Sign, and Prioritize will never require a re-fetch of the entire Report object
          case "client-details":
          case "upload-files":
          case "review-and-sign":
          case "prioritize":
            return false;
          // If we're on either of the report writeup pages, and one of the pages has a section that is pending
          // we'll need to keep refetching until its status is complete
          case "report-part-1": {
            const sectionsForCurrentRoute = getFilteredPageSectionsByLocation(report, currentRoute);
            const someSectionsStillPredicting = sectionsForCurrentRoute.some(
              (s) => s.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
            );
            // TODO: BACKPORT: Need to resolve this when FDM has this field populate
            // const prioritizePageStillGenerating = report.reportTreatmentPlan?.prioritizeFieldsAiStatus
            if (someSectionsStillPredicting) {
              return config.REPORT_POLLING_INTERVAL;
            } else {
              return false;
            }
          }
          // If we're on either of the report writeup pages, and one of the pages has a section that is pending
          // we'll need to keep refetching until its status is complete
          case "report-part-2": {
            const sectionsForCurrentRoute = getFilteredPageSectionsByLocation(report, currentRoute);
            const someSectionsStillPredicting = sectionsForCurrentRoute.some(
              (s) => s.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
            );
            if (someSectionsStillPredicting) {
              return config.REPORT_POLLING_INTERVAL;
            } else {
              return false;
            }
          }
          // Long Term Goals page if goals are still generating
          case "long-term-goals":
            if (ltgStatus === ApiReportTreatmentPlanAiLtgPredictionStatusChoices.GoalPredictionPending) {
              return config.REPORT_POLLING_INTERVAL;
            } else {
              return false;
            }
          // Short Term Goals page if short term goals or targets are still generating
          case "short-term-goals":
            if (
              stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPending ||
              targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPending
            ) {
              return config.REPORT_POLLING_INTERVAL;
            } else {
              return false;
            }
        }
      },
    },
  );

  if (!assessmentQuery || assessmentQuery.isLoading) return null;

  const assessmentReport = assessmentQuery.data?.getReports?.edges[0]?.node;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);

  if (assessmentReport && !assessmentReport.learner?.id && currentRoute !== "client-details")
    navigate(`client-details`);

  return <AssessmentBuilderContext.Provider value={{ assessmentQuery }}>{children}</AssessmentBuilderContext.Provider>;
};
