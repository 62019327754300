import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";

import { Heading } from "@fronterahealth/frontera-ui-components";

/**
 * https://docs.launchdarkly.com/sdk/client-side/react/react-web
 * This provider prevents loading of child nodes until LaunchDarkly is fully loaded
 * Synchronous loading of the LaunchDarkly SDK means the flags are unavailable until the App is mounted
 * Our app requires flags be available prior to App mount because we use flags for routing
 */
export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isLoading: isUserLoading } = useAuth0();
  const [isLDLoading, setIsLDLoading] = useState(true);

  // React providers must provide a consistent signature (i.e. accept children)
  const dummyProvider = ({ children }: { children: ReactNode }) => <>{children}</>;
  const [LDProvider, setLDProvider] = useState(() => dummyProvider);

  useEffect(() => {
    const initializeLDProvider = async () => {
      if (!user) return;

      const orgName = `${user[config.auth0Audience + "/orgName"] ?? "no-org"}`;

      const Provider = await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
        reactOptions: { useCamelCaseFlagKeys: false },
        context: {
          kind: "user",
          key: user.sub,
          name: user.name,
          email: user.email,
          custom: {
            orgName,
            sub: user.sub,
          },
        },
      });

      setLDProvider(() => Provider);
      setIsLDLoading(false);
    };

    initializeLDProvider();
  }, [user]);

  if (isUserLoading || isLDLoading || !user || !LDProvider) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <Heading type="h2" className="text-limestone-400">
          Loading...
        </Heading>
      </div>
    );
  }

  return <LDProvider>{children}</LDProvider>;
};
