import { Link } from "react-router-dom";

import { Button, Heading, Small } from "@fronterahealth/frontera-ui-components";

import { SavedGoalsBank } from "@components/GoalsBank/SavedGoals/SavedGoalsBank";
import { useAdminData } from "@providers/AdminDataProvider";

interface CreditHeaderProps {
  setDialogOpen: (open: boolean) => void;
  credits: number;
  refetch: () => void;
  isSelfServeUser: boolean;
}

export const CreditHeader: React.FC<CreditHeaderProps> = ({ setDialogOpen, credits, isSelfServeUser }) => {
  const { isFetchingAssessmentCredits } = useAdminData();

  // If we're a self-serve user and still loading credits data, show loading state
  if (isSelfServeUser && isFetchingAssessmentCredits) {
    return (
      <div className="flex mt-4 mb-4  w-full items-center justify-between">
        <Heading className="" type="h1">
          Assessment Reports
        </Heading>
        <SavedGoalsBank />
      </div>
    );
  }

  const showNewReportButton = credits > 0 || !isSelfServeUser;
  const showNoCreditsWarning = isSelfServeUser && credits === 0;

  return (
    <>
      <div className="flex mt-4 mb-4  w-full items-center justify-between">
        <Heading className="inline-flex items-center" type="h1">
          Assessment Reports
        </Heading>

        {showNewReportButton ? (
          <div>
            <SavedGoalsBank />
            <Button text="New Report" appearance="primary" onClick={() => setDialogOpen(true)} className="ml-4" />
          </div>
        ) : (
          <SavedGoalsBank />
        )}
      </div>
      {showNoCreditsWarning && (
        <div className="flex mt-4 items-center justify-between bg-[#ECEAE6] rounded-xl p-4">
          <div className="w-2/3">
            <Heading type="h4">No Credits</Heading>
            <Small>
              You have used all your credits. Please purchase more credits to create additional assessment reports.
            </Small>
          </div>
          <Link to="/products">
            <Button text="Buy Credits" appearance="primary" />
          </Link>
        </div>
      )}
    </>
  );
};
