import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { QueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";

import {
  AI_GENERATED,
  Button,
  Folder,
  FolderExplorer,
  GoalBankPanel,
  UserType,
} from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedTargetsType,
  TargetTimelineEstimationEnums,
  TargetType,
  TargetTypeEdge,
  useAddReportTreatmentPlanSuggestedTargetsMutation,
  useCreateUpdateReportTreatmentPlanTargetMutation,
  useGetReportTreatmentPlanShortTermAiSuggestedTargetsQuery,
  useGoalBankTargetsQuery,
} from "@api/graphql/types-and-hooks";
import { AddTargetsBankCard } from "@components/GoalsBank/AddTargetsBank/AddTargetsBankCard";
import { GoalBankTargetsSaveToFolder } from "@components/GoalsBank/SavedGoals/Targets/GoalBankTargetsSaveToFolder";
import { DeleteFolderDialog } from "@components/GoalsBank/dialogs/DeleteFolderDialog";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolder,
  getAiGeneratedFolderSection,
  getOrgFolderSection,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { convertDBString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

export interface AddTargetsBankProps {
  onAdd: () => void;
  assessmentId: string;
  shortTermGoalId: string;
  shortTermGoalBankId?: string;
  learnerId: string;
  targets: TargetTypeEdge[];
}

export const AddTargetsBank: React.FC<AddTargetsBankProps> = ({
  targets,
  assessmentId,
  onAdd,
  learnerId,
  shortTermGoalId,
  shortTermGoalBankId,
}) => {
  const [queryClient] = useState(() => new QueryClient());
  const { user } = useAuth0();
  const userType: UserType = !!user?.org_id ? "organization" : "selfServe";
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useGoalBankFolders();
  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);
  const [isSaveToFolderDialogOpen, setSaveToFolderDialogOpen] = useState<boolean>(false);
  const [selectedTargetIds, setSelectedTargetIds] = useState<string[]>([]);
  const [selectedAiTargetIds, setSelectedAiTargetIds] = useState<string[]>([]);
  const createUpdateTargetMutation = useCreateUpdateReportTreatmentPlanTargetMutation({});
  const addSuggestedTargets = useAddReportTreatmentPlanSuggestedTargetsMutation();
  const goalBankTargets = useGoalBankTargetsQuery(
    { folderId: selectedFolderId, shortTermGoalBankId },
    {
      queryKey: ["targets-" + selectedFolderId],
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      refetchInterval: 100000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );

  const { data: aiSuggestedTargets } = useGetReportTreatmentPlanShortTermAiSuggestedTargetsQuery(
    {
      reportId: assessmentId ? assessmentId : "<missing-report-id>",
      shortTermGoalId,
    },
    {
      queryKey: ["getShortTermAiSuggestedTargets", assessmentId, shortTermGoalId],
      enabled: !!assessmentId && !!shortTermGoalId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const aiTargets = aiSuggestedTargets?.getReportTreatmentPlanShortTermAiSuggestedTargets?.edges || [];

  // const aiTargets = assessmentReport?.reportTreatmentPlan?.
  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const aiSuggestedFoldersSection = getAiGeneratedFolderSection();

  const goalsToAdd = (): ReactNode[] => {
    if (selectedFolderId === AI_GENERATED) {
      return aiTargets.length
        ? aiTargets.map((edge) => {
            const node = edge?.node as AiSuggestedTargetsType;
            return node ? (
              <AddTargetsBankCard
                id={node.id}
                targetName={node.targetName}
                targetDescription={node.targetDescription}
                selected={selectedAiTargetIds.includes(node.id)}
                onClick={() => {
                  if (selectedAiTargetIds.includes(node.id)) {
                    setSelectedAiTargetIds([...selectedAiTargetIds.filter((id) => id != node?.id)]);
                    return;
                  }
                  setSelectedAiTargetIds([...selectedAiTargetIds, node.id]);
                }}
              />
            ) : (
              <></>
            );
          })
        : [];
    }
    return (
      goalBankTargets.data?.getGoalBankTargets?.edges.map((edge) => {
        const node = edge?.node;
        return node ? (
          <AddTargetsBankCard
            id={node.id}
            targetName={node.targetName}
            targetDescription={node.targetDescription}
            selected={selectedTargetIds.includes(node.id)}
            onClick={() => {
              if (selectedTargetIds.includes(node.id)) {
                setSelectedTargetIds([...selectedTargetIds.filter((id) => id != node?.id)]);
                return;
              }
              setSelectedTargetIds([...selectedTargetIds, node.id]);
            }}
          />
        ) : (
          <></>
        );
      }) || []
    );
  };

  const addGoals = async () => {
    if (selectedTargetIds.length === 0 && selectedAiTargetIds.length === 0) return;

    try {
      const isAiGeneratedFolder = selectedFolderId === AI_GENERATED;
      if (!isAiGeneratedFolder) {
        const targetsToAdd = goalBankTargets.data?.getGoalBankTargets?.edges.filter((edge) =>
          selectedTargetIds.includes(edge?.node?.id ?? "<missing-target-id>"),
        );
        targetsToAdd?.forEach(async (target) => {
          const node = target?.node;
          await createUpdateTargetMutation.mutateAsync(
            {
              targetData: {
                targetName: node?.targetName ?? "",
                shortTermGoalId,
                targetDescription: node?.targetDescription,
                masteryCriteria: node?.masteryCriteria,
                timelineEstimationType: node?.timelineEstimationType
                  ? (convertDBString(node?.timelineEstimationType) as TargetTimelineEstimationEnums)
                  : null,
                timelineEstimationValue: node?.timelineEstimationValue,
                reportId: assessmentId,
              },
              learnerId: learnerId ? learnerId : "<missing-learner-id>",
            },
            {
              onSuccess: async () => {
                await onAdd();
              },
            },
          );
        });
      }
      if (isAiGeneratedFolder) {
        await addSuggestedTargets.mutateAsync(
          {
            aiSuggestedTargetsIds: selectedAiTargetIds,
            reportId: assessmentId,
            shortTermGoalId,
          },
          {
            onSuccess: async () => {
              await onAdd();
            },
          },
        );
      }
    } catch (err) {
      notifyError("Failed To Add Target(s)");
      console.error("Error adding AI goals", err);
    } finally {
      setSelectedTargetIds([]);
      notifySuccess("Successfully Added Target(s)");
    }
  };

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        userType={userType}
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id, newValue) => editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)}
        onCreateFolder={async (folderName, folderType) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title={"Add Targets"}
        folderSections={
          orgName
            ? [aiSuggestedFoldersSection, userFoldersSection, orgFoldersSection]
            : [aiSuggestedFoldersSection, userFoldersSection]
        }
        modalButton={
          <Button
            data-testid="suggested-target-button"
            text="+ Suggested Target"
            appearance="text"
            buttonAction="action"
            style={{ fontWeight: 400 }}
          />
        }
        footer={{
          addButtonText: "Add Target(s)",
          onAdd: addGoals,
          onSaveToFolder: () => setSaveToFolderDialogOpen(true),
          displaySaveToFolderButton: selectedFolderId === AI_GENERATED,
        }}
        panel={
          <GoalBankPanel
            userType={userType}
            isLoading={goalBankTargets.isFetching || goalBankTargets.isLoading}
            key={goalsToAdd().length}
            noFolders={false}
            goalsToDisplay={goalBankTargets.isFetching || goalBankTargets.isLoading ? [] : goalsToAdd()}
            goalsTabs={[]}
            onCreateFolder={() => {}}
            noGoalsEmptyState={{
              header: selectedFolderId == AI_GENERATED ? "No AI-Generated Items Available" : "Nothing to Show",
              subHeader:
                selectedFolderId == AI_GENERATED
                  ? "You may add saved items from the folders on the left, or add custom items on the previous screen."
                  : "Save items to this folder for later use.",
            }}
          ></GoalBankPanel>
        }
      >
        <GoalBankTargetsSaveToFolder
          key={selectedAiTargetIds.length + selectedTargetIds.length}
          open={isSaveToFolderDialogOpen}
          aiTargets={
            aiTargets
              .filter((aiTarget) => !!aiTarget && selectedAiTargetIds.includes(aiTarget.node?.id ?? ""))
              .map((edge) => edge?.node) as AiSuggestedTargetsType[]
          }
          targets={
            targets
              .filter((target) => !!target && selectedTargetIds.includes(target.node?.id ?? ""))
              .map((edge) => edge.node) as TargetType[]
          }
          refetch={async () => await queryClient.refetchQueries()}
          onCloseClick={() => {
            setSaveToFolderDialogOpen(false);
          }}
        />
        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};
