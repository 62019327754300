import { isAuthorized } from "@utils/utils";
import React, { PropsWithChildren } from "react";
import { roleType } from "src/layouts/config";

import { Button, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

interface AuthorizeProps extends PropsWithChildren {
  redirect?: boolean;
  to?: string;
  allowedRoles: roleType[];
}

export const NotAllowedPage: React.FC = () => {
  return (
    <div className="bg-inherit">
      <div className="flex flex-col items-center h-screen pt-16 bg-inherit">
        <Heading type="h1">403</Heading>
        <Heading type="h4" className="mt-4">
          Oops, you don't have permission to access this page.
        </Heading>

        <Paragraph displayType="normal" colorType="secondary" className="text-center my-4">
          You can return to the homepage, or if you think something is broken you may report a problem.
        </Paragraph>

        <div className="flex space-x-2 pt-6">
          <Button
            appearance="primary"
            text="Return to Homepage"
            className="px-6"
            onClick={() => (window.location.href = "/")}
          />
          <Button
            appearance="secondary"
            className="px-6"
            text="Report a Problem"
            onClick={() => (window.location.href = "mailto:support@frontera.health")}
          />
        </div>
      </div>
    </div>
  );
};

export const Authorize: React.FC<AuthorizeProps> = ({ children, allowedRoles = [] }) => {
  const authorized = isAuthorized(allowedRoles);

  if (!authorized) {
    return <NotAllowedPage />;
  }
  return children;
};
