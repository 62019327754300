import { FeatureFlagSwitch } from "@utils/FeatureFlags/FeatureFlagSwitch";
import { useMemo, useRef, useState } from "react";

import { VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

import { CommentsTimeline } from "@pages/VideoSessions/components/EmojiComments/CommentsTimeline";
import { EmojiCommentButtons } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentButtons";
import { EmojiCommentPanelOld } from "@pages/VideoSessions/components/EmojiComments/EmojiCommentPanelOld";
import { MomentsTimeline } from "@pages/VideoSessions/components/EmojiComments/MomentsTimeline";
import { MediaCardType } from "@pages/VideoSessions/components/MediaCards";
import { VideoPlayerContent } from "@pages/VideoSessions/components/VideoPlayerContent";
import { useEmojiMarkers } from "@pages/VideoSessions/hooks/useEmojiMarkers";

interface VideoContainerProps {
  videoUrl: string;
  media: MediaCardType;
  onMarkerClick: (time: number, commentId: string) => void;
  onTimelineClick: (time: number) => void;
  externalPlayerRef?: React.RefObject<VideoPlayerHandle>; // To handle player in the details view
  commentCount?: string;
  onDurationChange: (duration: number) => void;
  onSeeking?: () => void;
  onSeeked?: () => void;
  enabledFocus?: boolean;
  onPlayerReady?: () => void;
}

export const VideoContainer: React.FC<VideoContainerProps> = ({
  videoUrl,
  media,
  onMarkerClick,
  externalPlayerRef,
  commentCount,
  onDurationChange,
  onTimelineClick,
  onSeeked,
  onSeeking,
  enabledFocus = true,
  onPlayerReady,
}) => {
  const localPlayerRef = useRef<VideoPlayerHandle>(null);
  const playerRef = externalPlayerRef || localPlayerRef;
  const [currentDuration, setCurrentDuration] = useState(0);

  const [currentTime, setCurrentTime] = useState(0);

  const mediaId = media.id;

  const getCurrentTime = () => {
    return playerRef.current?.player?.currentTime() || 0;
  };

  const handleDurationChange = (duration: number) => {
    setCurrentDuration(duration);
    onDurationChange(duration);
  };

  const { handleEmojiClick, selectedEmoji, handleCommentSubmit, closeCommentBox, emojiMarkers, isLoadingComments } =
    useEmojiMarkers({
      mediaId,
      videoDuration: currentDuration,
    });

  const handleMarkerClick = (timestamp: number, commentId: string) => {
    if (playerRef.current) {
      playerRef.current.seekTo(timestamp);
      if (playerRef.current.player) {
        playerRef.current.player.pause();
      }
    }
    onMarkerClick(timestamp, commentId);
  };

  const MemoizedVideoPlayerContent = useMemo(
    () => (
      <VideoPlayerContent
        enabledFocus={enabledFocus}
        ref={playerRef}
        key={mediaId}
        videoUrl={videoUrl}
        onDurationChange={handleDurationChange}
        onSeeked={onSeeked}
        onTimeUpdate={(time) => setCurrentTime(time)}
        onSeeking={onSeeking}
        onPlayerReady={onPlayerReady}
      />
    ),
    [videoUrl, mediaId],
  );

  return (
    <div className="w-full aspect-video" data-video-player>
      {MemoizedVideoPlayerContent}
      <div className="relative flex flex-col gap-2">
        {/* Without the Maladaptive Feature Flag - The Emoji Comment Panel is above the buttons, that's why this is setup as is */}
        <FeatureFlagSwitch
          flagKey={"maladaptive-behaviors"}
          flagEnabled={null}
          flagDisabled={
            <EmojiCommentPanelOld
              emojiMarkers={emojiMarkers}
              isLoading={isLoadingComments || currentDuration <= 0}
              mediaId={mediaId}
              onMarkerClick={handleMarkerClick}
            />
          }
        />
        <EmojiCommentButtons
          onEmojiClick={handleEmojiClick}
          disabled={currentDuration <= 0}
          selectedEmoji={selectedEmoji}
          onCommentSubmit={handleCommentSubmit}
          onClose={closeCommentBox}
          getCurrentTime={getCurrentTime}
          playerRef={playerRef}
          commentCount={commentCount}
          media={media}
        />

        {/* With the Maladaptive Feature Flag - The Emoji Comment Panel (now called the CommentsTimeline) 
        is below the buttons, alongside the new Moments Timel*/}
        <FeatureFlagSwitch
          flagKey={"maladaptive-behaviors"}
          flagDisabled={null}
          flagEnabled={
            <>
              <CommentsTimeline
                emojiMarkers={emojiMarkers}
                isLoading={isLoadingComments || currentDuration <= 0}
                mediaId={mediaId}
                onMarkerClick={handleMarkerClick}
                onTimelineClick={onTimelineClick}
                duration={currentDuration}
                currentTime={currentTime}
              />
              <MomentsTimeline
                media={media}
                onTimelineClick={onTimelineClick}
                duration={currentDuration}
                currentTime={currentTime}
                isLoading={currentDuration <= 0}
              />
            </>
          }
        />
      </div>
    </div>
  );
};
