import { pathNameToBreadCrumbLinks } from "@utils/helpers";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Breadcrumb, Heading } from "@fronterahealth/frontera-ui-components";

import { FilterBar } from "@pages/VideoSessions/components/FilterBar";
import { MediaCards } from "@pages/VideoSessions/components/MediaCards";

export const VideoSessionsSummary: React.FC = () => {
  const location = useLocation();
  const { pathname } = useLocation();
  const breadcrumbLinks = pathNameToBreadCrumbLinks(pathname);
  const isDetailView = location.pathname.includes("/video-sessions/") && location.pathname !== "/video-sessions";

  if (isDetailView) {
    return <Outlet />;
  }

  return (
    <div className="h-full w-full flex flex-col gap-4  px-4">
      <Breadcrumb
        links={breadcrumbLinks}
        homeLink={{
          name: "Home",
          Component: <Link to={`/`}>Home</Link>,
          current: false,
          type: "component",
        }}
      />
      <Heading type="h1" className="text-xl md:text-4xl">
        Video Sessions
      </Heading>
      <FilterBar />
      <MediaCards />
    </div>
  );
};
