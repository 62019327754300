import { PropsWithChildren, createContext, useContext, useState } from "react";

interface UIStateContextI {
  sidebarCollapsed: boolean;
  onSidebarCollapse: (bool: boolean) => void;
}

export const UIStateContext = createContext<UIStateContextI | undefined>(undefined);

export const useUIState = () => {
  const context = useContext(UIStateContext);
  if (context === undefined) {
    throw new Error("useUIState must be used within a UIStateProvider");
  }
  return context;
};

export const UIStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sidebarCollapsed, onSidebarCollapse] = useState<boolean>(false);

  return <UIStateContext.Provider value={{ sidebarCollapsed, onSidebarCollapse }}>{children}</UIStateContext.Provider>;
};
