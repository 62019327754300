import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import React, { useEffect } from "react";

import { Paragraph, SignatureInput, TextArea } from "@fronterahealth/frontera-ui-components";

import { ApiOrganizationAbExportTemplateNameChoices } from "@api/graphql/types-and-hooks";
import { useAdminData } from "@providers/AdminDataProvider";

const PolygonShape: React.FC = () => {
  return (
    <div className="relative">
      <svg
        className="absolute inset-y-1.5 -right-11 w-16 bottom-0"
        width="40"
        height="37.07"
        viewBox="0 0 40 37.07"
        xmlns="http://www.w3.org/2000/svg"
        style={{ opacity: 1 }}
      >
        {/* Half hexagon shape with direct rotation applied to points */}
        <polygon points="40,0 20,0 0,18.535 20,37.07 40,37.07" fill="#E77757" />
      </svg>
    </div>
  );
};

interface SignatureSectionProps {
  id: string;
  closingRemark: string;
  setClosingRemark: (val: string) => void;
  signature: string;
  setSignature: (val: string) => void;
  timestamp: Date;
  setTimestamp: (val: Date) => void;
  isReadOnly?: boolean;
}

export const SignatureSection: React.FC<SignatureSectionProps> = ({
  id,
  signature,
  setSignature,
  closingRemark,
  setClosingRemark,
  timestamp,
  setTimestamp,
  isReadOnly,
}) => {
  const { orgnizationDetailsQuery } = useAdminData();

  const isCatalightUser =
    orgnizationDetailsQuery.data?.getUserOrgnizationDetails?.abExportTemplateName ===
      ApiOrganizationAbExportTemplateNameChoices.CatalightAssessmentReportDocxTemplateDocx || false;

  useEffect(() => {
    const font = new FontFace(
      "Yellowtail",
      "url(https://fonts.gstatic.com/s/yellowtail/v22/OZpGg_pnoDtINPfRIlLohlvHwQ.woff2)",
    );
    font.load().then(() => {
      document.fonts.add(font);
    });
  }, []);
  return (
    <div className="pageBreak">
      <section className="px-8 py-6 mb-4 rounded-3xl bg-white" id={id}>
        <div className="flex items-center mb-4" id={`${id}-signature`} data-sticky-nav-header>
          <div className="border p-2 rounded mr-2">
            <PencilSquareIcon className="h-6 w-6 text-text-primary" />
          </div>
          <div>
            <Paragraph data-dynamic-section displayType="loud" colorType="primary">
              Signature
            </Paragraph>
          </div>
        </div>
        {!isCatalightUser ? (
          <div className={isReadOnly ? "mb-2" : "-mb-4"}>
            <TextArea
              value={closingRemark}
              displayOnlyMode={isReadOnly}
              onChange={(e) => setClosingRemark(e.target.value)}
              label="Closing Remarks"
              name="closing-remarks"
              hintText="Optional"
            />
          </div>
        ) : (
          <></>
        )}
        <Paragraph displayType="normal">
          {isReadOnly
            ? `This assessment report was approved by:`
            : `By clicking “Sign & Complete”, you are stating that the information you have provided in this assessment report is correct to your best understanding.`}
        </Paragraph>
        {!isReadOnly && <PolygonShape />}

        {isReadOnly ? (
          <div className="flex h-20 flex-col gap-y-2 rounded bg-hatch-50 p-5 align-middle mt-5 captureSignature">
            <p className="text-2xl leading-6" style={{ fontFamily: "'Yellowtail', cursive" }}>
              {signature}
            </p>
            <p className="text-sm">{format(timestamp, "MM/dd/yyyy h:mm a")}</p>
          </div>
        ) : (
          <SignatureInput
            className="mt-0 captureSignature"
            name="signature"
            data-testid="signature-input"
            date={timestamp}
            inputLabel=""
            value={signature}
            onInputChange={(val) => {
              setSignature(val);
              setTimestamp(new Date());
            }}
            defaultValue={signature}
            inputSize="md"
            isSignatureReadOnlyMode={isReadOnly}
          />
        )}
      </section>
    </div>
  );
};
