import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import {
  Badge,
  Button,
  Dialog,
  Paragraph,
  SkeletonLoader,
  Tooltip,
  TrainStopProgressNav,
} from "@fronterahealth/frontera-ui-components";

import {
  ApiAssessmentAssessmentStatusChoices,
  ApiAssessmentEhrSyncChoices,
  AssessmentStatusEnums,
  useStartAssessmnetManualEhrSyncMutation,
  useSyncPassageGoalsMutation,
  useUpdateAssessmentMutation,
} from "@api/graphql/types-and-hooks";
import ExportWithTemplate from "@components/PDFGenerator/ExportWithTemplate";
import { convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAdminData } from "@providers/AdminDataProvider";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export type AssessmentReportSubRoute =
  | "client-details"
  | "upload-files"
  | "report-summary-part-1"
  | "prioritize"
  | "long-term-goals"
  | "short-term-goals"
  | "report-summary-part-2"
  | "review-and-sign";

export const routeLabelMapping: { [key in AssessmentReportSubRoute]: string } = {
  "client-details": "Details",
  "upload-files": "Upload Files",
  "report-summary-part-1": "Report Part 1",
  prioritize: "Prioritize",
  "long-term-goals": "Long Term Goals",
  "short-term-goals": "Short Term Goals",
  "report-summary-part-2": "Report Part 2",
  "review-and-sign": "Review",
};

export const baseRoutes: AssessmentReportSubRoute[] = [
  "client-details",
  "upload-files",
  "report-summary-part-1",
  "prioritize",
];

export const finalRoutes: AssessmentReportSubRoute[] = ["report-summary-part-2", "review-and-sign"];

export const useOrderedAssessmentReportSubRoutes = () => {
  const { assessmentReport } = useAssessmentBuilderData();

  const goalRoutes: AssessmentReportSubRoute[] = assessmentReport?.useLongTermGoals
    ? ["long-term-goals", "short-term-goals"]
    : ["short-term-goals"];

  const orderedRoutes = [...baseRoutes, ...goalRoutes, ...finalRoutes] as AssessmentReportSubRoute[];

  return {
    orderedRoutes,
    getNextRoute: (route: AssessmentReportSubRoute) => {
      const currentIndex = orderedRoutes.indexOf(route);
      if (route === "review-and-sign") return "review-and-sign";
      return currentIndex >= 0 && currentIndex < orderedRoutes.length - 1 ? orderedRoutes[currentIndex + 1] : null;
    },
    getPastRoute: (route: AssessmentReportSubRoute) => {
      const currentIndex = orderedRoutes.indexOf(route);
      return currentIndex > 0 ? orderedRoutes[currentIndex - 1] : "client-details";
    },
    statusFromCurrentRoute: (currentRoute: AssessmentReportSubRoute, navStepRoute: AssessmentReportSubRoute) => {
      return currentRoute === navStepRoute
        ? "current"
        : orderedRoutes.indexOf(currentRoute) > orderedRoutes.indexOf(navStepRoute)
          ? "complete"
          : "upcoming";
    },
  };
};

interface ButtonConfig {
  condition: boolean;
  text: string;
  onClick: () => void;
  disabled: boolean;
}

export const AssessmentReportDetails: React.FC = () => {
  const { pathname, state } = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { assessmentReport, refetch } = useAssessmentBuilderData();
  const { isPassageHealthUser, isManualEHRSyncEnabled } = useAdminData();
  const { orderedRoutes, statusFromCurrentRoute } = useOrderedAssessmentReportSubRoutes();

  const updateAssessment = useUpdateAssessmentMutation({});
  const syncPassageGoals = useSyncPassageGoalsMutation({});
  const startManualEHRSync = useStartAssessmnetManualEhrSyncMutation({});
  const { isPending: passageGoalSyncLoading } = syncPassageGoals;
  const { isPending } = updateAssessment;

  const updateAssessmentStatus = async () => {
    await updateAssessment
      .mutateAsync({
        assessment: assessmentReport.id,
        assessmentStatus: AssessmentStatusEnums.Draft,
      })
      .then((data) => {
        if (data.updateAssessment?.status) {
          refetch();
        }
      });
  };

  const handleSyncPassageGoals = async () => {
    syncPassageGoals
      .mutateAsync({ learnerId: assessmentReport.learner?.id ?? "<missing-learner-id>" })
      .then(async (data) => {
        if (data?.syncPassageGoals?.status) {
          notifySuccess("Sync Started. This treatment plan has been sent to Passage Health");
          await refetch();
        }
      })
      .catch((error) => {
        console.error("Error Starting Passage Goals Sync : ", error);
        notifyError("Error Starting Passage Goals Sync");
      });

    setDialogOpen(false);
  };

  const handleEHRSync = async () => {
    startManualEHRSync
      .mutateAsync({
        assessmentId: assessmentReport.id,
      })
      .then(async (data) => {
        if (data?.startAssessmnetManualEhrSync?.status) {
          notifySuccess(data?.startAssessmnetManualEhrSync?.message ?? "");
          await refetch();
        }
      })
      .catch((error) => {
        console.error("Error Starting EHR Sync : ", error);
        notifyError("Error Starting EHR Sync");
      });
  };

  if (!assessmentReport) return null;

  const reportIsCompleted = assessmentReport.assessmentStatus === ApiAssessmentAssessmentStatusChoices.Completed;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);
  const configuredClient = Boolean(assessmentReport?.assessmentClientDetails ?? false);
  const clientName = `${assessmentReport?.assessmentClientDetails?.clientFirstName || "N/A"} ${assessmentReport?.assessmentClientDetails?.clientLastName || ""}`;

  const buttons: ButtonConfig[] = [
    {
      condition: isPassageHealthUser,
      text: "Sync to Passage",
      onClick: () => setDialogOpen(true),
      disabled: !!assessmentReport.ehrSync || !assessmentReport.learner?.externalId,
    },
    {
      condition: isManualEHRSyncEnabled && !assessmentReport.ehrSync,
      text: "Sync to EHR",
      onClick: handleEHRSync,
      disabled: false,
    },
  ];

  const getPassageButtonTooltipText = (): string => {
    if (!!assessmentReport.ehrSync) return "This treatment plan has been sent to Passage and cannot be re-synced.";
    else if (!assessmentReport.learner?.externalId) return "The client does not have an external ID available.";
    else return "disabled";
  };

  return (
    <div className="-mt-12 flex w-full flex-col items-start justify-between">
      <div className="d-flex flex-col sticky top-4 pt-3  w-full z-20">
        <div className="-mt-7 flex flex-col gap-y-2 bg-white -ml-6 w-[calc(100%+48px)] p-4 ">
          <div className="flex w-full items-center justify-between px-2">
            <SkeletonLoader loading={!assessmentReport}>
              <Paragraph colorType="primary" displayType="loud">
                {clientName} Assessment Report{" "}
              </Paragraph>
            </SkeletonLoader>
            {reportIsCompleted && (
              <div>
                {isManualEHRSyncEnabled && assessmentReport.ehrSync ? (
                  <Badge
                    text={`EHR Sync ${convertReadableString(assessmentReport.ehrSync)}`}
                    appearance={
                      assessmentReport.ehrSync && assessmentReport.ehrSync === ApiAssessmentEhrSyncChoices.Inprogress
                        ? "ai"
                        : assessmentReport.ehrSync === ApiAssessmentEhrSyncChoices.Pending
                          ? "warn"
                          : "success"
                    }
                    className="mr-5 px-3 py-2"
                  />
                ) : null}
                <ExportWithTemplate assessmentData={assessmentReport} />
                {isPassageHealthUser && !!assessmentReport.ehrSync ? (
                  <Tooltip
                    text="This treatment plan has been sent to Passage and cannot be edited."
                    showInfoIcon={false}
                  >
                    <Button
                      text="Edit"
                      disabled={isPending || !!assessmentReport.ehrSync}
                      appearance="secondary"
                      onClick={updateAssessmentStatus}
                      className="ml-2 ring-green-400"
                    />
                  </Tooltip>
                ) : (
                  <Button
                    text="Edit"
                    disabled={isPending || !!assessmentReport.ehrSync}
                    appearance="secondary"
                    onClick={updateAssessmentStatus}
                    className="ml-2 ring-green-400"
                  />
                )}

                {buttons.map(({ condition, text, onClick, disabled }) => {
                  if (!condition) return null;

                  const isDisabledWithTooltip = disabled && isPassageHealthUser;
                  const button = (
                    <Button
                      key={text}
                      text={text}
                      disabled={passageGoalSyncLoading || disabled}
                      appearance="primary"
                      onClick={onClick}
                      className="ring-green-400"
                    />
                  );

                  return (
                    <div key={text} className="inline-block ml-2">
                      {isDisabledWithTooltip ? (
                        <Tooltip text={getPassageButtonTooltipText()} showInfoIcon={false}>
                          {button}
                        </Tooltip>
                      ) : (
                        button
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {assessmentReport?.assessmentStatus !== ApiAssessmentAssessmentStatusChoices.Completed ? (
          <div className="-ml-8 w-[calc(100%+64px)] bg-background-secondary">
            <TrainStopProgressNav
              steps={orderedRoutes.map((route, index) => ({
                navigationToEnabled: true,
                index,
                name: routeLabelMapping[route as AssessmentReportSubRoute],
                status: statusFromCurrentRoute(currentRoute, route),
                to: route,
                Component: ({ children, className, to }) => (
                  <Link
                    to={to}
                    className={configuredClient ? className : `${className} pointer-events-none`}
                    state={state}
                  >
                    {children}
                  </Link>
                ),
              }))}
            />
          </div>
        ) : null}
      </div>
      <div className="w-full mt-4 py-8 h-full">
        <Outlet />
      </div>
      <Dialog
        size="xl"
        open={dialogOpen}
        setOpen={setDialogOpen}
        title="Confirm Sync to Passage Health"
        primaryButton={{
          text: "Yes, Continue",
          onClick: () => handleSyncPassageGoals(),
          disabled: passageGoalSyncLoading,
        }}
        secondaryButton={{
          text: "No, Cancel",
          onClick: () => {
            setDialogOpen(false);
          },
        }}
      >
        <>
          <Paragraph displayType="normal" colorType="secondary">
            Once this client’s treatment plan is sent to Passage Health you will be unable to edit it or re-sync.
          </Paragraph>
          <Paragraph displayType="normal" colorType="secondary" className="my-4">
            Would you like to proceed?
          </Paragraph>
          <div className="p-4 bg-red-50">
            <Paragraph displayType="loud" className="inline !text-red-500">
              <ExclamationTriangleIcon className="h-5 w-5 text-red-500 inline mr-2" />
              Warning:{" "}
            </Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="inline !text-red-500">
              &nbsp; This action is not reversible
            </Paragraph>
          </div>
        </>
      </Dialog>
    </div>
  );
};
