import { formatDate, formatDurationToHrMin } from "@utils/utils";
import { format } from "date-fns";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import { LearnersRecentSessionQuery, SessionsByLearnerIdQuery } from "@api/graphql/types-and-hooks";
import { Datum } from "@components/SessionAnalyzer/OverTimeEngagementChart/OverTimeEngagementChart";

export interface SessionAnalyzerClientType {
  id: string;
  learnerId: string;
  name: string;
  technician: string;
  lastSession: string;
  engagement: string;
  status: string;
}

export interface PointData {
  x: number;
  y: number | null;
}

export const secondsToMinutes = (seconds: number) => Math.ceil(seconds / 60);

export const transformSessionLearnersIntoClients = (data: LearnersRecentSessionQuery): SessionAnalyzerClientType[] => {
  return (
    data?.learnersRecentSession?.edges.map((edge) => {
      const providerEdges = edge?.node?.therapySession.sessionproviderSet.edges || [];
      const providerLength = providerEdges.length;
      const provider = providerLength ? providerEdges[providerLength - 1]?.node?.provider.providermetadata : null;

      return {
        id: edge?.node?.id || "",
        learnerId: edge?.node?.sessionLearners.id || "",
        status: edge?.node?.therapySession.sessionStatus || "",
        name: `${edge?.node?.sessionLearners.learnermetadata?.firstName || ""} ${edge?.node?.sessionLearners.learnermetadata?.lastName || ""}`,
        technician: provider ? `${provider.firstName || ""} ${provider.lastName || ""}` : "",
        lastSession: edge?.node?.therapySession.createdDate ? formatDate(edge?.node?.therapySession.createdDate) : "",
        engagement: edge?.node?.therapySession.engagementScore
          ? `${edge?.node?.therapySession.engagementScore.toString()}%`
          : "",
      };
    }) || []
  );
};

export interface LearnerSessionsDataType {
  sessionId: string;
  session: string;
  technician: string;
  technicianId: string;
  duration: string;
  engagementScore: number | null;
  date: Date;
}

export const transformLearnerSessionsIntoEngagementData = (
  data: SessionsByLearnerIdQuery,
): LearnerSessionsDataType[] => {
  return (
    data?.sessionsByLearnerId?.edges.map((edge) => {
      const providerEdges = edge?.node?.sessionproviderSet.edges || [];
      const providerLength = providerEdges.length;
      const provider = providerLength ? providerEdges[providerLength - 1]?.node?.provider.providermetadata : null;
      const providerId = providerLength ? providerEdges[providerLength - 1]?.node?.provider.id : "";

      return {
        sessionId: edge?.node?.id || "",
        technician: provider ? `${provider.firstName || ""} ${provider.lastName || ""}` : "",
        technicianId: providerId || "",
        duration: edge?.node?.finalMedia?.totalDuration
          ? formatDurationToHrMin(edge?.node?.finalMedia.totalDuration)
          : "",
        engagementScore: edge?.node?.engagementScore || 0,
        date: edge?.node?.createdDate || "",
        session: edge?.node?.createdDate ? format(edge?.node?.createdDate, "MM/dd/yyyy HH:mm a") : "",
      };
    }) || []
  );
};

export const getDistinctTechniciansFromLearnerSessions = (sessions: LearnerSessionsDataType[]): SelectItem[] => {
  const technicianMap: { [key: string]: { technicianId: string; technician: string } } = {};

  sessions.forEach((session) => {
    const { technicianId, technician } = session;
    if (technicianId && !technicianMap[technicianId]) {
      technicianMap[technicianId] = { technicianId, technician };
    }
  });

  return Object.values(technicianMap).map(({ technicianId, technician }) => ({
    id: technicianId,
    primary: technician,
  }));
};

export const transformLearnerSessionsToDatum = (sessions: LearnerSessionsDataType[]): Datum[] => {
  return sessions.map((session) => {
    const date = new Date(session.date);
    const engagement = session.engagementScore ? session.engagementScore : null;

    return {
      date,
      engagement,
      technician: session.technician,
      sessionId: session.sessionId,
    };
  });
};
export const transformDataForIntervals = (
  data: PointData[],
  videoDuration: number,
  intervalCount: number = 12,
): PointData[] => {
  if (data.length === 0) return [];

  const maxX = videoDuration / 60;

  const createIntervals = (start: number, end: number, numIntervals: number): number[] => {
    const intervals: number[] = [];
    const intervalSize = (end - start) / numIntervals;
    for (let i = 0; i <= numIntervals; i++) {
      intervals.push(parseFloat((start + i * intervalSize).toFixed(2)));
    }
    return intervals;
  };

  const intervals: number[] = [];
  for (let i = 0; i < Math.ceil(maxX); i++) {
    intervals.push(...createIntervals(i, i + 1, intervalCount));
  }

  const uniqueIntervals = Array.from(new Set(intervals));
  const transformedData: PointData[] = uniqueIntervals.map((interval, index) => {
    const closestPoint = data[index];

    return {
      x: interval,
      y: closestPoint?.y ?? null,
    };
  });

  return transformedData;
};
