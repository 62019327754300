import { datadogRum } from "@datadog/browser-rum";
import React, { PropsWithChildren, useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { Button, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import { StagingAndBelowWrapper } from "@components/DevOnlyWrapper/DevOnlyWrapper";

type ErrorBoundaryProps = PropsWithChildren;

export const RouteErrorElement: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      let errorMessage: string = "Unknown error";
      let errorStack: string | undefined = undefined;

      if (isRouteErrorResponse(error)) {
        errorMessage = error.data || error.statusText || "Router error occurred";
      } else if (typeof error === "object" && error !== null && "message" in error) {
        errorMessage = String(error.message);
        errorStack = "stack" in error ? String(error.stack) : undefined;
      } else if (typeof error === "string") {
        errorMessage = error;
      }

      const errorBoundaryError = new Error(errorMessage);
      errorBoundaryError.name = `React Router Error Element`;
      errorBoundaryError.stack = errorStack;

      datadogRum.addError(errorBoundaryError);
    }
  }, [error]);

  const errorMessage = (() => {
    if (isRouteErrorResponse(error)) {
      return error.data || error.statusText;
    }
    if (typeof error === "object" && error !== null && "message" in error) {
      return String(error.message);
    }
    if (typeof error === "string") {
      return error;
    }
    return "Unknown error occurred.";
  })();

  const errorStack = (() => {
    if (typeof error === "object" && error !== null && "stack" in error) {
      return String(error.stack);
    }
  })();

  return (
    <div className="bg-inherit">
      <div className="flex flex-col items-center h-screen pt-16 bg-inherit">
        <Heading type="h1">Uh Oh...</Heading>
        <Heading type="h4" className="mt-4">
          Something went wrong on our side
        </Heading>
        <StagingAndBelowWrapper>
          <div className="bg-limestone-100 relative rounded-md  mt-4 p-4">
            <Paragraph className="font-semibold absolute top-4 left-4">Dev / Staging Only</Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="text-center ">
              Error Message:
            </Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="text-center text-red-800 mb-2">
              <code>{errorMessage}</code>
            </Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="text-center  mt-4">
              Stack Trace:
            </Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="text-center text-red-800 mb-2">
              <code>{errorStack}</code>
            </Paragraph>
          </div>
        </StagingAndBelowWrapper>

        <Paragraph displayType="normal" colorType="secondary" className="text-center my-4">
          You can return to the homepage, or if you think something is broken you may report a problem.
        </Paragraph>

        <div className="flex space-x-2 pt-6">
          <Button
            appearance="primary"
            text="Return to Homepage"
            className="px-6"
            onClick={() => (window.location.href = "/")}
          />
          <Button
            appearance="secondary"
            className="px-6"
            text="Report a Problem"
            onClick={() => (window.location.href = "mailto:support@frontera.health")}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
