import { CheckCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { capitalizeString } from "@utils/utils";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { ApiReportSectionsAiGeneratedStatusChoices } from "@api/graphql/types-and-hooks";
import { SpinnerIcon } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/StickyNav/SpinnerIcon";
import { useScrollToAnchor } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/useScrollToAnchor";

interface IconColorConfig {
  color: string;
  icon: React.ForwardRefExoticComponent<unknown> | React.ElementType;
  status: "failure" | "pending" | "predicted" | "predicting";
}

const getIconColorConfigFromReportSection = (section: SectionProps | undefined): IconColorConfig => {
  if (!section) {
    return {
      color: "text-gray-400",
      icon: () => <div className="w-2 h-2 rounded-full bg-gray-300 mr-3 p-1" />,
      status: "pending",
    };
  }
  if (section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure) {
    return {
      color: "text-red-400",
      icon: ExclamationTriangleIcon,
      status: "failure",
    };
  }
  if (section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending) {
    return {
      color: "text-blurple-400 animate-slow-spin",
      icon: SpinnerIcon,
      status: "predicting",
    };
  }
  if (section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted) {
    return {
      color: "text-blurple-400",
      icon: CheckCircleIcon,
      status: "predicted",
    };
  }
  if (section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Pending) {
    return {
      color: "text-limestone-400",
      icon: CheckCircleIcon,
      status: "pending",
    };
  }

  return {
    color: "text-gray-500",
    icon: () => <div className="w-4 h-4 rounded-full bg-limestone-500" />,
    status: "pending",
  };
};

interface HeadingItemInterface {
  heading: string;
  hash: string;
  sections: SectionProps[];
}
const HeadingItem: React.FC<HeadingItemInterface> = ({ heading, hash, sections }) => {
  const actualHeading = heading.split(/-(.+)/)[1]; // split by first occurrence of "-"
  const active = hash.replace("#", "") === heading;
  const headingLabel = actualHeading
    .split("-")
    .map((h) => capitalizeString(h))
    .join(" ");

  const correspondingSectionFromLabel = sections.find(
    (section) => section.title.toLowerCase() === headingLabel.toLowerCase(),
  );
  const iconConfig: IconColorConfig = getIconColorConfigFromReportSection(correspondingSectionFromLabel);

  return (
    <Link to={`#${heading}`} key={heading}>
      <div
        className={`transition-all cursor-pointer ${active ? "border-l-2" : "border-l"}   py-2 pl-2 hover:border-interaction-primary  ${active ? "border-interaction-primary" : "border-limestone-100"} `}
      >
        <div className="flex items-center">
          <iconConfig.icon className={`opacity-50 ${iconConfig.color} w-4 h-4 min-h-4 min-w-4 mr-2 `} />
          <Small
            className={`transition-all hover:text-text-primary`}
            displayType={active ? "loud" : "normal"}
            colorType={active ? "primary" : "secondary"}
          >
            {headingLabel}
          </Small>
        </div>
      </div>
    </Link>
  );
};

interface SectionProps {
  title: string;
  aiGeneratedStatus: ApiReportSectionsAiGeneratedStatusChoices;
}

interface StickyNavProps {
  sections: SectionProps[];
}

/**
 * BACKPORT-FINAL: To be used going forward
 * This is the floating nav item bar that reads and scans for any div elements with the "data-sticky-nav-header" data attribute
 * on them and renders them as clickable legend elements that will jump the user to that section
 * @returns
 */
export const StickyNav: React.FC<StickyNavProps> = ({ sections }) => {
  const { hash } = useLocation();
  const [headings, setHeadings] = React.useState<string[]>([]);

  useEffect(() => {
    const _headings = Array.from(document.querySelectorAll(`div[data-sticky-nav-header]`)).map((div) => div.id);
    setHeadings(_headings);
  }, [sections]);

  useScrollToAnchor();

  return (
    <nav className="sticky top-[138px] flex flex-col items-start ">
      <Paragraph className="mb-2">On this page</Paragraph>

      <div className="flex flex-col items-start max-h-[calc(100vh-250px)] overflow-y-scroll ">
        {headings.map((heading) => (
          <HeadingItem key={heading} heading={heading} hash={hash} sections={sections} />
        ))}
      </div>
    </nav>
  );
};
