import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";

import { Button, VideoTimePicker } from "@fronterahealth/frontera-ui-components";

import { EmojiButtonInterface } from "@pages/VideoSessions/types";

interface EmojiCommentCardProps {
  selectedEmoji: EmojiButtonInterface | null;
  currentTime: number;
  onSubmit: (comment: string, timestamp: number) => void;
  onClose: () => void;
  className?: string;
  duration: number;
}
const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const calculateTotalSeconds = (time: string): number => {
  try {
    const [hours, minutes, seconds] = time.split(":").map((unit) => parseInt(unit || "0", 10));
    return hours * 3600 + minutes * 60 + seconds;
  } catch {
    return 0;
  }
};

export const EmojiCommentCard: React.FC<EmojiCommentCardProps> = ({
  selectedEmoji,
  currentTime,
  onSubmit,
  onClose,
  className,
  duration,
}) => {
  const [comment, setComment] = useState("");
  const [inputValue, setInputValue] = useState(formatTime(currentTime));

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Update inputValue when timestamp changes from outside
  useEffect(() => {
    setInputValue(formatTime(currentTime));
  }, [currentTime]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(comment, calculateTotalSeconds(inputValue));
    setComment("");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to calculate scrollHeight
      textarea.style.height = `${Math.min(textarea.scrollHeight, 256)}px`; // Max height of 256px
    }
    setComment(e.target.value);
  };

  if (!selectedEmoji) return null;

  return (
    <div
      data-emoji-comment
      className={`w-96 min-h-40 bg-white flex flex-col items-start p-4 gap-4 border border-gray-200 shadow-lg rounded-2xl z-10 -top-40 left-1/2 transform -translate-x-1/2 ${className || ""}`}
    >
      <form className="w-full flex flex-col gap-6" onSubmit={handleSubmit}>
        <div>
          <div className="flex justify-end w-full">
            <button onClick={onClose}>
              <XMarkIcon className="h-4 w-4 text-gray-500" />
            </button>
          </div>

          <textarea
            ref={textareaRef}
            name="comment"
            placeholder="Leave your comment..."
            className="w-full min-h-[32px] max-h-64 px-2 py-2 text-text-secondary text-sm border-none outline-none focus:ring-2 focus:ring-limestone-200 focus:ring-offset-0 rounded-lg transition-all duration-200 resize-none"
            value={comment}
            onChange={handleInputChange}
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <label className="text-sm text-text-secondary">@</label>
            <VideoTimePicker
              name="timestamp"
              label=""
              defaultValue={inputValue}
              videoDuration={duration}
              onTimeChange={(time) => setInputValue(time)}
              className="max-w-28 min-w-28 z-50"
              inputSize="xs"
              formNoValidate
              position="top"
            />
          </div>

          <Button
            text={
              <div className="flex items-center gap-1">
                <span>Send</span>
                <PaperAirplaneIcon height={14} />
              </div>
            }
            type="submit"
            appearance="primary"
          />
        </div>
      </form>
    </div>
  );
};
