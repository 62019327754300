import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { UseQueryResult } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

import {
  ApiOrganizationOrganizationTypeChoices,
  GetUserOrgnizationDetailsQuery,
  LearnersQuery,
  useGetUserAccountDetailsQuery,
  useGetUserOrgnizationDetailsQuery,
  useLearnersQuery,
} from "@api/graphql/types-and-hooks";

interface AdminDataContextI {
  learnersQuery: UseQueryResult<LearnersQuery, unknown>;
  orgnizationDetailsQuery: UseQueryResult<GetUserOrgnizationDetailsQuery, unknown>;
  roles: string[];
  isSelfServeUser: boolean;
  isPassageHealthUser: boolean;
  assessmentCreditsRemaining: number;
  assessmentCreditsUsed: number;
  isFetchingAssessmentCredits: boolean;
  isManualEHRSyncEnabled: boolean;
}

export const AdminContext = createContext<AdminDataContextI | undefined>(undefined);

export const useAdminData = () => {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useAdminData must be used within a AdminDataProvider");
  }
  return context;
};

/**
   *
   * The goal of this Provider is not so much to store original state or new state but more to simply house
   * and dependency inject the data from from useQuery and useMutation for our signin (mutation) and userdetails(query) calls
   *
   * React Query (from Tanstack) ultimately manages all of our async state (idle, loading, error, data) for us.
   * Part of the decision around UI state management for this project was to used a widely adopted, intentional tool for managing async server state

   * This provider is simply lifting that async state at the top of our tree so the rest of the app can access it
   * @returns
   */
export const AdminDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [roles, setRoles] = useState<string[]>([]);
  const [isSelfServeUser, setIsSelfServeUser] = useState<boolean>(false);
  const [isPassageHealthUser, setIsPassageHealthUser] = useState<boolean>(false);
  const [isManualEHRSyncEnabled, setIsManualEHRSyncEnabled] = useState<boolean>(false);

  const { user, isAuthenticated } = useAuth0();

  const orgnizationDetailsQuery = useGetUserOrgnizationDetailsQuery(
    {},
    {
      queryKey: ["get-account-details"],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
      enabled: isAuthenticated,
    },
  );
  const { data: orgnizationDetailsData } = orgnizationDetailsQuery;

  useEffect(() => {
    if (orgnizationDetailsData?.getUserOrgnizationDetails) {
      if (
        orgnizationDetailsData?.getUserOrgnizationDetails.organizationType ===
        ApiOrganizationOrganizationTypeChoices.PassageHealth
      ) {
        setIsPassageHealthUser(true);
      }
      setIsManualEHRSyncEnabled(orgnizationDetailsData.getUserOrgnizationDetails.manualEhrIntegration);
    }
  }, [orgnizationDetailsData]);

  useEffect(() => {
    if (user) {
      try {
        const rolesKey = `${config.auth0Audience}/roles`;
        const rolesString = user?.[rolesKey];

        const selfServeKey = `${config.auth0Audience}/selfServeUser`;
        const selfServeString = user?.[selfServeKey];

        const isSelfServe =
          !!selfServeString && typeof selfServeString === "string" && selfServeString.toLowerCase() === "true";

        setIsSelfServeUser(isSelfServe);
        if (rolesString.length) setRoles(rolesString.split(","));
      } catch (err) {
        console.error("Error getting roles ", err);
        setRoles([]);
      }
    }
  }, [user]);

  const accountDetails = useGetUserAccountDetailsQuery(
    {},
    {
      queryKey: ["get-account-details", isSelfServeUser],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
      enabled: isSelfServeUser,
    },
  );

  const assessmentCreditsRemaining = accountDetails?.data?.getUserAccountDetails?.pendingAssessment || 0;
  const totalCredits = accountDetails?.data?.getUserAccountDetails?.purchasedAssessment || 0;
  const assessmentCreditsUsed = totalCredits - assessmentCreditsRemaining;

  const learnersQuery = useLearnersQuery(
    {},
    {
      queryKey: ["learners", isAuthenticated, JSON.stringify(roles)],
      retry: false,
      enabled: isAuthenticated && !!roles.length && roles.includes("admin"),
      refetchOnWindowFocus: false,
    },
  );

  if (!learnersQuery || !orgnizationDetailsQuery) return null;

  return (
    <AdminContext.Provider
      value={{
        learnersQuery,
        orgnizationDetailsQuery,
        roles,
        isSelfServeUser,
        isPassageHealthUser,
        assessmentCreditsRemaining,
        assessmentCreditsUsed,
        isFetchingAssessmentCredits: accountDetails.isFetching,
        isManualEHRSyncEnabled,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
