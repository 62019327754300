import { forwardRef, memo, useState } from "react";

import { VideoJsPlayerOptions, VideoPlayer, VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

interface VideoPlayerContentProps {
  videoUrl: string;
  onDurationChange?: (duration: number) => void;
  onSeeking?: () => void;
  onSeeked?: () => void;
  onTimeUpdate: (time: number) => void;
  enabledFocus: boolean;
  onPlayerReady?: () => void;
}

export interface VideoPlayerReadyEvent
  extends CustomEvent<{
      duration: number;
    }>,
    Event {}

export const VideoPlayerContent = memo(
  forwardRef<VideoPlayerHandle, VideoPlayerContentProps>(
    ({ videoUrl, onDurationChange, enabledFocus, onSeeked, onSeeking, onTimeUpdate, onPlayerReady }, ref) => {
      const [videoReady, setVideoReady] = useState<boolean>(false);
      const videoPlayerOptions: VideoJsPlayerOptions = {
        sources: [{ src: videoUrl, type: "video/mp4" }],
        preload: "auto",
        controls: true,
        fluid: true,
        enableSmoothSeeking: true,
        controlBar: {
          autoPlay: false,
          pictureInPictureToggle: true,
          playbackRateMenuButton: false,
          chaptersButton: false,
          descriptionsButton: false,
          subsCapsButton: false,
          audioTrackButton: false,
          children: [
            "playToggle",
            "currentTimeDisplay",
            "timeDivider",
            "durationDisplay",
            "progressControl",
            "fullscreenToggle",
            "volumePanel",
          ],
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          autoHide: false,
          skipButtons: { forward: 10, backward: 10 },
        },
        inactivityTimeout: 0,
        userActions: {
          click: false,
          doubleClick: true,
        },
        html5: {
          preloadTextTracks: true,
        },
      };

      return (
        <div className="w-full aspect-video rounded-t-2xl overflow-hidden" data-video-player="true">
          <div className={` video-sessions-player w-full h-full relative`}>
            <VideoPlayer
              ref={ref}
              src={videoUrl}
              onSeeked={onSeeked}
              onLoadedMetadata={(duration) => {
                onDurationChange?.(duration);
                setVideoReady(true);
              }}
              onReady={onPlayerReady}
              onSeeking={onSeeking}
              onTimeUpdate={(state) => onTimeUpdate(state.playedSeconds)}
              options={videoPlayerOptions}
              enableFocus={enabledFocus}
              className={`w-full  h-full vjs-keep-controls vjs-user-active video-js vjs-default-skin ${videoReady ? "opacity-100" : "opacity-0"} transition-all`}
            />

            <div
              className={` flex items-center justify-center bg-black h-full w-full absolute top-0 ${videoReady ? "invisible" : "visible"}`}
            >
              <div className="animate-spin border-b-2 rounded-full h-12 w-12 text-gray-400" />
            </div>
          </div>
        </div>
      );
    },
  ),
);
