import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { formatTime, useGetTickMarks } from "@pages/VideoSessions/components/EmojiComments/useGetTickMarks";
import { useSize } from "@pages/VideoSessions/components/EmojiComments/useSize";
import { EmojiMarker } from "@pages/VideoSessions/types";

interface CommentsTimelineProps {
  emojiMarkers: EmojiMarker[];
  isLoading?: boolean;
  mediaId: string;
  onMarkerClick: (time: number, commentId: string) => void;
  onTimelineClick: (time: number) => void;
  duration: number;
  currentTime: number;
}

export const CommentsTimeline: React.FC<CommentsTimelineProps> = ({
  emojiMarkers,
  isLoading = false,
  onMarkerClick,
  onTimelineClick,
  duration,
  currentTime,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useSize(containerRef, emojiMarkers, isLoading);
  const xScale = d3.scaleLinear().domain([0, duration]).range([0, width]);
  const xTicks = useGetTickMarks(duration, emojiMarkers, isLoading);
  const [hoveredMarkerId, setHoveredMarkerId] = useState<string | null>(null);
  const [horizontalPosition, setHorizontalPosition] = useState("right");
  const tooltipRef = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (hoveredMarkerId && tooltipRef.current[hoveredMarkerId]) {
      const tooltip = tooltipRef.current[hoveredMarkerId];
      if (tooltip) {
        const tooltipRectangle = tooltip.getBoundingClientRect();

        // Calculate horizontal position
        const tooltipWidth = tooltip.offsetWidth;
        const spaceLeft = tooltipRectangle.left;
        const spaceRight = window.innerWidth - tooltipRectangle.right;

        let horizontal = "center";
        if (spaceLeft >= tooltipWidth && spaceLeft >= spaceRight) {
          horizontal = "left";
        } else if (spaceRight >= tooltipWidth && spaceRight >= spaceLeft) {
          horizontal = "right";
        }
        setHorizontalPosition(horizontal);
      }
    } else {
      setHorizontalPosition("center");
    }
  }, [hoveredMarkerId]);

  const [cursorXPosition, setCursorXPosition] = useState(0);

  const [cursorIsHoveringContainer, setCursorIsHoveringContainer] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => {
      setCursorIsHoveringContainer(true);
    };

    const handleMouseLeave = () => {
      setCursorIsHoveringContainer(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      // setCursorXPosition(e.offsetX);
      const clientX = e.clientX;
      const containerRect = containerRef.current?.getBoundingClientRect();
      if (!containerRect) {
        return;
      }
      const containerLeft = containerRect.left;
      const containerRight = containerRect.right;
      if (clientX < containerLeft || clientX > containerRight) {
        setCursorIsHoveringContainer(false);
        return;
      }

      setCursorXPosition(e.clientX - containerLeft);
    };

    window.addEventListener("mousemove", handleMouseMove);
    containerRef.current?.addEventListener("mouseenter", handleMouseEnter);
    containerRef.current?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      containerRef.current?.removeEventListener("mouseenter", handleMouseEnter);
      containerRef.current?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [containerRef, xScale, xTicks]);

  const handleTimelineContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!containerRect) {
      return;
    }
    const containerLeft = containerRect.left;
    const containerRight = containerRect.right;
    const clickX = e.clientX;
    const clickPosition = (clickX - containerLeft) / (containerRight - containerLeft);
    const time = clickPosition * duration;
    onTimelineClick(time);
  };

  if (emojiMarkers.length === 0 || isLoading) return null;

  const currentTimePosition = xScale(currentTime);

  return (
    <div className="flex flex-col w-full mt-2">
      <div className="flex flex-col w-full">
        <Small>Comments Timeline</Small>
        <div ref={containerRef} className="flex items-center h-8 bg-limestone-100 text-white mt-6 w-full">
          <div className="w-full h-full cursor-pointer relative" onClick={handleTimelineContainerClick}>
            <div
              className="absolute h-8 w-[1px] bg-hatch-500 rounded-full"
              style={{ left: currentTimePosition, top: "0px", opacity: cursorIsHoveringContainer ? 1 : 0.3 }}
            >
              <div
                className={`text-hatch-500 text-[10px] font-bold whitespace-nowrap absolute -bottom-4`}
                style={{
                  transform: "translateX(-50%)", // center the text horizontally
                }}
              >
                {formatTime(xScale.invert(currentTimePosition))}
              </div>
            </div>
            <div
              className="absolute h-8 w-[1px] bg-globemallow-500 rounded-full"
              style={{ left: cursorXPosition, top: 0, display: cursorIsHoveringContainer ? "block" : "none" }}
            >
              <div
                className={`text-globemallow-500 text-[10px] font-bold whitespace-nowrap absolute -bottom-4`}
                style={{
                  transform: "translateX(-50%)", // center the text horizontally
                }}
              >
                {formatTime(xScale.invert(cursorXPosition))}
              </div>
            </div>
            {!isLoading ? (
              <div className={`w-full h-full flex flex-row items-center relative ${isLoading ? "opacity-0" : ""}`}>
                {xTicks.map((tick, index) => {
                  const xPos = xScale(tick);

                  return (
                    <div key={tick} className="absolute -top-[0px]" style={{ left: xPos }}>
                      {/* The tick line */}
                      <div className="w-[1px] h-8 bg-limestone-200 opacity-50" />

                      {/* The text label (position it below or above as you prefer) */}
                      <div
                        className={`text-text-secondary text-[10px] whitespace-nowrap absolute -top-4 ${index === 0 ? "opacity-0" : "opacity-50"}`}
                        style={{
                          transform: "translateX(-50%)", // center the text horizontally
                        }}
                      >
                        {formatTime(tick)}
                      </div>
                    </div>
                  );
                })}
                {emojiMarkers.map((marker: EmojiMarker) => (
                  <div
                    key={marker.commentId}
                    className="absolute transform -translate-x-1/2 group cursor-pointer"
                    style={{ left: `${marker.position}%` }}
                    onMouseEnter={() => setHoveredMarkerId(marker.commentId)}
                    onMouseLeave={() => setHoveredMarkerId(null)}
                    onClick={() => onMarkerClick(marker.time, marker.commentId)}
                  >
                    <div className="relative">
                      <span className={`text-sm ${marker.commentId === hoveredMarkerId ? "opacity-50" : ""}`}>
                        {marker.emoji}
                      </span>
                      {hoveredMarkerId === marker.commentId && (
                        <div
                          ref={(el) => (tooltipRef.current[marker.commentId] = el)}
                          className={`absolute bg-white shadow-lg rounded-lg p-3 w-[200px] z-50 border border-gray-200 cursor-pointer bottom-8 ${
                            horizontalPosition === "left"
                              ? "right-0"
                              : horizontalPosition === "right"
                                ? "left-0"
                                : "translate-x-[-50%]"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            onMarkerClick(marker.time, marker.commentId);
                          }}
                        >
                          <div className="flex flex-col gap-1">
                            <div className="flex items-center gap-2">
                              <Paragraph colorType="primary" className="text-sm">
                                {marker.authorFirstName} {marker.authorLastName}
                              </Paragraph>
                              <Small colorType="secondary" className="text-xs">
                                {formatTime(marker.time)}
                              </Small>
                            </div>
                            {/* <Paragraph colorType="secondary" className="text-sm">
                              {marker.authorFirstName} {marker.authorLastName}
                            </Paragraph> */}

                            <Paragraph colorType="primary" className="text-sm truncate overflow-hidden">
                              {marker.content}
                            </Paragraph>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
