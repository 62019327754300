import { APP_ENV } from "@utils/utils";
import { PropsWithChildren } from "react";

export const DevOnlyWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return APP_ENV === "dev" || APP_ENV === "local" ? <>{children}</> : null;
};

export const StagingAndBelowWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return APP_ENV === "dev" || APP_ENV === "local" || APP_ENV === "staging" ? <>{children}</> : null;
};
