import { WrenchIcon } from "@heroicons/react/24/solid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, createBrowserRouter, useLocation } from "react-router-dom";

import { Small } from "@fronterahealth/frontera-ui-components";

import { Authorize, NotAllowedPage } from "@components/Authorize/Authorize.tsx";
import { RouteErrorElement } from "@components/Error/RouteErrorElement.tsx";
import { AssessmentReportDetails } from "@pages/AssessmentReportDetails/AssessmentReportDetails.tsx";
import { ClientDetails as AssessmentReportClientDetails } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ClientDetails.tsx";
import { LongTermGoals } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/LongTermGoals.tsx";
import { Prioritize } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/Prioritize.tsx";
import { ReportSummaryPart1 } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1.tsx";
import { ReportSummaryPart2 } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2.tsx";
import { ReviewAndSign } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReviewAndSign.tsx";
import { ShortTermGoals } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ShortTermGoals.tsx";
import { UploadFiles } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles.tsx";
import { AssessmentReportV2Details } from "@pages/AssessmentReportV2Details/AssessmentReportDetails.tsx";
import { ClientDetails as AssessmentReportV2ClientDetails } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ClientDetails.tsx";
import { LongTermGoals as LongTermGoalsV2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/LongTermGoals.tsx";
import { Prioritize as PrioritizeV2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/Prioritize.tsx";
import { ReportSummaryPart1 as ReportSummaryPart1V2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1.tsx";
import { ReportSummaryPart2 as ReportSummaryPart2V2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2.tsx";
import { ReviewAndSign as ReviewAndSignV2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReviewAndSign.tsx";
import { ShortTermGoals as ShortTermGoalsV2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ShortTermGoals.tsx";
import { UploadFiles as UploadFilesV2 } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/UploadFiles.tsx";
import { ClientDetails } from "@pages/ClientDetails/ClientDetails.tsx";
import { ClientDetailsAvailability } from "@pages/ClientDetailsSubPages/Availability.tsx";
import { ClientDetailsCaregivers } from "@pages/ClientDetailsSubPages/Caregivers.tsx";
import { ClientDetailsInvites } from "@pages/ClientDetailsSubPages/ClientDetailsInvites.tsx";
import { ClientDetailsDetails } from "@pages/ClientDetailsSubPages/Details.tsx";
import { ClientDetailsEHRData } from "@pages/ClientDetailsSubPages/EHRData.tsx";
import { ClientDetailsFiles } from "@pages/ClientDetailsSubPages/Files.tsx";
import { ClientDetailsForms } from "@pages/ClientDetailsSubPages/Forms.tsx";
import { ClientDetailsInsurance } from "@pages/ClientDetailsSubPages/Insurance.tsx";
import { ClientDetailsFormsIntakeForm } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeForm.tsx";
import { IntakeFormPageSwitcher } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormPageSwitcher.tsx";
import { links } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config.ts";
import { EvaluationDetails } from "@pages/EvaluationDetails/EvaluationDetails.tsx";
import { EvaluationAppendix } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationAppendix.tsx";
import { EvaluationClientDetails } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationClientDetails.tsx";
import { EvaluationConclusions } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationConclusions.tsx";
import { EvaluationRecommendations } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationRecommendations.tsx";
import { EvaluationReportPart1 } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationReportPart1.tsx";
import { EvaluationReportPart2 } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationReportPart2.tsx";
import { EvaluationReviewAndSign } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationReviewAndSign.tsx";
import { EvaluationUploadFiles } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/EvaluationUploadFiles.tsx";
import { NewClient } from "@pages/NewClient/NewClient.tsx";
import { NewProvider } from "@pages/Providers/NewProvider/NewProvider.tsx";
import { ProviderDetailsDetails } from "@pages/Providers/ProiderDetailsSubPages/Details.tsx";
import { ProviderClients } from "@pages/Providers/ProiderDetailsSubPages/ProviderClients.tsx";
import { ProviderDetails } from "@pages/Providers/ProviderDetails/ProviderDetails.tsx";
import { LearnerSessions } from "@pages/SessionAnalyzer/LearnerSessions/LearnerSessions.tsx";
import { SessionAnalyzer } from "@pages/SessionAnalyzer/SessionAnalyzer.tsx";
import { SessionDetails } from "@pages/SessionAnalyzer/SessionDetails/SessionDetails.tsx";
import { SocialEnagagement } from "@pages/SessionAnalyzer/SessionDetails/SocialEngagement/SocialEnagagement.tsx";
import { VideoSessionDetails } from "@pages/VideoSessions/VideoSessionDetails.tsx";
import { VideoSessionsSummary } from "@pages/VideoSessions/VideoSessionsSummary.tsx";
import { AssessmentBuilderProvider } from "@providers/AssessmentBuilderProvider.tsx";
import { AssessmentBuilderProvider as AssessmentBuilderV2Provider } from "@providers/AssessmentBuilderV2Provider.tsx";
import { EvaluationProvider } from "@providers/EvaluationProvider.tsx";
import { ProvidersDataProvider } from "@providers/ProvidersDataProvider.tsx";
import { VideoSessionProvider } from "@providers/VideoSessionProvider.tsx";
import { UserSettings } from "@routes/AccountSettings/Settings.tsx";
import { AssessmentReports } from "@routes/AssessmentReports/AssessmentReports.tsx";
import { AssessmentReportDetailsRedirect } from "@routes/AssessmentReports/AssessmentReportsHelper.tsx";
import { AssessmentReportsV2 } from "@routes/AssessmentReportsV2/AssessmentReports.tsx";
import { AssessmentReportV2DetailsRedirect } from "@routes/AssessmentReportsV2/AssessmentReportsHelper.tsx";
import { ClientDashboard } from "@routes/ClientDashboard/ClientDashboard.tsx";
import { Downloads } from "@routes/Downloads.tsx";
import { Evaluations } from "@routes/Evaluations/Evaluations.tsx";
import { EvaluationDetailsRedirect } from "@routes/Evaluations/EvaluationsHelper.tsx";
import { Login } from "@routes/Login.tsx";
import { Checkout } from "@routes/Payment/Checkout.tsx";
import { Products } from "@routes/Payment/Products.tsx";
import { Providers } from "@routes/Providers.tsx";

import App from "./App.tsx";
import "./index.css";
import { roleMapping } from "./layouts/config.ts";

export const ComingSoon = () => (
  <div className="flex flex-col">
    <Small className="flex items-center">
      <WrenchIcon className="mr-2 h-6 w-6" /> Under Construction
    </Small>
  </div>
);

const ClientDetailsRedirect = () => {
  const { state } = useLocation();
  return <Navigate to="details" replace state={state} />;
};

const VideoSessionsRedirect = () => {
  const flags = useFlags();

  if (!flags["video-tooling"]) return <NotAllowedPage />;
  else return <VideoSessionsSummary />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    /**
     * what gets rendered at / (the Root route)
     * The rest of the routes will render inside of it
     */
    // This will be the element that will handle all inbound traffic
    element: <App />,
    errorElement: <RouteErrorElement />,
    children: [
      {
        path: "login",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: <Login />,
        errorElement: <RouteErrorElement />,
      },
      {
        path: "products",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: <Products />,
        errorElement: <RouteErrorElement />,
      },
      {
        path: "settings",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: <UserSettings />,
        errorElement: <RouteErrorElement />,
      },

      {
        path: "downloads",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: (
          <Authorize allowedRoles={roleMapping["/downloads"]}>
            <Downloads />
          </Authorize>
        ),
        errorElement: <RouteErrorElement />,
      },
      {
        path: "session-analyzer",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: (
          <Authorize allowedRoles={roleMapping["/session-analyzer"]}>
            <SessionAnalyzer />
          </Authorize>
        ),
        errorElement: <RouteErrorElement />,
        children: [
          {
            path: ":learnerId",
            element: <LearnerSessions />,
            children: [
              {
                path: ":sessionId",
                element: <SessionDetails />,
                children: [
                  {
                    path: "socialEngagement",
                    element: <SocialEnagagement />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "video-sessions",
        element: (
          <Authorize allowedRoles={roleMapping["/video-sessions"]}>
            <ProvidersDataProvider>
              <VideoSessionProvider>
                <VideoSessionsRedirect />
              </VideoSessionProvider>
            </ProvidersDataProvider>
          </Authorize>
        ),
        errorElement: <RouteErrorElement />,
        children: [
          {
            path: ":mediaId",
            element: <VideoSessionDetails />,
          },
        ],
      },
      {
        path: "providers",
        element: (
          <Authorize allowedRoles={roleMapping["/providers"]}>
            <ProvidersDataProvider>
              <Providers />
            </ProvidersDataProvider>
          </Authorize>
        ),
        children: [
          {
            path: "new",
            element: <NewProvider />,
          },
          {
            path: ":providerId",
            element: <ProviderDetails />,
            children: [
              {
                path: "",
                element: <ClientDetailsRedirect />,
              },
              {
                path: "details",
                element: <ProviderDetailsDetails />,
              },
              {
                path: "clients",
                element: <ProviderClients />,
              },
            ],
          },
        ],
        errorElement: <RouteErrorElement />,
      },
      {
        path: "evaluations",
        element: (
          <Authorize allowedRoles={roleMapping["/evaluations"]}>
            <Evaluations />
          </Authorize>
        ),
        children: [
          {
            path: ":evaluationId",
            element: (
              <EvaluationProvider>
                <EvaluationDetails />
              </EvaluationProvider>
            ),
            children: [
              {
                path: "",
                element: <EvaluationDetailsRedirect />,
              },
              {
                path: "client-details",
                element: <EvaluationClientDetails />,
              },
              {
                path: "upload-files",
                element: <EvaluationUploadFiles />,
              },
              {
                path: "report-part-1",
                element: <EvaluationReportPart1 />,
              },
              {
                path: "report-part-2",
                element: <EvaluationReportPart2 />,
              },
              {
                path: "conclusions",
                element: <EvaluationConclusions />,
              },
              {
                path: "recommendations",
                element: <EvaluationRecommendations />,
              },
              {
                path: "appendix",
                element: <EvaluationAppendix />,
              },
              {
                path: "review-and-sign",
                element: <EvaluationReviewAndSign />,
              },
            ],
          },
        ],
        errorElement: <RouteErrorElement />,
      },
      {
        path: "assessment-reports",
        element: (
          <Authorize allowedRoles={roleMapping["/assessment-reports"]}>
            <AssessmentReports />
          </Authorize>
        ),
        children: [
          {
            path: ":assessmentId",
            element: (
              <AssessmentBuilderProvider>
                <AssessmentReportDetails />
              </AssessmentBuilderProvider>
            ),
            children: [
              {
                path: "",
                element: <AssessmentReportDetailsRedirect />,
              },
              {
                path: "client-details",
                element: <AssessmentReportClientDetails />,
              },
              {
                path: "upload-files",
                element: <UploadFiles />,
              },
              {
                path: "report-summary-part-1",
                element: <ReportSummaryPart1 isDisplayModeForReview={false} />,
              },
              {
                path: "prioritize",
                element: <Prioritize />,
              },
              {
                path: "long-term-goals",
                element: <LongTermGoals />,
              },
              {
                path: "short-term-goals",
                element: <ShortTermGoals />,
              },
              {
                path: "report-summary-part-2",
                element: <ReportSummaryPart2 isDisplayModeForReview={false} />,
              },
              {
                path: "review-and-sign",
                element: <ReviewAndSign />,
              },
            ],
          },
        ],
        errorElement: <RouteErrorElement />,
      },
      {
        path: "assessment-reports-v2",
        element: (
          <Authorize allowedRoles={roleMapping["/assessment-reports-v2"]}>
            <AssessmentReportsV2 />
          </Authorize>
        ),
        errorElement: <RouteErrorElement />,
        children: [
          {
            path: ":assessmentId",
            element: (
              <AssessmentBuilderV2Provider>
                <AssessmentReportV2Details />
              </AssessmentBuilderV2Provider>
            ),
            children: [
              {
                path: "",
                element: <AssessmentReportV2DetailsRedirect />,
              },
              {
                path: "client-details",
                element: <AssessmentReportV2ClientDetails />,
              },
              {
                path: "upload-files",
                element: <UploadFilesV2 />,
              },
              {
                path: "report-part-1",
                element: <ReportSummaryPart1V2 />,
              },
              {
                path: "prioritize",
                element: <PrioritizeV2 />,
              },
              {
                path: "long-term-goals",
                element: <LongTermGoalsV2 />,
              },
              {
                path: "short-term-goals",
                element: <ShortTermGoalsV2 />,
              },
              {
                path: "report-part-2",
                element: <ReportSummaryPart2V2 />,
              },
              {
                path: "review-and-sign",
                element: <ReviewAndSignV2 />,
              },
            ],
            errorElement: <RouteErrorElement />,
          },
        ],
      },
      {
        path: "clients",
        element: (
          <Authorize allowedRoles={roleMapping["/clients"]}>
            <ClientDashboard />
          </Authorize>
        ),
        children: [
          {
            path: "new",
            element: <NewClient />,
          },
          {
            path: ":clientId",
            element: <ClientDetails />,
            children: [
              {
                path: "",
                element: <ClientDetailsRedirect />,
              },
              {
                path: "details",
                element: <ClientDetailsDetails />,
              },
              {
                path: "availability",
                element: <ClientDetailsAvailability />,
              },
              {
                path: "insurance",
                element: <ClientDetailsInsurance />,
              },
              {
                path: "caregivers",
                element: <ClientDetailsCaregivers />,
              },
              {
                path: "invites",
                element: <ClientDetailsInvites />,
              },
              {
                path: "EHR-data",
                element: <ClientDetailsEHRData />,
              },
              {
                path: "files",
                element: <ClientDetailsFiles />,
              },
              {
                path: "forms",
                element: <ClientDetailsForms />,
                children: [
                  {
                    path: "intake_form",
                    element: <ClientDetailsFormsIntakeForm />,
                    children: [
                      {
                        path: "all",
                        element: <IntakeFormPageSwitcher />,
                      },
                      ...links.map((link) => {
                        return {
                          path: link.name,
                          element: <IntakeFormPageSwitcher />,
                          children: link.sublinks?.map((sublink) => {
                            return {
                              path: sublink.name,
                              element: <IntakeFormPageSwitcher />,
                            };
                          }),
                        };
                      }),
                    ],
                  },
                ],
              },
            ],
          },
        ],
        errorElement: <RouteErrorElement />,
      },
    ],
  },
  {
    path: "checkout",
    /**
     * what gets rendered at / (the Root route)
     * The rest of the routes will render inside of it
     */
    element: <Checkout />,
    errorElement: <RouteErrorElement />,
  },
]);
