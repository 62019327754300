import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { FileRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/UploadFiles";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/hooks";

interface BHTIAFilesProps {
  existingBHTIAFiles: ExistingFileInterface[];
}
export const BHTIAFiles: React.FC<BHTIAFilesProps> = ({ existingBHTIAFiles }) => {
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const fileKind: FileTypeEnum = FileTypeEnum.Bhtia;
  return (
    <FileRow
      title="Behavioral Health Treatment Initial Assessment"
      subtitle="Behavioral Health Treatment Initial Assessment (BHTIA) form."
    >
      <FileUploadAndParse
        key={JSON.stringify(existingBHTIAFiles)}
        fileKind={fileKind}
        title={"BHTIA Form"}
        supportedFileTypes={["pdf", "docx"]}
        maxSize={"1GB"}
        existingFiles={existingBHTIAFiles}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
      />
    </FileRow>
  );
};
