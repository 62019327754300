import { useParams } from "react-router-dom";

import { EmojiButtonInterface } from "@pages/VideoSessions/types";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface EmojiButtonProps {
  button: EmojiButtonInterface;
  onEmojiClick: (emoji: EmojiButtonInterface) => void;
  disabled?: boolean;
  count: number | null;
}

export const EmojiButton: React.FC<EmojiButtonProps> = ({ button, onEmojiClick, disabled = false, count }) => {
  const { mediaId } = useParams();
  const { isUserRBT } = useVideoSessionData();

  return (
    <button
      key={button.value}
      onClick={(e?: React.MouseEvent<HTMLElement>) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (isUserRBT && !mediaId) return;
        onEmojiClick(button);
      }}
      disabled={disabled}
      className={`
          flex flex-row items-center
          px-4 py-2 gap-2 max-[640px]:px-2 max-[640px]:gap-1
          rounded-full
          transition-colors duration-200
          focus:outline-none
          ${
            disabled
              ? "bg-gray-50 border border-gray-200 cursor-not-allowed"
              : `
              bg-white border border-[#D9D4CF]
              hover:bg-[#EDEAE7] hover:border-[#C1B9B0]
              active:bg-[#C1B9B0]
              focus:ring-2 focus:ring-offset-2 focus:ring-[#D9D4CF]
            `
          }
        `}
    >
      <span
        className={`
            flex items-center justify-center w-4 h-4
            ${disabled ? "opacity-25" : ""}
          `}
      >
        {button.emoji}
      </span>
      <span
        className={`
            ${isUserRBT && !mediaId ? "sm:hidden max-[640px]:hidden" : "sm:inline"}
            text-sm font-semibold leading-[22px] max-[640px]:text-[12px]
            ${disabled ? "text-[#C1B9B0]" : "text-[#4E4440]"}
          `}
      >
        {button.text}
      </span>
      {isUserRBT && !mediaId ? (
        <span className={` max-[640px]:text-[12px] ${disabled ? "text-[#C1B9B0]" : "text-[#4E4440]"}`}>
          {count || 0}
        </span>
      ) : null}
    </button>
  );
};
