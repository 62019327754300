// import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

import { Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { ReportTypeEnums, useAssessmentToolsQuery, useDownloadableTemplatesQuery } from "@api/graphql/types-and-hooks";
import { DownloadsTable } from "@components/DownloadsTable/DownloadsTable";
import { useAdminData } from "@providers/AdminDataProvider";

export const Downloads: React.FC = () => {
  const { roles } = useAdminData();

  const isPsychologist = roles.includes("psychologist");
  const isBCBA = roles.includes("assessment-bcba") || roles.includes("bcba");

  const { data: diagnosisToolsData } = useAssessmentToolsQuery(
    { reportType: ReportTypeEnums.DiagnosisEvaluation },
    {
      queryKey: ["diagnosis-tools"],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
  const supportedDiagnosisToolIds =
    diagnosisToolsData?.assessmentTools
      ?.filter((tool) => tool?.parsingSupported)
      .map((tool) => tool?.id || "<missing-tool-id>") || [];

  const { data: assessmentToolsData } = useAssessmentToolsQuery(
    { reportType: ReportTypeEnums.InitialAssessment },
    {
      queryKey: ["assessment-tools"],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
  const supportedAssessmentToolIds =
    assessmentToolsData?.assessmentTools
      ?.filter((tool) => tool?.parsingSupported)
      .map((tool) => tool?.id || "<missing-tool-id>") || [];

  const tools: string[] = [];

  if (isPsychologist) {
    tools.push(...supportedDiagnosisToolIds);
  }
  if (isBCBA) {
    tools.push(...supportedAssessmentToolIds);
  }

  const { data: downloadAssessmentLinks } = useDownloadableTemplatesQuery();
  const downloadTemplateData = (downloadAssessmentLinks?.downloadableTemplates || [])
    .filter((linkObj) => tools?.includes(linkObj.assessment))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div className="flex h-screen flex-col items-start justify-start mx-16">
      <Heading type="h1">Assessment Templates</Heading>
      <Paragraph displayType="normal" colorType="primary" className="max-w-screen-lg mt-8">
        To streamline your assessment process, we offer a range of templates for scoring assessments. These templates
        are designed to help our computer models generate comprehensive and insightful reports. We also support the
        official output of each tool, which you can find linked below.
      </Paragraph>
      <div className="flex w-full flex-col rounded-lg bg-white border-limestone-100 border p-4 my-8">
        <ul className="list-disc marker:text-limestone-200 marker:text-lg pl-6">
          <li>
            <Small className="flex items-center">
              To use our templates, visit a link below and select{" "}
              <span className="text-text-primary font-semibold flex items-center mx-1">
                File <ArrowRightIcon className="w-4 h-4 inline-block mx-1" /> Make a Copy
              </span>
              within Google Sheets while logged into a Google Account{" "}
            </Small>
          </li>

          <li>
            <Small className="flex items-center">Fill out the assessment template</Small>{" "}
          </li>
          <li>
            <Small className="flex items-center">
              Finally, select
              <span className="text-text-primary font-semibold flex items-center mx-1">
                File <ArrowRightIcon className="w-4 h-4 inline-block mx-1" /> Download
                <ArrowRightIcon className="w-4 h-4 inline-block mx-1" /> Microsoft Excel (.xls/.xlsx)
              </span>
              and upload your Excel file in step 1 of the Assessment Builder{" "}
            </Small>
          </li>
        </ul>
      </div>
      <DownloadsTable data={downloadTemplateData} isLoading={false} />
    </div>
  );
};
