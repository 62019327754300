import { useParams } from "react-router-dom";

import { AssessmentReportFieldsEnums } from "@api/graphql/types-and-hooks";
import { useGoals } from "@hooks/useGoals.ts";
import { SmartFieldWrapper } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/SmartFieldWrapper";
import { getIconForSection } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/assessmentFieldEnumMapping";
import { HeadingSection } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import {
  GoalOverview,
  GoalOverviewItem,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/GoalOverview";

export const longTermGoalMapping = {
  "Behavior Reduction Goals": AssessmentReportFieldsEnums.BehaviorReductionSummary,
  "Skill Acquisition Goals": AssessmentReportFieldsEnums.SkillAcquisitionSummary,
  "Parent / Caregiver Goals": AssessmentReportFieldsEnums.ParentCaregiverSummary,
};

export const GoalsSection = ({
  isDisplayModeForReview,
  useLongTermGoals,
}: {
  isDisplayModeForReview: boolean;
  useLongTermGoals: boolean;
}) => {
  const { assessmentId: reportId } = useParams();
  const { goalSections } = useGoals({ reportId, useLongTermGoals });
  if (!goalSections) {
    return <></>;
  }

  const renderSectionContent = (sectionName: string, goals: GoalOverviewItem[], index: number) => {
    const idFromSection = sectionName.toLowerCase().split(" ").join("-");
    const iconForSection = getIconForSection(sectionName);
    const isLast = index === Object.values(goalSections).length - 1;

    if (useLongTermGoals) {
      return (
        <div className="pageBreak" key={idFromSection}>
          <HeadingSection
            key={idFromSection}
            Icon={iconForSection}
            id={idFromSection}
            label={sectionName}
            isLast={isLast}
          >
            <div className="flex flex-col">
              <SmartFieldWrapper
                field={longTermGoalMapping[sectionName as keyof typeof longTermGoalMapping]}
                section={sectionName}
                isDisplayMode={isDisplayModeForReview}
              />
              <div className="goals">
                <GoalOverview defaultOpenState={isDisplayModeForReview} longTermGoals={goals} hideHeaderIcon />
              </div>
            </div>
          </HeadingSection>
        </div>
      );
    }

    return (
      <div className="pageBreak" key={idFromSection}>
        <HeadingSection
          Icon={iconForSection}
          key={idFromSection}
          id={idFromSection}
          label={sectionName}
          isLast={isLast}
        >
          <div id={`heading-${idFromSection}`} data-sticky-nav-header={true}>
            <GoalOverview showChildrenOnly={true} hideHeaderIcon={true} longTermGoals={goals} />
          </div>
        </HeadingSection>
      </div>
    );
  };

  return (
    <>
      {Object.entries(goalSections).map(([sectionName, goals], index) =>
        renderSectionContent(sectionName, goals, index),
      )}
    </>
  );
};
