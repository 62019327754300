import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { FileRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/UploadFiles";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/UploadFiles/hooks";

interface DiagnosisReportProps {
  existingDiagnosis: ExistingFileInterface | undefined;
}
export const DiagnosisReport: React.FC<DiagnosisReportProps> = ({ existingDiagnosis }) => {
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const fileKind: FileTypeEnum = FileTypeEnum.DiagnosisReport;

  return (
    <FileRow
      title="Diagnosis Report"
      subtitle="Diagnosis report from the psychologist that contains the autism diagnosis."
    >
      <FileUploadAndParse
        key={JSON.stringify(existingDiagnosis)}
        fileKind={fileKind}
        existingFiles={existingDiagnosis ? [existingDiagnosis] : undefined}
        title={"Diagnosis Report"}
        singleFileUpload
        supportedFileTypes={["pdf", "jpg", "jpeg", "png"]}
        maxSize={"1GB"}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
      />
    </FileRow>
  );
};
