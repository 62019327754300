import { ChartBarSquareIcon } from "@heroicons/react/24/solid";
import { formatSecondsToHHMMSS } from "@utils/utils";
import { useEffect, useState } from "react";

import { Small, Tooltip } from "@fronterahealth/frontera-ui-components";

import { ClassificationSubClassEnum } from "@api/graphql/types-and-hooks";
import { VideoMomentInterface } from "@pages/VideoSessions/components/Moments/VideoMoments";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

export const MomentTypeMapping: Record<ClassificationSubClassEnum, { color: string; label: string }> = {
  [ClassificationSubClassEnum.Aggression]: { color: "bg-globemallow-600", label: "Aggression" },
  [ClassificationSubClassEnum.CryingAndScreaming]: { color: "bg-hatch-600", label: "Crying And Screaming" },
};

interface MomentCardProps {
  moment: VideoMomentInterface;
  onClick: () => void;
  videoSeeking: boolean;
}
export const MomentCard: React.FC<MomentCardProps> = ({ moment, videoSeeking }) => {
  // TODO: Add Feedback Logic back in
  // const [feedbackValue, setFeedbackValue] = useState("");

  const { playerRef } = useVideoSessionData();

  const jumpToMomentTime = () => {
    if (playerRef.current?.playInRange) {
      playerRef.current.playInRange(moment.startTime, moment.endTime);
    }
  };

  const [momentCardSeeking, setMomentCardSeeking] = useState<false | number>(false);

  useEffect(() => {
    if (videoSeeking === false) {
      setMomentCardSeeking(false);
    }
  }, [videoSeeking]);

  return (
    <div
      onClick={() => {
        setMomentCardSeeking(moment.startTime);
        jumpToMomentTime();
      }}
      className={`cursor-pointer ${momentCardSeeking === moment.startTime ? "bg-limestone-50 animate-pulse border-green-800 border" : "bg-white border-limestone-200 border hover:border-limestone-300 "} hover:bg-limestone-50 relative flex flex-col items-start p-4 pb-6 transition-all rounded-2xl gap-6`}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <div className={`h-4 w-4 rounded-full ${MomentTypeMapping[moment.type!].color} mr-2`} />
          <Small colorType="primary">{MomentTypeMapping[moment.type!].label} Detected </Small>
        </div>

        <div
          onClick={() => {
            setMomentCardSeeking(moment.startTime);
            jumpToMomentTime();
          }}
          className="flex items-center group gap-x-1 text-interaction-primary  hover:text-interaction-primary-hover hover:underline cursor-pointer transition-all"
        >
          <Small className="text-interaction-primary group-hover:text-interaction-primary-hover transition-all">
            {formatSecondsToHHMMSS(moment.startTime)}
          </Small>
          <Small className="no-underline group-hover:text-interaction-primary-hover">-</Small>
          <Small className="text-interaction-primary group-hover:text-interaction-primary-hover transition-all">
            {formatSecondsToHHMMSS(moment.endTime)}
          </Small>
        </div>
      </div>

      <div className="flex items-center justify-between w-full">
        <Tooltip text={"Confidence Interval"} placement="right" showInfoIcon={false}>
          <div className="flex items-center gap-x-1 bg-limestone-50 rounded-sm px-2 py-1">
            <ChartBarSquareIcon className="text-text-secondary w-4 h-4 mr" />
            <Small className="text-text-secondary">{moment.confidenceLevel}%</Small>
          </div>
        </Tooltip>
        {/* TODO: Add Feedback Logic back in */}
        {/* <PopoverPanel
          content={
            <div className="p-4">
              <Paragraph>Tell us what's wrong with it</Paragraph>
              <TextArea value={feedbackValue} onChange={(e) => setFeedbackValue(e.target.value)} label={"Feedback"} />
              <Button text={"Submit"} appearance={"primary"} />
            </div>
          }
          direction="bottom-left"
          height="max-h-[300px]"
          width="w-[364px]"
        >
          <FlagIcon className="w-4 h-4 text-interaction-primary hover:text-interaction-primary-hover transition-all" />
        </PopoverPanel> */}
      </div>
    </div>
  );
};
