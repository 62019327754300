import { useLocation, useNavigate } from "react-router-dom";

import { Button, Tooltip } from "@fronterahealth/frontera-ui-components";

import { notifyError } from "@components/notifications/notifications";
import {
  InitialAssessmentPageRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { useUIState } from "@providers/UIStateProvider";

interface FooterButtonRowProps {
  primaryButtonTitle?: string;
  primaryButtonLoading?: boolean;
  primaryButtonDisabled?: boolean;
  primaryButtonType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  primaryButtonAction?: () => Promise<unknown>;
  primaryButtonDisabledTooltipReason?: string;
  stickyToBottom?: boolean;
  skipNavigate?: boolean;
}
export const FooterButtonRow: React.FC<FooterButtonRowProps> = ({
  primaryButtonAction,
  primaryButtonType = "button",
  primaryButtonTitle = "Next Step",
  primaryButtonDisabled = false,
  primaryButtonDisabledTooltipReason = "",
  primaryButtonLoading,
  stickyToBottom = true,
  skipNavigate = false,
}) => {
  const { pathname } = useLocation();
  const { sidebarCollapsed } = useUIState();
  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);
  const { getNextRoute, getPastRoute } = useOrderedAssessmentReportSubRoutes();
  const nextRoute = getNextRoute(currentRoute);
  const pastRoute = getPastRoute(currentRoute);

  const go = useNavigate();

  return (
    <div
      id="btnRow"
      // NOTE: 288 comes from the width of the sidebar expanded, 72px from that width of the sidebar collapsed
      className={`-ml-6 flex w-full ${sidebarCollapsed ? "max-w-[calc(100vw-72px)]" : "max-w-[calc(100vw-288px)]"} items-center justify-end border-t border-limestone-100 px-8 ${stickyToBottom ? "fixed bottom-0" : ""}  z-10 bg-white py-[11.5px]`}
    >
      <div className="flex items-center gap-2">
        <Button
          appearance="secondary"
          text="Back"
          onClick={() => {
            if (pastRoute) {
              window.scrollTo({ top: 0 });
              go(`../${pastRoute}`);
            }
          }}
        />
        {primaryButtonDisabled && primaryButtonDisabledTooltipReason ? (
          <Tooltip text={primaryButtonDisabledTooltipReason} showInfoIcon={false}>
            <Button
              appearance="primary"
              disabled={primaryButtonDisabled}
              text={primaryButtonLoading ? "Loading..." : primaryButtonTitle}
              type={primaryButtonType}
              onClick={
                primaryButtonType === "submit"
                  ? undefined
                  : async () => {
                      if (nextRoute) {
                        window.scrollTo({ top: 0 });
                        if (primaryButtonAction) {
                          try {
                            await primaryButtonAction();
                            if (!skipNavigate) {
                              go(`../${nextRoute}`);
                            }
                          } catch (err) {
                            console.error("Error calling primaryButtonAction from FooterButtonRow");
                            notifyError("Failed to perform action");
                            return;
                          }
                        } else {
                          if (!skipNavigate) {
                            window.scrollTo({ top: 0 });
                            go(`../${nextRoute}`);
                          }
                        }
                      }
                    }
              }
            />
          </Tooltip>
        ) : (
          <Button
            appearance="primary"
            disabled={primaryButtonDisabled}
            text={primaryButtonLoading ? "Loading..." : primaryButtonTitle}
            type={primaryButtonType}
            onClick={
              primaryButtonType === "submit"
                ? undefined
                : async () => {
                    if (nextRoute) {
                      window.scrollTo({ top: 0 });
                      if (primaryButtonAction) {
                        try {
                          await primaryButtonAction();
                          if (!skipNavigate) {
                            go(`../${nextRoute}`);
                          }
                        } catch (err) {
                          console.error("Error calling primaryButtonAction from FooterButtonRow");
                          notifyError("Failed to perform action");
                          return;
                        }
                      } else {
                        if (!skipNavigate) {
                          window.scrollTo({ top: 0 });
                          go(`../${nextRoute}`);
                        }
                      }
                    }
                  }
            }
          />
        )}
      </div>
    </div>
  );
};
