import { GenderApiValues, GenderMappingToApi, GenderMappingToUi, GenderUiValues } from "@utils/constants";
import { Link } from "react-router-dom";

import { BreadcrumbLink } from "@fronterahealth/frontera-ui-components";

import {
  ApiAssessmentClientDetailsGenderChoices,
  ApiLearnerMetadataGenderChoices,
  ApiProviderMetadataGenderChoices,
  ApiReportClientDetailsGenderChoices,
} from "@api/graphql/types-and-hooks";

export const getGenderValue = (
  value:
    | GenderApiValues
    | null
    | undefined
    | ApiProviderMetadataGenderChoices
    | ApiAssessmentClientDetailsGenderChoices
    | ApiLearnerMetadataGenderChoices
    | ApiReportClientDetailsGenderChoices,
) => {
  const genderValue = value === "M" || value === "F" ? value : value?.toLowerCase();
  if (genderValue) return GenderMappingToUi[genderValue as GenderApiValues];

  return null;
};

export const setGenderValue = (value: GenderUiValues | null | undefined | string) => {
  if (value) return GenderMappingToApi[value as GenderUiValues];
  return null;
};

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const pathNameToBreadCrumbLinks = (pathname: string): BreadcrumbLink[] => {
  const links = pathname.slice(1).split("/");

  const linksToRender = links.length > 2 ? links.slice(0, 2) : links;

  return linksToRender.map((link, index) => {
    const decodedLink = decodeURI(link);

    return {
      name: link.toLocaleUpperCase(),
      current: link === links[links.length - 1],
      type: "component",
      Component: (
        <Link to={`/${links.slice(0, index + 1).join("/")}`}>
          {`${decodedLink
            .split("-")
            .map((word) => capitalizeString(word))
            .join(" ")}`}
        </Link>
      ),
    };
  });
};

export const formatPlayerTime = (startSeconds: number): string => {
  const hours = Math.floor(startSeconds / 3600);
  const minutes = Math.floor((startSeconds % 3600) / 60);
  const seconds = Math.floor(startSeconds % 60);

  // Pad with leading zeros if necessary
  const hoursStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const getPronoun = (
  gender:
    | GenderApiValues
    | null
    | undefined
    | ApiProviderMetadataGenderChoices
    | ApiAssessmentClientDetailsGenderChoices
    | ApiLearnerMetadataGenderChoices
    | ApiReportClientDetailsGenderChoices,
): "She/Her" | "He/His" | "They/Them" | "Prefer Not to Answer" => {
  switch (gender) {
    case "F":
      return "She/Her";
    case "M":
      return "He/His";
    case "NON_BINARY":
    case "AGENDER":
    case "GENDERQUEER_OR_GENDERFLUID":
    case "GENDER_NOT_LISTED":
    case "QUESTIONING_OR_UNSURE":
      return "They/Them";
    case "PREFER_NOT_TO_DISCLOSE":
    case null:
    case undefined:
      return "Prefer Not to Answer";
    default:
      return "Prefer Not to Answer";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupArrayByKeyValue = (arr: Record<string, any>[], key: string) => {
  return arr.reduce((accumulator, current) => {
    const value = current[key];
    if (!accumulator[value]) {
      accumulator[value] = [];
    }
    accumulator[value].push(current);
    return accumulator;
  }, {});
};
