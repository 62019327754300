import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";

import { Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import { UserInitialsView } from "@components/UserInitial/UserInitialsView";
import { SessionRating } from "@pages/VideoSessions/components/Utils/SessionRating";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface HeaderContentProps {
  learnerFullName: string;
  providerFirstName?: string;
  providerLastName?: string;
  date: string;
  sessionRating?: number;
}

export const HeaderContent: React.FC<HeaderContentProps> = ({
  learnerFullName,
  providerFirstName,
  providerLastName,
  date,
  sessionRating,
}) => {
  const { setSelectedTab, isVideoDetailsPage } = useVideoSessionData();

  return (
    <div className="flex justify-between w-[100%]">
      <div className="w-9/10">
        <div className="flex flex-col sm:flex-row sm:items-baseline gap-1 sm:gap-4">
          <Heading type="h2">{learnerFullName}</Heading>
          <Paragraph displayType="normal">{date}</Paragraph>
        </div>
        <div className="flex flex-wrap gap-2 mt-1">
          <Paragraph colorType="secondary" className="text-sm">
            Attending Provider:{" "}
          </Paragraph>
          <UserInitialsView firstName={providerFirstName || ""} lastName={providerLastName || ""} />
          {sessionRating && <SessionRating rating={sessionRating} />}
        </div>
      </div>
      {isVideoDetailsPage ? null : (
        <div className="w-1/10">
          <DocumentMagnifyingGlassIcon
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("transcript");
            }}
            className="h-6 w-6 text-limestone-600 inline mr-2"
          />
        </div>
      )}
    </div>
  );
};
