import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Heading, Small } from "@fronterahealth/frontera-ui-components";

import {
  ApiAssessmentAiReport2PredictionStatusChoices,
  ApiAssessmentAssessmentStatusChoices,
  AssessmentReportFieldsEnums,
  useAddUpdateAssessmentFieldValuesMutation,
  useGetAssessmentQuery,
  useUpdateAssessmentStatusAndUploadPdfMutation,
} from "@api/graphql/types-and-hooks";
import useGenerateDocx from "@components/PDFGenerator/useGenerateDOCX";
import { notifyError, notifySuccess, notifyWarn } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { ReportSummaryPart1 } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1";
import { ReportSummaryPart2 } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2";
import { StickyNav } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { SignaturePart } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/SignaturePart";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export const ReviewHeader: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center mb-4 gap-5 ">
        <div className="mr-2 h-10 w-10 bg-limestone-200 border-globemallow-100 border rounded-md p-1 flex items-center justify-center">
          <ArrowDownIcon className="h-6 w-6 text-white " />
        </div>
        <div>
          <Heading type="h4">Review Assessment Report</Heading>

          <Small displayType="normal" colorType="secondary">
            Please review the information below to verify it’s accuracy before approving the final document.
          </Small>
        </div>
      </div>
    </div>
  );
};

export const ReviewAndSign: React.FC = () => {
  const [signature, setSignature] = useState<string>("");
  const { assessmentId } = useParams();

  const [date, setDate] = useState<Date>(new Date());
  const { assessmentReport, refetch } = useAssessmentBuilderData();

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", assessmentId],
      refetchOnWindowFocus: true,
    },
  );
  const [reportGenerating, setReportGenerating] = useState(false);

  const { generateDocx, loading } = useGenerateDocx();

  const addUpdateAssessmentFieldValuesMutation = useAddUpdateAssessmentFieldValuesMutation({});

  const updateReportStatusMutation = useUpdateAssessmentStatusAndUploadPdfMutation({});

  const reportIsCompleted = assessmentReport.assessmentStatus === ApiAssessmentAssessmentStatusChoices.Completed;

  const aiReportPartTwoStatus = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.aiReport2PredictionStatus;

  const reportNotYetKickedOff = aiReportPartTwoStatus === ApiAssessmentAiReport2PredictionStatusChoices.Pending;

  useEffect(() => {
    if (reportIsCompleted) {
      const signatureData = assessmentReport.assessmentReportFields.edges.find(
        (item) => item?.node?.fieldKey === AssessmentReportFieldsEnums.ReportSignature,
      );
      if (signatureData?.node?.value) {
        try {
          const signatureValue = JSON.parse(signatureData.node.value);
          setSignature(signatureValue.signature || "");
          setDate(new Date(signatureValue.date) || new Date());
        } catch (error) {
          console.error("Error parsing signature data:", error);
        }
      }
    }
  }, [reportIsCompleted]);

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Review" />;
  }

  return (
    <div className={`w-full h-full flex flex-col`}>
      <div className="grid grid-cols-4 gap-x-1 w-full">
        <div className="col-span-3 flex flex-col gap-y-4">
          {!reportIsCompleted && (
            <div>
              <ReviewHeader />
            </div>
          )}
          <div id="assessmentReport" className="grid grid-cols-1 gap-y-4 ">
            <ReportSummaryPart1 isDisplayModeForReview={true} />
            <ReportSummaryPart2 isDisplayModeForReview={true} />
            <SignaturePart
              signature={signature}
              setSignature={setSignature}
              date={date}
              setDate={setDate}
              isReadOnly={reportIsCompleted || reportGenerating}
            />
          </div>
        </div>
        <div className="flex flex-col items-end ">
          <StickyNav state={{}} headingType="h4" />
        </div>
      </div>
      {!reportIsCompleted ? (
        <FooterButtonRow
          primaryButtonTitle="Sign & Complete"
          primaryButtonLoading={loading || reportGenerating}
          primaryButtonDisabled={loading || reportGenerating}
          primaryButtonAction={async () => {
            if (signature.replace(/\s/g, "").length === 0) {
              notifyWarn("To generate the report, you need to provide your signature.");
              return window.scroll({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            }
            setReportGenerating(true);
            const element = document.getElementById("assessmentReport");

            const file = await generateDocx(element);
            if (file) {
              await addUpdateAssessmentFieldValuesMutation.mutateAsync({
                assessmentId: assessmentReport.id || "",
                assessmentFieldDetail: {
                  fieldKey: AssessmentReportFieldsEnums.ReportSignature,
                  fieldValue: JSON.stringify({ signature, date }),
                },
              });

              return await updateReportStatusMutation!.mutateAsync(
                {
                  assessmentId: assessmentReport.id || "",
                  assessmentReportFile: file,
                },
                {
                  onSuccess: async () => {
                    setReportGenerating(false);
                    await refetch(); // refetch assessmentBuilderProvide data

                    notifySuccess("Successfully Submitted Assessment Report");
                  },
                  onError: async (error) => {
                    setReportGenerating(false);
                    console.error("Error Saving Assessment Report", error);
                    notifyError("Error Saving Assessment Report");
                  },
                },
              );
            }
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
