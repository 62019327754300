import {
  LongTermGoalTypeEdge,
  Maybe,
  ReportType,
  ShortTermGoalType,
  ShortTermGoalTypeEdge,
} from "@api/graphql/types-and-hooks";

export interface OrganizedCatalightGoals {
  receptiveCommunicationGoals: ShortTermGoalType[];
  expressiveCommunicationGoals: ShortTermGoalType[];
  pragmaticCommunicationGoals: ShortTermGoalType[];
  selfHelpDailyLivingSkills: ShortTermGoalType[];
  behaviorGoals: ShortTermGoalType[];
  caregiverTrainingGoals: ShortTermGoalType[];
}

const organizeCatalightGoals = (
  longTermGoals: Maybe<LongTermGoalTypeEdge>[],
  shortTermGoals: Maybe<ShortTermGoalTypeEdge>[],
): OrganizedCatalightGoals => {
  const result: OrganizedCatalightGoals = {
    receptiveCommunicationGoals: [],
    expressiveCommunicationGoals: [],
    pragmaticCommunicationGoals: [],
    selfHelpDailyLivingSkills: [],
    behaviorGoals: [],
    caregiverTrainingGoals: [],
  };

  // Create a map of long-term goal IDs to their categories
  const longTermGoalCategories = new Map<string, keyof OrganizedCatalightGoals>();
  if (longTermGoals) {
    longTermGoals.forEach((goalEdge) => {
      if (!goalEdge?.node) return;

      const { id, goalName } = goalEdge.node;
      if (!id || !goalName) return;

      switch (goalName) {
        case "Receptive Communication":
          longTermGoalCategories.set(id, "receptiveCommunicationGoals");
          break;
        case "Expressive Communication":
          longTermGoalCategories.set(id, "expressiveCommunicationGoals");
          break;
        case "Pragmatic Communication":
          longTermGoalCategories.set(id, "pragmaticCommunicationGoals");
          break;
        case "Self Help / Daily Living Skills":
          longTermGoalCategories.set(id, "selfHelpDailyLivingSkills");
          break;
        case "Behavior":
          longTermGoalCategories.set(id, "behaviorGoals");
          break;
        case "Caregiver Training":
          longTermGoalCategories.set(id, "caregiverTrainingGoals");
          break;
      }
    });
  }

  if (shortTermGoals) {
    shortTermGoals.forEach((goalEdge) => {
      if (!goalEdge?.node) return;

      const { longTermGoal } = goalEdge.node;
      if (!longTermGoal?.id) return;

      const category = longTermGoalCategories.get(longTermGoal.id);
      if (category) {
        result[category].push(goalEdge.node);
      }
    });
  }

  return result;
};

export const organizeCatalightTreatmentPlanGoals = (reportData: ReportType): OrganizedCatalightGoals => {
  const longTermGoals = reportData.reportTreatmentPlan?.longtermgoalSet?.edges || [];
  const shortTermGoals = reportData.reportTreatmentPlan?.shorttermgoalSet?.edges || [];

  return organizeCatalightGoals(longTermGoals, shortTermGoals);
};

type InputContent = {
  customRowAttributeField: string;
  data: Array<{
    Domain: string;
    "Standard Score": string;
    "V-Scale Score": string;
    "Adaptive Level": string;
    "Percentile Rank": string;
    "Age Equivalent (optional)": string;
    customRowAttributeField?: string[];
  }>;
  headers: string[];
};

type TransformedData = {
  [key: string]: string | undefined;
};

export const transformServicesTables = (content: string, prefix: string): TransformedData => {
  try {
    const parsed = JSON.parse(content);
    if (!parsed.data || !parsed.headers) {
      throw new Error("Invalid content format");
    }

    const formattedData: TransformedData = {};

    parsed.headers.forEach((header: string) => {
      const key = header
        .replace(/[^a-zA-Z0-9]+(.)/g, (_: unknown, char: string) => char.toUpperCase()) // Capitalize after special characters
        .replace(/^[0-9]/, "") // Remove leading numbers
        .replace(/^([A-Za-z])/, (match) => match.toUpperCase()) // Ensure the first letter stays uppercase to keep prefix + key as camelCase
        .replace(/(?<=^.{1})([A-Z])/g, (match) => match.toLowerCase()); // Lowercase all letters after the first

      const value = parsed.data[0]?.[header] ?? "";
      formattedData[prefix + key] = value;
    });

    return formattedData;
  } catch (error) {
    console.error("Error parsing content:", error);
    return {};
  }
};

export const transformVinelandFormData = (content: string): TransformedData => {
  try {
    const parsed = JSON.parse(content);
    const result: Record<string, string> = {};

    parsed.data.forEach((item: { Key: unknown; Value: string }) => {
      if (!item || typeof item.Key !== "string") {
        console.warn("Invalid item found:", item);
        return;
      }

      const key = item.Key;
      const value = item.Value ?? "";

      // Convert the key to camelCase
      const camelCaseKey = key
        .toLowerCase()
        // Remove special characters and spaces, capitalize next letter
        .replace(/[^a-zA-Z0-9]+(.)/g, (_: unknown, char: string) => char.toUpperCase())
        // Handle the case where the string starts with a number
        .replace(/^[0-9]/, "");

      result[camelCaseKey] = value;
    });

    return result;
  } catch (error) {
    console.error("Error transforming content:", error);
    throw new Error(`Failed to transform content: ${error instanceof Error ? error.message : "Unknown error"}`);
  }
};

export const transformVinelandTableData = (content: string): TransformedData => {
  try {
    const parsed: InputContent = JSON.parse(content);
    const result: TransformedData = {
      //vineland optional fields (to prevent undefined in output)
      "motorSkillsOptional)StandardScore": "",
      "motorSkillsOptional)VScaleScore": "",
      "motorSkillsOptional)AdaptiveLevel": "",
      "motorSkillsOptional)PercentileRank": "",
      "motorSkillsOptional)AgeEquivalent": "",
      fineMotorStandardScore: "",
      fineMotorVScaleScore: "",
      fineMotorAdaptiveLevel: "",
      fineMotorPercentileRank: "",
      fineMotorAgeEquivalent: "",
      grossMotorStandardScore: "",
      grossMotorVScaleScore: "",
      grossMotorAdaptiveLevel: "",
      grossMotorPercentileRank: "",
      grossMotorAgeEquivalent: "",
      "maladaptiveBehaviorOptional)StandardScore": "",
      "maladaptiveBehaviorOptional)VScaleScore": "",
      "maladaptiveBehaviorOptional)AdaptiveLevel": "",
      "maladaptiveBehaviorOptional)PercentileRank": "",
      "maladaptiveBehaviorOptional)AgeEquivalent": "",
      internalizingStandardScore: "",
      internalizingVScaleScore: "",
      internalizingAdaptiveLevel: "",
      internalizingPercentileRank: "",
      internalizingAgeEquivalent: "",
      externalizingStandardScore: "",
      externalizingVScaleScore: "",
      externalizingAdaptiveLevel: "",
      externalizingPercentileRank: "",
      externalizingAgeEquivalent: "",
    };

    // Process each data entry
    parsed.data.forEach((item) => {
      const domainKey = item.Domain.toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
        .replace(/\s/g, "");

      // Add each metric as a separate property
      result[`${domainKey}StandardScore`] = item["Standard Score"] ?? "";
      result[`${domainKey}VScaleScore`] = item["V-Scale Score"] ?? "";
      result[`${domainKey}AdaptiveLevel`] = item["Adaptive Level"] ?? "";
      result[`${domainKey}PercentileRank`] = item["Percentile Rank"] ?? "";
      result[`${domainKey}AgeEquivalent`] = item["Age Equivalent (optional)"] ?? "";
    });

    return result;
  } catch (error) {
    throw new Error(`Failed to parse or transform vineland table content: ${error}`);
  }
};
