import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

import { notifySuccess } from "@components/notifications/notifications";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

export function Checkout() {
  const [clientSecret, setClientSecret] = useState("");
  const { state } = useLocation();
  const go = useNavigate();
  const { user } = useAuth0();
  const [searchParams] = useSearchParams();
  const flags = useFlags();
  const route = flags["assessment-builder-backport"] ? "/assessment-reports-v2" : "/assessment-reports";
  useEffect(() => {
    // Check to ensure user should be able to access this page and redirect if not
    // TODO: This permission logic should be moved to the router level if possible
    if (user) {
      try {
        const rolesKey = `${config.auth0Audience}/selfServeUser`;
        const isSelfServeUser = user?.[rolesKey];
        if (!isSelfServeUser)
          go(route, {
            replace: true,
          });
      } catch (err) {
        console.error("Error getting roles ", err);
        go(route, { replace: true });
      }
    }
  }, [user]);

  const isRedirect = searchParams.get("loading") === "true";

  useEffect(() => {
    if (state) {
      const { clientSecret } = state;

      setClientSecret(clientSecret);
    }
  }, []);

  useEffect(() => {
    if (isRedirect) {
      notifySuccess("Payment successful! Redirecting to your assessment reports...");
      setTimeout(() => {
        go(route);
      }, 3000);
    }
  }, []);

  return (
    <div className="">
      <div className="flex items-center w-1/2 justify-center pl-16 py-16 h-8">
        <Link to="/products" className="flex items-center">
          {!isRedirect && <ArrowLeftIcon className="h-6 w-6" />}
          <img src={logo} alt="" className="h-8 pl-8" />
        </Link>
      </div>
      {!isRedirect && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret: clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}
