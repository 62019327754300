import { useAuth0 } from "@auth0/auth0-react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { formatPlayerTime } from "@utils/helpers";
import { formatDistanceToNow } from "date-fns";
import { useState } from "react";

import { Dialog, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { useDeleteMediaCommentMutation } from "@api/graphql/types-and-hooks";
import { UserInitialsView } from "@components/UserInitial/UserInitialsView";
import { convertReadableString } from "@components/forms/utils";
import CommentEditForm from "@pages/VideoSessions/components/Comments/CommentEditForm";
import { Comment, getEmojiByValue } from "@pages/VideoSessions/components/Comments/helper";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface CommentCardProps extends Comment {
  onTimeClick: (time: number) => void;
  handleEditSubmit: () => void;
  onClick?: () => void;
}

export const CommentCard: React.FC<CommentCardProps> = ({
  id,
  authorFirstName,
  authorLastName,
  content,
  type,
  time,
  parentCommentID,
  createdDate,
  onTimeClick,
  auth0Id,
  handleEditSubmit,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { user } = useAuth0();
  const { editingCommentId, setEditingCommentId } = useVideoSessionData();
  const deleteCommentMutation = useDeleteMediaCommentMutation();

  const handleDeleteComment = async () => {
    await deleteCommentMutation
      .mutateAsync({
        commentId: id,
      })
      .then((data) => {
        if (data.removeMediaComment?.status) {
          setIsDialogOpen(false);
          handleEditSubmit();
        }
      });
  };

  return (
    <div
      key={id}
      className="relative flex gap-2 w-full transition-colors"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <UserInitialsView firstName={authorFirstName} lastName={authorLastName} />
      {editingCommentId === id ? (
        <CommentEditForm commentId={id} existingContent={content} handleCommentSubmit={handleEditSubmit} />
      ) : (
        <>
          <div className="relative items-start gap-2">
            {/* Author Name and Time */}
            <div className="flex flex-col">
              <div className="flex flex-row gap-2 items-start">
                <Small displayType="loud" colorType="primary">
                  {authorFirstName} {authorLastName}
                </Small>
                {!parentCommentID && (
                  <div className="flex gap-3">
                    {/* Time */}
                    <Small
                      colorType="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        onTimeClick(time);
                      }}
                      className="cursor-pointer flex flex-row gap-2 items-start text-interaction-primary"
                    >
                      <p className="font-bold text-text-primary">•</p> {formatPlayerTime(time)}
                    </Small>

                    {/* Comment Type Badge */}
                    <div className="flex flex-raw gap-1">
                      <Small>{type ? getEmojiByValue(type) : null}</Small>
                      <Small className="text-black">{convertReadableString(type)}</Small>
                    </div>
                  </div>
                )}
              </div>

              <Small displayType="normal">{formatDistanceToNow(new Date(createdDate), { addSuffix: true })}</Small>
            </div>

            {/* Comment Text */}
            <Paragraph className="text-[#454545] break-words break-all">{content}</Paragraph>
          </div>

          {/* Action buttons */}
          {isHovered && user?.sub === auth0Id && (
            <div className="absolute right-2 flex items-center space-x-3">
              <TrashIcon
                className="w-4 h-4 cursor-pointer text-text-secondary hover:text-error-600"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!parentCommentID) {
                    setIsDialogOpen(true);
                  } else {
                    await handleDeleteComment();
                  }
                }}
              />
              <PencilIcon
                className="w-4 h-4 cursor-pointer text-text-secondary hover:text-primary-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingCommentId(id);
                }}
              />
            </div>
          )}
        </>
      )}

      {/* Delete Dialog */}
      <Dialog
        size="sm"
        showCloseButton={false}
        title="Delete Comment"
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        primaryButton={{
          text: "Yes, Delete",
          onClick: handleDeleteComment,
          type: "submit",
          buttonAction: "destructive",
        }}
        secondaryButton={{
          text: "No, Cancel",
          onClick: () => setIsDialogOpen(false),
        }}
      >
        <div className="py-2 text-text-primary text-base">
          Are you sure you want to delete this comment? This will remove the entire conversation.
        </div>
      </Dialog>
    </div>
  );
};
