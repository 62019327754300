import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { datadogRum } from "@datadog/browser-rum";
import { QueryClient } from "@tanstack/react-query";
import React, { useState } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import { PopoverMenu } from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import {
  CreateGoalBankLongTermGoalsInput,
  GoalTypeEnums,
  LongTermGoalType,
  useCreateGoalBankLongTermGoalsMutation,
  useRemoveReportTreatmentPlanLongTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import { getFolderSelections, getOrgFolderSection, getUserFolderSection } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

interface LongTermGoalCardProps {
  onClick: () => void;
  goalObject: LongTermGoalType;
  refetchGoals: () => Promise<void>;
  uiGoalId?: string;
}

export const LongTermGoalCard: React.FC<LongTermGoalCardProps> = ({ onClick, goalObject, refetchGoals, uiGoalId }) => {
  const { user } = useAuth0();
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const { assessmentQuery } = useAssessmentBuilderData();
  const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";
  const removeLongTermGoalMutation = useRemoveReportTreatmentPlanLongTermGoalMutation({});
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [showSaveToFolderDialog, setShowSaveToFolderDialog] = React.useState<boolean>(false);
  const [queryClient] = useState(() => new QueryClient());
  const createLongTermGoalBank = useCreateGoalBankLongTermGoalsMutation({});

  const { userFolders, orgFolders } = useGoalBankFolders();
  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);

  const { formState, RegisteredFormSelected, reset, onSubmit } = useFormUtils<CreateGoalBankLongTermGoalsInput>({
    mutationFn: async (params) => {
      if (!params.folderId) return;
      if (params.folderId)
        try {
          createLongTermGoalBank.mutate({
            input: {
              folderId: params.folderId,
              longtermGoalsData: [
                {
                  aiSuggestedLongGoal: goalObject.aiGenerated ? goalObject.aiSuggestedGoal?.id : null,
                  description: goalObject.description,
                  goalName: goalObject.goalName,
                  goalType: convertDBString(goalObject.goalType ?? "") as unknown as GoalTypeEnums,
                },
              ],
            },
          });
        } catch (err) {
          notifyError("Failed To Save To Folder");
          console.error("Error saving to folder", err);
          datadogRum.addError("Failed to save long term goal to folder");
        } finally {
          notifySuccess("Saved To Folder");
          setShowSaveToFolderDialog(false);
          await queryClient.refetchQueries();
        }
    },
  });

  return (
    <div>
      <FolderManagementDialog
        action={FolderManagementAction.SAVE}
        open={showSaveToFolderDialog}
        onCancelClick={() => {
          reset();
          setShowSaveToFolderDialog(false);
        }}
        onSaveClick={onSubmit}
      >
        <FormContainer onSubmit={onSubmit}>
          <RegisteredFormSelected
            formKey="folderId"
            required
            formState={formState}
            items={getFolderSelections(userFoldersSection, orgFoldersSection)}
            title={"Select Folder"}
            placeholderText={"Select Folder"}
          />
        </FormContainer>
      </FolderManagementDialog>

      <div
        className="border group transition-all hover:border-limestone-300 rounded-md shadow p-4 pb-8 bg-white cursor-pointer"
        onClick={onClick}
      >
        <div className="flex mb-4">
          <div className="w-6 flex-shrink-0">
            <Paragraph displayType="loud" colorType="secondary">
              {uiGoalId ?? null}
            </Paragraph>
          </div>
          <div className="flex-grow">
            <Paragraph displayType="loud" colorType="primary">
              {goalObject?.goalName}
            </Paragraph>
            {goalObject?.goalType && <Small displayType="normal">{convertReadableString(goalObject?.goalType)}</Small>}
          </div>
          <div className="w-6 flex-shrink-0">
            <PopoverMenu
              openDirection="to-left"
              menuItems={[
                {
                  label: "Edit",
                  callback: () => onClick(),
                },

                {
                  label: "Save to Folder...",
                  callback: () => setShowSaveToFolderDialog(true),
                },

                {
                  label: deleteLoading ? "Deleting..." : "Delete",
                  callback: async () => {
                    setDeleteLoading(true);
                    try {
                      await removeLongTermGoalMutation.mutateAsync({
                        learner: learnerId,
                        longTermGoalId: goalObject.id,
                      });
                      notifySuccess("Successfully Deleted Long Term Goal");
                      await refetchGoals();
                      setDeleteLoading(false);
                    } catch (err) {
                      console.error("Failed To Delete Long Term Goal", err);
                      notifyError("Failed To Delete Long Term Goal");
                    }
                  },
                },
              ]}
            />
          </div>
        </div>
        <Small displayType="normal">{goalObject?.description}</Small>
      </div>
    </div>
  );
};
