import { getGenderValue, getPronoun } from "@utils/helpers";
import { formatDate, formatDateYYYY_MM_DD, formatTime_hh_mm, getDifferenceInYearsAndMonths } from "@utils/utils";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import { snakeCase } from "lodash";
import PizZip from "pizzip";
import React from "react";

import { Button } from "@fronterahealth/frontera-ui-components";

import {
  ApiOrganizationAbExportTemplateNameChoices,
  Maybe,
  ReportSectionsTypeEdge,
  ShortTermGoalType,
} from "@api/graphql/types-and-hooks";
import {
  organizeCatalightTreatmentPlanGoals,
  transformServicesTables,
  transformVinelandFormData,
  transformVinelandTableData,
} from "@components/PDFGenerator/catalightUtils";
import {
  ExportWithTemplateV2Props,
  FieldMap,
  FieldMapValue,
  markdownToDocxtemplater,
  reportTemplateToFontsMapping,
  reportTemplateToPathMapping,
  sortReportSectionsByRankOrder,
  sortSectionFieldsByRankOrder,
} from "@components/PDFGenerator/docxTemplateUtils";
import { useAdminData } from "@providers/AdminDataProvider";

const ExportWithTemplate: React.FC<ExportWithTemplateV2Props> = ({ reportData }) => {
  const { orgnizationDetailsQuery } = useAdminData();
  const { data, isLoading } = orgnizationDetailsQuery;

  const exportTemplate =
    data?.getUserOrgnizationDetails?.abExportTemplateName ||
    ApiOrganizationAbExportTemplateNameChoices.AssessmentReportDocxTemplateDocx;

  const font = reportTemplateToFontsMapping[exportTemplate];
  const isCatalightUser =
    data?.getUserOrgnizationDetails?.abExportTemplateName ===
    ApiOrganizationAbExportTemplateNameChoices.CatalightAssessmentReportDocxTemplateDocx;

  const getBaseFieldMap = (): FieldMap => {
    const clientName =
      `${reportData.reportClientDetails?.clientFirstName} ${reportData.reportClientDetails?.clientLastName}`.trim();
    const age = getDifferenceInYearsAndMonths(reportData.reportClientDetails?.birthDate, reportData?.createdDate);
    const address = [reportData.reportClientDetails?.address, reportData.reportClientDetails?.addressLine2]
      .filter(Boolean)
      .join("\n");

    return {
      dateOfReport: formatDate(reportData.signatureSectionDetail?.lastUpdatedDate),
      clientName,
      guardianName: `${reportData.reportClientDetails?.guardianFirstName} ${reportData.reportClientDetails?.guardianLastName}`,
      address,
      cityStateZip: `${reportData.reportClientDetails?.city}, ${reportData.reportClientDetails?.state} ${reportData.reportClientDetails?.zip}`,
      dateOfBirth: formatDate(reportData.reportClientDetails?.birthDate),
      age: age || "",
      currentDate: new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      priorities: reportData.reportTreatmentPlanPrioritizeFields?.socialDesires ?? "",
      reportCreatedDate: formatDateYYYY_MM_DD(reportData?.createdDate),
      reportCreatedTime: formatTime_hh_mm(reportData?.createdDate),
      reportCreatedByUserEmail: reportData.createdBy.email ?? "",
      gender: getGenderValue(reportData?.reportClientDetails?.gender),
      signatureName: reportData.signatureSectionDetail?.signedBy ?? "",
      signatureDate: formatDate(reportData.signatureSectionDetail?.signedAt ?? ""),
    };
  };

  const getCatalightFieldMap = (sortedReportFields: Maybe<ReportSectionsTypeEdge>[]): FieldMap => {
    const catalightClientDetails = sortSectionFieldsByRankOrder(sortedReportFields[0]);
    const catalightInsuranceDetails = sortSectionFieldsByRankOrder(sortedReportFields[1]);
    const catalightHistoryOfServices = sortSectionFieldsByRankOrder(sortedReportFields[2]);
    const catalightVineland = sortSectionFieldsByRankOrder(sortedReportFields[5]);
    const catalightSummaryOfAssessments = sortSectionFieldsByRankOrder(sortedReportFields[4]);
    const domainLevelStrengths = sortSectionFieldsByRankOrder(sortedReportFields[8]);
    return {
      ...getBaseFieldMap(),
      ...transformVinelandTableData(catalightVineland?.node?.sectionField.edges[2]?.node?.content ?? ""),
      ...transformVinelandFormData(catalightVineland?.node?.sectionField.edges[1]?.node?.content ?? ""),
      ...transformServicesTables(
        catalightHistoryOfServices?.node?.sectionField.edges[3]?.node?.content ?? "",
        "educationalServices",
      ),
      ...transformServicesTables(
        catalightHistoryOfServices?.node?.sectionField.edges[5]?.node?.content ?? "",
        "otherServices",
      ),
      dateOfReport: formatDate(
        catalightInsuranceDetails?.node?.sectionField.edges[0]?.node?.content ??
          reportData.signatureSectionDetail?.lastUpdatedDate,
      ),
      treatmentTeam: catalightInsuranceDetails?.node?.sectionField.edges[1]?.node?.content ?? "",
      mrn: catalightClientDetails?.node?.sectionField.edges[0]?.node?.content ?? "",
      pronoun: getPronoun(reportData?.reportClientDetails?.gender),
      familyConstellation: markdownToDocxtemplater(
        catalightHistoryOfServices?.node?.sectionField.edges[0]?.node?.content ?? "",
        font,
      ),
      significantBirthAndMedicalHistory: markdownToDocxtemplater(
        catalightHistoryOfServices?.node?.sectionField.edges[1]?.node?.content ?? "",
        font,
      ),
      educationalServicesTotalHours: markdownToDocxtemplater(
        catalightHistoryOfServices?.node?.sectionField.edges[2]?.node?.content ?? "",
        font,
      ),
      otherServicesTotalHours: markdownToDocxtemplater(
        catalightHistoryOfServices?.node?.sectionField.edges[4]?.node?.content ?? "",
        font,
      ),
      summaryOfBehavioralAndAdaptiveConcerns: markdownToDocxtemplater(
        catalightSummaryOfAssessments?.node?.sectionField.edges[1]?.node?.content ?? "",
        font,
      ),
      desiredOutcomes: markdownToDocxtemplater(
        sortedReportFields[3]?.node?.sectionField.edges[0]?.node?.content ?? "",
        font,
      ),
      fadePlan: markdownToDocxtemplater(sortedReportFields[7]?.node?.sectionField.edges[0]?.node?.content ?? "", font),
      summaryOfStrengths: markdownToDocxtemplater(
        catalightSummaryOfAssessments?.node?.sectionField.edges[0]?.node?.content ?? "",
        font,
      ),
      documentedReasonForReferral: markdownToDocxtemplater(
        catalightClientDetails?.node?.sectionField.edges[1]?.node?.content ?? "",
        font,
      ),
      preferenceAssessment: markdownToDocxtemplater(
        sortedReportFields[6]?.node?.sectionField.edges[0]?.node?.content ?? "",
        font,
      ),
      assessmentResults: markdownToDocxtemplater(
        catalightVineland?.node?.sectionField.edges[3]?.node?.content ?? "",
        font,
      ),
      receptiveCommunicationStrengths: markdownToDocxtemplater(
        domainLevelStrengths?.node?.sectionField.edges[0]?.node?.content ?? "",
        font,
      ),
      expressiveCommunicationStrengths: markdownToDocxtemplater(
        domainLevelStrengths?.node?.sectionField.edges[1]?.node?.content ?? "",
        font,
      ),
      pragmaticCommunicationStrengths: markdownToDocxtemplater(
        domainLevelStrengths?.node?.sectionField.edges[2]?.node?.content ?? "",
        font,
      ),
      selfHelpDailyLivingSkillsStrengths: markdownToDocxtemplater(
        domainLevelStrengths?.node?.sectionField.edges[3]?.node?.content ?? "",
        font,
      ),
      behaviorCurrentlyExhibits: markdownToDocxtemplater(
        domainLevelStrengths?.node?.sectionField.edges[4]?.node?.content ?? "",
        font,
      ),
      caregiverTrainingStrengths: markdownToDocxtemplater(
        domainLevelStrengths?.node?.sectionField.edges[5]?.node?.content ?? "",
        font,
      ),
    };
  };

  const generateDocument = async (
    templateData: Record<string, string | ShortTermGoalType[] | FieldMapValue | undefined>,
  ) => {
    try {
      const response = await fetch(reportTemplateToPathMapping[exportTemplate]);
      if (!response.ok) throw new Error("Network response was not ok");

      const buffer = await response.arrayBuffer();
      const zip = new PizZip(buffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.setData(templateData);
      doc.render();

      const blob = doc.getZip().generate({ type: "blob" });
      const clientName = templateData?.clientName || "unknown";
      saveAs(blob, `${snakeCase(clientName.toString())}_assessment_report.docx`);
    } catch (error) {
      console.error("Error generating document:", error);
    }
  };

  const handleExport = async () => {
    const sortedReportFields = sortReportSectionsByRankOrder(reportData?.reportSections?.edges);
    const baseFieldMap = getBaseFieldMap();

    if (isCatalightUser) {
      const organizedCatalightGoals = organizeCatalightTreatmentPlanGoals(reportData);
      const catalightFieldMap = getCatalightFieldMap(sortedReportFields);
      await generateDocument({
        ...baseFieldMap,
        ...organizedCatalightGoals,
        ...catalightFieldMap,
      });
    } else {
      await generateDocument(baseFieldMap);
    }
  };

  return <Button text="Export" disabled={isLoading} id="report-export-btn" appearance="link" onClick={handleExport} />;
};

export default ExportWithTemplate;
