import { format } from "date-fns";
import { useMemo } from "react";

import { Badge, BadgeProps, Table } from "@fronterahealth/frontera-ui-components";

import { useDeleteAssessmentMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { InitialAssessmentPageRoute } from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { useAdminData } from "@providers/AdminDataProvider";

export const STATUS_VALUES = [
  // Brand new report just started
  "Client Details Needed",

  // Clinical Files are needed
  "Clinical Files Needed",

  // client details filled out
  "At Upload Files",

  // Part 1 Kicked Off
  "Generating Report Page 1",

  // Part 1 has a section that has errored
  "Error on Report Page 1",

  // Part 1 has AI sections generated
  "At Report Page 1",

  // Report Has Prioritize generated
  // NOTE: This may be the same as "At Report Page 1"
  "At Prioritize",

  // LTG Kicked off
  "Generating Long Term Goals",

  // LTG has errored
  "Error Generating Long Term Goals",

  // LTG Have been completed
  "At Long Term Goals",

  // STG and Targets Kicked off
  "Generating Short Term Goals",
  "Generating Targets",

  // STG Have Been completed
  "At Short Term Goals",

  // STG has errored
  "Error Generating Short Term Goals",
  "Error Generating Targets",

  // Part 2 Kicked Off
  "Generating Report Page 2",

  // Part 2 has a section that has errored
  "Error on Report Page 2",

  // Part 2 has been generated
  "At Report Page 2",

  // Report has been submitted
  "Completed",
  "Unknown Status",
] as const;
export type StatusType = (typeof STATUS_VALUES)[number];

const BadgeSettingFromStatus: { [key in StatusType]: BadgeProps["appearance"] } = {
  "Generating Report Page 1": "ai",
  "Generating Report Page 2": "ai",
  "Generating Long Term Goals": "ai",
  "Generating Short Term Goals": "ai",
  "Generating Targets": "ai",
  "At Report Page 1": "neutral",
  "At Report Page 2": "neutral",
  "At Prioritize": "neutral",
  "At Long Term Goals": "neutral",
  "At Short Term Goals": "neutral",
  Completed: "success",
  "Error on Report Page 1": "error",
  "Error on Report Page 2": "error",
  "Error Generating Long Term Goals": "error",
  "Error Generating Short Term Goals": "error",
  "Error Generating Targets": "error",
  "At Upload Files": "neutral",
  "Client Details Needed": "warn",
  "Clinical Files Needed": "warn",
  "Unknown Status": "error",
};

export interface AssessmentReport {
  id: string;
  clientName: string;
  dateCreated: Date;
  lastUpdated: Date;
  status: StatusType;
  redirectRoute: InitialAssessmentPageRoute;
  learnerId?: string;
}

interface AssessmentReportsTableProps {
  reports: (AssessmentReport | null)[];
  onRowClick: (report: AssessmentReport) => void;
  loading?: boolean;
  refetch: () => Promise<unknown>;
}

export const AssessmentReportsTable: React.FC<AssessmentReportsTableProps> = ({
  reports,
  onRowClick,
  loading,
  refetch,
}) => {
  const deleteAssessmentMutation = useDeleteAssessmentMutation({});

  const { roles } = useAdminData();

  const actions = useMemo(() => {
    const isInternalUser = roles.includes("frontera-internal");

    return [
      {
        label: "View",
        callback: (rowData: AssessmentReport) => onRowClick(rowData),
      },
      ...(isInternalUser
        ? [
            {
              label: "Delete",
              textColorClassName: "text-red-700",
              callback: async (rowData: { id: string }) => {
                try {
                  await deleteAssessmentMutation.mutateAsync({ assessment: rowData.id });
                  await refetch();
                  notifySuccess("Report successfully deleted");
                } catch (err) {
                  console.error("Failed to delete report", err);
                  notifyError("Unable to delete report");
                }
              },
            },
          ]
        : []),
    ];
  }, [roles]);

  return (
    <div className="w-full bg-white mt-8 rounded-lg">
      <Table
        onRowClick={onRowClick}
        loading={loading}
        defaultItemsPerPage={20}
        showSearch
        inputClassName="w-96"
        showPagination
        itemsPerPageOptions={[5, 10, 20, 50]}
        actions={actions}
        data={(reports.filter((report) => report !== null) as AssessmentReport[]).sort((reportA, reportB) =>
          reportB.lastUpdated > reportA.lastUpdated ? 1 : -1,
        )}
        columns={[
          { dataIndex: "clientName", key: "clientName", title: "Client Name" },
          {
            dataIndex: "dateCreated",
            key: "dateCreated",
            title: "Date Created",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "lastUpdated",
            key: "lastUpdated",
            title: "Last Updated",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "status",
            key: "status",
            title: "Status",
            render: (value) => {
              return <Badge appearance={BadgeSettingFromStatus[value as StatusType]} text={value as StatusType} />;
            },
          },
        ]}
      />
    </div>
  );
};
