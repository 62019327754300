import { ExistingFileInterface, FileUploadAndParse } from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { FileRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/hooks";

interface ClinicalNotesProps {
  existingClinicalNotes: ExistingFileInterface[];
}
export const ClinicalNotes: React.FC<ClinicalNotesProps> = ({ existingClinicalNotes }) => {
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const fileKind: FileTypeEnum = FileTypeEnum.ClinicalNotes;
  return (
    <FileRow title="Clinical Notes" subtitle="Other supporting clinical documents" isLast>
      <FileUploadAndParse
        key={JSON.stringify(existingClinicalNotes)}
        fileKind={fileKind}
        title={"Documents"}
        supportedFileTypes={["pdf", "png", "jpeg", "jpg", "doc", "docx"]}
        maxSize={"1GB"}
        existingFiles={existingClinicalNotes}
        S3BucketUrlRetriever={S3BucketUrlRetriever}
        S3BucketUploadCallback={S3BucketUploadCallback}
        reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
        fileParseStatusRetriever={fileParseStatusRetriever}
        removeFileCallback={removeFileCallback}
        hintText="Optional"
      />
    </FileRow>
  );
};
