import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { PlusIcon } from "@heroicons/react/24/outline";
import { QueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";

import {
  AI_GENERATED,
  Button,
  Folder,
  FolderExplorer,
  GoalBankPanel,
  Small,
  UserType,
} from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedShortTermGoalsType,
  AiSuggestedShortTermGoalsTypeEdge,
  ShortTermTimelineEstimationEnums,
  useAddSuggestedShortGoalsMutation,
  useCreateUpdateShortTermGoalMutation,
  useGoalBankShortTermGoalsQuery,
} from "@api/graphql/types-and-hooks";
import { AddShortTermGoalsBankCard } from "@components/GoalsBank/AddShortTermGoalsBank/AddShortTermGoalsBankCard";
import { GoalBankShortTermGoalsSaveToFolder } from "@components/GoalsBank/SavedGoals/ShortTermGoals/GoalBankShortTermGoalsSaveToFolder";
import { DeleteFolderDialog } from "@components/GoalsBank/dialogs/DeleteFolderDialog";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolder,
  getAiGeneratedFolderSection,
  getOrgFolderSection,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { convertDBString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

interface AddShortTermGoalBankProps {
  onAdd: () => void;
  assessmentId: string;
  aiGoals: AiSuggestedShortTermGoalsTypeEdge[];
  longTermGoalId: string;
  learnerId: string;
  longTermGoalBankId?: string;
  useLongTermGoals?: boolean;
}

export const AddShortTermGoalsBank: React.FC<AddShortTermGoalBankProps> = ({
  aiGoals,
  assessmentId,
  onAdd,
  learnerId,
  longTermGoalId,
  longTermGoalBankId,
  useLongTermGoals,
}) => {
  const [queryClient] = useState(() => new QueryClient());
  const { user } = useAuth0();
  const userType: UserType = !!user?.org_id ? "organization" : "selfServe";
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useGoalBankFolders();
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const [isSaveToFolderDialogOpen, setSaveToFolderDialogOpen] = useState<boolean>(false);
  const [selectedGoalIds, setSelectedGoalIds] = useState<string[]>([]);
  const [selectedAiGoalIds, setSelectedAiGoalIds] = useState<string[]>([]);
  const addSuggestedShortGoalsMutation = useAddSuggestedShortGoalsMutation({});
  const createUpdateShortTermGoalMutation = useCreateUpdateShortTermGoalMutation({});
  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);

  const goalBankShortTermGoals = useGoalBankShortTermGoalsQuery(
    { folderId: selectedFolderId, longTermGoalBankId },
    {
      queryKey: ["short-term-goals-" + selectedFolderId],
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      refetchInterval: 100000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );
  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const aiSuggestedFoldersSection = getAiGeneratedFolderSection();

  const goalsToAdd = (): ReactNode[] => {
    if (selectedFolderId === AI_GENERATED) {
      return aiGoals.length
        ? aiGoals.map((edge) => {
            const node = edge?.node as AiSuggestedShortTermGoalsType;
            return node ? (
              <AddShortTermGoalsBankCard
                id={node.id}
                goalName={node.goalName}
                description={useLongTermGoals ? node.description : node.masteryCriteria}
                selected={selectedAiGoalIds.includes(node.id)}
                onClick={() => {
                  if (selectedAiGoalIds.includes(node.id)) {
                    setSelectedAiGoalIds([...selectedAiGoalIds.filter((id) => id != node?.id)]);
                    return;
                  }
                  setSelectedAiGoalIds([...selectedAiGoalIds, node.id]);
                }}
              />
            ) : (
              <></>
            );
          })
        : [];
    }
    return (
      goalBankShortTermGoals.data?.getGoalBankShortTermGoals?.edges.map((edge) => {
        const node = edge?.node;
        return node ? (
          <AddShortTermGoalsBankCard
            id={node.id}
            goalName={node.goalName}
            description={useLongTermGoals ? node.description : node.masteryCriteria}
            selected={selectedGoalIds.includes(node.id)}
            onClick={() => {
              if (selectedGoalIds.includes(node.id)) {
                setSelectedGoalIds([...selectedGoalIds.filter((id) => id != node?.id)]);
                return;
              }
              setSelectedGoalIds([...selectedGoalIds, node.id]);
            }}
          />
        ) : (
          <></>
        );
      }) || []
    );
  };

  const addGoals = async () => {
    if (selectedGoalIds || selectedAiGoalIds) {
      try {
        if (selectedAiGoalIds) {
          addSuggestedShortGoalsMutation.mutate(
            {
              assessmentId: assessmentId || "<missing-assessment-id>",
              aiSuggestedShortGoalIds: selectedAiGoalIds,
              longTermGoalId: longTermGoalId ?? "<missing-ltg-id>",
            },
            {
              onSuccess: async () => {
                await onAdd();
              },
            },
          );
        }
        const goalsToAdd = goalBankShortTermGoals.data?.getGoalBankShortTermGoals?.edges.filter((edge) =>
          selectedGoalIds.includes(edge?.node?.id ?? "<missing-long-term-goal-id>"),
        );
        await goalsToAdd?.forEach(async (goal) => {
          const node = goal?.node;
          await createUpdateShortTermGoalMutation.mutateAsync(
            {
              shortTermData: {
                goalName: node?.goalName ?? "",
                description: node?.description,
                programGoal: node?.programGoal,
                masteryCriteria: node?.masteryCriteria,
                timelineEstimationType: node?.timelineEstimationType
                  ? (convertDBString(node?.timelineEstimationType) as ShortTermTimelineEstimationEnums)
                  : null,
                timelineEstimationValue: node?.timelineEstimationValue,
                assessmentId,
                longTermGoalId,
                shortTermGoalBankId: node?.id,
                hasMedicalNecessity: node?.hasMedicalNecessity,
                medicalNecessityCriteria: node?.medicalNecessityCriteria,
              },
              learnerId: learnerId ? learnerId : "<missing-learner-id>",
            },
            {
              onSuccess: async () => {
                await onAdd();
              },
            },
          );
        });
      } catch (err) {
        notifyError("Failed To Add Goal(s)");
        console.error("Error adding AI goals", err);
      } finally {
        setSelectedAiGoalIds([]);
        setSelectedGoalIds([]);
        notifySuccess("Successfully Added Goal(s)");
      }
    }
  };

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        userType={userType}
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id, newValue) => editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)}
        onCreateFolder={async (folderName, folderType) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title={"Add Short Term Goals"}
        folderSections={
          orgName
            ? [aiSuggestedFoldersSection, userFoldersSection, orgFoldersSection]
            : [aiSuggestedFoldersSection, userFoldersSection]
        }
        modalButton={
          <Button
            text={
              <Small className={"flex items-center px-2 text-secondary"}>
                <PlusIcon className="mr-2 h-4 w-4" />
                Suggested Goal
              </Small>
            }
            id="suggested-goal-btn"
            appearance="secondary"
            buttonAction="destructive"
            className="max-w-fit whitespace-nowrap hover:bg-transparent ring-globemallow-600 hover:ring-globemallow-500 active:ring-globemallow-900"
          />
        }
        footer={{
          addButtonText: "Add Goal(s)",
          onAdd: addGoals,
          onSaveToFolder: () => setSaveToFolderDialogOpen(true),
          displaySaveToFolderButton: selectedFolderId === AI_GENERATED,
        }}
        panel={
          <GoalBankPanel
            userType={userType}
            isLoading={goalBankShortTermGoals.isLoading || goalBankShortTermGoals.isFetching}
            key={goalsToAdd().length}
            noFolders={false}
            goalsToDisplay={goalBankShortTermGoals.isLoading || goalBankShortTermGoals.isFetching ? [] : goalsToAdd()}
            goalsTabs={[]}
            onCreateFolder={() => {}}
            noGoalsEmptyState={{
              header: selectedFolderId == AI_GENERATED ? "No AI-Generated Items Available" : "Nothing to Show",
              subHeader:
                selectedFolderId == AI_GENERATED
                  ? "You may add saved items from the folders on the left, or add custom items on the previous screen."
                  : "Save items to this folder for later use.",
            }}
          ></GoalBankPanel>
        }
      >
        <GoalBankShortTermGoalsSaveToFolder
          open={isSaveToFolderDialogOpen}
          shortTermGoals={[]}
          aiShortTermGoals={
            aiGoals
              .filter((aiGoal) => !!aiGoal && selectedAiGoalIds.includes(aiGoal.node?.id ?? ""))
              .map((edge) => edge.node) as AiSuggestedShortTermGoalsType[]
          }
          onCloseClick={() => {
            setSaveToFolderDialogOpen(false);
            setSelectedAiGoalIds([]);
          }}
          refetch={async () => await queryClient.refetchQueries()}
        />

        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};
