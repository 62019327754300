import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ApiReportSectionsAiGeneratedStatusChoices, ReportPageLocationEnums } from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import { GoalsSection, longTermGoalMapping } from "@components/GoalsSections/GoalsSections.tsx";
import { useGoals } from "@hooks/useGoals.ts";
import {
  InitialAssessmentPageRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { StickyNav } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { SectionWidgetWrapper } from "@pages/AssessmentReportV2Details/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";

const pageLocation: FilterSectionSubRoute = "report-part-2";

interface ReportSummaryPart2SectionsProps {
  isReviewPage?: boolean;
  showErrorWarning?: boolean;
}

export const ReportSummaryPart2: React.FC<ReportSummaryPart2SectionsProps> = ({
  isReviewPage = false,
  showErrorWarning = true,
}) => {
  // Remove long term goal sections from the report since they are not used
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation).filter((section) => {
    return !Object.keys(longTermGoalMapping).includes(section.title);
  });
  const { assessmentId: reportId } = useParams();
  const { goalSections } = useGoals({ reportId, useLongTermGoals: false });
  const navSections = [
    ...filteredSections.map((section) => ({ title: section.title, aiGeneratedStatus: section.aiGeneratedStatus })),
    ...Object.keys(goalSections || {}).map((sectionTitle) => ({
      title: sectionTitle,
      aiGeneratedStatus: ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
    })),
  ];
  const { pathname } = useLocation();
  const go = useNavigate();
  const { getNextRoute } = useOrderedAssessmentReportSubRoutes();
  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);
  const nextPage = getNextRoute(currentRoute) as FilterSectionSubRoute;
  const nextPageFilteredSections = useGetFilteredPageSectionsByLocation(nextPage);
  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);
  const nextPageAlreadyGenerated = nextPageFilteredSections.some(
    (section) =>
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending ||
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
  );

  const handlePrimaryButtonAction = async () => {
    if (!nextPageAlreadyGenerated) setPromptDialogOpen(true);
    else go(`../${nextPage}`);
  };

  const sectionsDom = () => {
    return filteredSections.map((section) => (
      <div key={section.id}>
        <SectionWidgetWrapper
          section={section}
          permissions={{
            canDelete: !isReviewPage,
            canEdit: !isReviewPage,
          }}
          supportedContentTypes={["Text", "Table", "Image"]}
          showErrorWarning={showErrorWarning}
        />
      </div>
    ));
  };

  if (isReviewPage) {
    return (
      <>
        {sectionsDom()}
        <GoalsSection isDisplayModeForReview={false} useLongTermGoals={false} />
      </>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.ReportPart_2}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`col-span-3 flex w-full flex-col gap-y-4`}>
          {sectionsDom()}
          <GoalsSection isDisplayModeForReview={false} useLongTermGoals={false} />
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav sections={navSections} />
        </div>
      </div>
      <FooterButtonRow primaryButtonTitle="Next" primaryButtonAction={handlePrimaryButtonAction} skipNavigate />
    </div>
  );
};
