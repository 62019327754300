import { format } from "date-fns";

import { Table } from "@fronterahealth/frontera-ui-components";

export interface FronteraProvider {
  id: string;
  name: string;
  created: Date;
  assignedClientsCount: number;
  email: string;
}

interface ProviderTableProps {
  providers: (FronteraProvider | null)[];
  onRowClick: (provider: FronteraProvider) => void;
  loading?: boolean;
}

export const ProviderTable: React.FC<ProviderTableProps> = ({ providers, onRowClick, loading }) => {
  return (
    <div className="mt-8 bg-white flow-root w-full">
      <Table
        onRowClick={onRowClick}
        defaultItemsPerPage={10}
        showPagination
        loading={loading}
        showSearch
        inputClassName="w-96"
        itemsPerPageOptions={[5, 10, 20, 50]}
        actions={[
          {
            label: "View",
            callback: onRowClick,
          },
          {
            label: "Delete",
            textColorClassName: "text-red-700",
            callback: () => alert("implement me"),
          },
        ]}
        data={(providers.filter((provider) => provider !== null) as FronteraProvider[]).sort((providerA, providerB) =>
          providerB.created > providerA.created ? 1 : -1,
        )}
        columns={[
          { dataIndex: "name", key: "name", title: "Name" },
          { dataIndex: "email", key: "email", title: "Email" },
          { dataIndex: "assignedClientsCount", key: "assignedClientsCount", title: "Assigned Clients" },
          {
            dataIndex: "created",
            key: "created",
            title: "Created",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
        ]}
      />
    </div>
  );
};
