import { GoalBankCard } from "@fronterahealth/frontera-ui-components";

interface AddShortTermGoalsBankCardProps {
  id: string;
  goalName: string;
  description?: string | undefined | null;
  selected: boolean;
  subtitle?: string | undefined | null;
  onClick: () => void;
}

export const AddShortTermGoalsBankCard: React.FC<AddShortTermGoalsBankCardProps> = ({
  id,
  goalName,
  description,
  selected,
  subtitle,
  onClick,
}) => {
  return (
    <GoalBankCard
      key={id}
      id={id}
      title={goalName}
      subtitle={subtitle ?? ""}
      body={description ?? ""}
      selected={selected}
      onClick={onClick}
    />
  );
};
