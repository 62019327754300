import { useAuth0 } from "@auth0/auth0-react";
import { KeyIcon } from "@heroicons/react/24/outline";
import { APP_ENV } from "@utils/utils";
import React, { useState } from "react";

import { Button, Heading, Small, Tooltip } from "@fronterahealth/frontera-ui-components";

import { notifySuccess } from "@components/notifications/notifications";

interface AccessTokenRetrieverProps {
  iconOnly?: boolean;
}
export const AccessTokenRetriever: React.FC<AccessTokenRetrieverProps> = ({ iconOnly }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [token, setToken] = useState("");

  if (APP_ENV === "prod") return null;

  if (iconOnly) {
    return (
      <Tooltip showInfoIcon={false} text="Click for JWT" placement="right">
        <div
          onClick={async () => {
            setLoading(true);
            const token = await getAccessTokenSilently();
            setToken(token);
            window.navigator.clipboard.writeText(token);
            setShowConfirmation(true);
            notifySuccess("JWT Successfully copied");
            setTimeout(() => {
              setShowConfirmation(false);
              setLoading(false);
            }, 500);
          }}
          className={`hover:bg-blurple-100 cursor-pointer flex flex-col px-4 py-2  border-blurple-200 transition-all rounded-md w-full`}
        >
          <KeyIcon className={`${loading ? "animate-pulse" : ""} text-blurple-600 `} />
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="flex flex-col px-4 py-2 bg-blurple-100 border border-blurple-200 rounded-md w-full">
      <Heading className="text-sm underline" type="h4">
        Get Access Token (JWT)
      </Heading>
      <Small className="my-1 text-xs italic">(This too is behind a feature flag)</Small>

      <div className="flex flex-col items-start">
        <Button
          text={loading ? "Copying..." : "Copy JWT to Clipboard"}
          appearance="secondary"
          className="hover:bg-blurple-300"
          disabled={loading}
          buttonAction="ai"
          onClick={async () => {
            setLoading(true);
            const token = await getAccessTokenSilently();
            setToken(token);
            window.navigator.clipboard.writeText(token);
            setShowConfirmation(true);
            setTimeout(() => {
              setShowConfirmation(false);
            }, 2000);
            setLoading(false);
          }}
        />
        <Small
          className={`my-1 text-xs  ml-4 italic text-blurple-600 transition-all ${showConfirmation ? "opacity-100" : "opacity-0"}`}
        >
          ...<code className="text-gray-500">{token.slice(-8)}</code> has been copied!
        </Small>
      </div>
    </div>
  );
};
