import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReportPageLocationEnums } from "@api/graphql/types-and-hooks";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import {
  InitialAssessmentPageRoute,
  useOrderedAssessmentReportSubRoutes,
} from "@pages/AssessmentReportV2Details/AssessmentReportDetails";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { StickyNav } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { SectionWidgetWrapper } from "@pages/AssessmentReportV2Details/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/AssessmentReportV2Details/Components/helper";
import { isGeneratingSection } from "@pages/EvaluationDetails/Components/helper";

const pageLocation: FilterSectionSubRoute = "report-part-1";

export const ReportSummaryPart1: React.FC = () => {
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const { pathname } = useLocation();
  const go = useNavigate();
  const { getNextRoute } = useOrderedAssessmentReportSubRoutes();
  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as InitialAssessmentPageRoute) ||
    ("upload-files" as InitialAssessmentPageRoute);
  const nextPage = getNextRoute(currentRoute) as FilterSectionSubRoute;
  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);

  const isSectionGenerating = isGeneratingSection(filteredSections);
  // TODO: BACKPORT: Once this is in the DB model, use this
  // const { assessmentQuery } = useAssessmentBuilderData();
  // const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;
  // const isPrioritizeGenerating = assessmentReport?.reportTreatmentPlan?.aiPrioritizeStatus
  const isPrioritizeGenerating = false;

  const handlePrimaryButtonAction = async () => {
    go(`../${nextPage}`);
  };

  return (
    <div className="flex w-full flex-col">
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.ReportPart_2}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`col-span-3 flex w-full flex-col gap-y-4`}>
          {filteredSections.map((section) => (
            <div key={section.id}>
              <SectionWidgetWrapper
                section={section}
                permissions={{
                  canDelete: true,
                  canEdit: true,
                }}
                supportedContentTypes={["Text", "Table", "Image"]}
              />
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav sections={filteredSections} />
        </div>
      </div>
      <FooterButtonRow
        primaryButtonDisabled={isSectionGenerating}
        primaryButtonDisabledTooltipReason="All sections on the page must finish generating before continuing"
        primaryButtonTitle="Next"
        primaryButtonAction={handlePrimaryButtonAction || isPrioritizeGenerating}
        skipNavigate
      />
    </div>
  );
};
