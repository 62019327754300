import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Heading, Small } from "@fronterahealth/frontera-ui-components";

import {
  ProductPriceListType,
  useCreateCheckoutSessionMutation,
  useQueryProductPriceQuery,
} from "@api/graphql/types-and-hooks";

const priceQuantityOptionsToDisplay = [
  {
    price: 450,
    quantity: 10,
  },
  {
    price: 224,
    quantity: 5,
  },
  {
    price: 50,
    quantity: 1,
  },
];

export const Products: React.FC = () => {
  const [selected, setSelected] = useState<string>("");
  const [products, setProducts] = useState<ProductPriceListType[]>([{}, {}, {}]);
  const createCheckoutSession = useCreateCheckoutSessionMutation();
  const go = useNavigate();
  const flags = useFlags();
  const route = flags["assessment-builder-backport"] ? "/assessment-reports-v2" : "/assessment-reports";

  const { user } = useAuth0();

  useEffect(() => {
    // Check to ensure user should be able to access this page and redirect if not
    // TODO: This permission logic should be moved to the router level if possible
    if (user) {
      try {
        const rolesKey = `${config.auth0Audience}/selfServeUser`;
        const isSelfServeUser = user?.[rolesKey];
        if (!isSelfServeUser)
          go(route, {
            replace: true,
          });
      } catch (err) {
        console.error("Error getting roles ", err);
        go(route, { replace: true });
      }
    }
  }, [user]);

  const { data, isFetching } = useQueryProductPriceQuery();

  useEffect(() => {
    if (isFetching === false && data) {
      const productPrices = data.getProductPriceList;
      // Stripe orders the prices by latest creation date, so we sort them by quantity here
      productPrices?.sort((a, b) => (a?.quantity ?? 0) - (b?.quantity ?? 0));
      setProducts(productPrices as ProductPriceListType[]);
      setSelected(productPrices?.[0]?.priceId ?? "");
    }
  }, [data, isFetching]);

  const handlePriceSelection = useCallback((id: string) => {
    setSelected(id);
  }, []);

  const handlePriceSubmission = useCallback(async () => {
    try {
      if (!selected) {
        throw new Error("Please select a bundle");
      }

      const res = await createCheckoutSession.mutateAsync({
        priceId: selected,
        redirectOrigin: window.location.origin,
      });

      const { clientSecret } = res.createCheckoutSession as {
        clientSecret: string;
      };

      go(`/checkout`, {
        state: { clientSecret },
      });
    } catch (error) {
      console.error(error);
    }
  }, [selected]);

  return (
    <div className="h-svh">
      <Heading type="h1" className="">
        Buy Credits
      </Heading>
      <div className="flex items-start justify-center pt-8">
        <div className="rounded-3xl p-8 pt-6 w-2/3 bg-white border border-limestone-100 border-solid">
          <p className="text-semibold">How many credits would you like to purchase?</p>
          <div className="grid grid-cols-3 gap-2 mt-8 mb-12">
            {products.length &&
              products
                .filter((product) =>
                  priceQuantityOptionsToDisplay.find(
                    (p) => p.price === product.price && p.quantity === product.quantity,
                  ),
                )
                .map(({ price, priceId, quantity }) => {
                  if (!priceId || !price || !quantity) return <div key={priceId}>loading</div>;

                  return (
                    <PriceBox
                      key={priceId}
                      selected={selected}
                      id={priceId}
                      price={price}
                      credits={quantity}
                      handlePriceSelection={handlePriceSelection}
                    />
                  );
                })}
          </div>
          <div className="flex justify-end">
            <Button
              disabled={!selected}
              className="flex"
              onClick={() => handlePriceSubmission()}
              text="Continue to Checkout"
              appearance="primary"
            />
          </div>
        </div>
        <div className="w-1/3 ml-8">
          <div>
            <div className="mb-4">
              <p className="text-semibold">FAQ</p>
            </div>
            <div className="mb-4">
              <Small colorType="primary" displayType="loud">
                What is a credit?
              </Small>
              <Small>Credits are used to create Assessment Reports.</Small>
            </div>
            <div className="mt-4">
              <Small colorType="primary" displayType="loud">
                How many credits do I need for one assessment report?
              </Small>
              <Small>One (1) credit is required to create one (1) Assessment Report.</Small>
            </div>
            <div className="mt-4">
              <Small colorType="primary" displayType="loud">
                How do I purchase credits?
              </Small>
              <Small>
                You can purchase credits by selecting the desired amount and clicking "Continue to Checkout." Follow the
                prompts to complete your purchase.
              </Small>
            </div>
            <div className="mt-4">
              <Small colorType="primary" displayType="loud">
                How can I check my remaining credits?
              </Small>
              <Small>
                Your remaining credits are displayed at the bottom of the sidebar, as well as on your account page.
              </Small>
            </div>
            <div className="mt-4">
              <Small colorType="primary" displayType="loud">
                How much do credits cost?
              </Small>
              {products
                .filter((product) =>
                  priceQuantityOptionsToDisplay.find(
                    (p) => p.price === product.price && p.quantity === product.quantity,
                  ),
                )
                .map(({ price, quantity }) => (
                  <div key={price} className="flex justify-between border-b border-limestone-100 py-1">
                    <div className="w-fit">
                      <Small>
                        {quantity ?? 0} credit{quantity && quantity > 1 && "s"}
                      </Small>
                    </div>
                    <div>
                      <Small>${price ?? 0 / (quantity ?? 1)}</Small>
                    </div>
                  </div>
                ))}
              <div className="flex justify-between pt-1">
                <div>
                  <Small>11+ credits</Small>
                </div>
                <div>
                  <Small>Contact us for pricing</Small>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Small colorType="primary" displayType="loud">
              Do credits expire?
            </Small>
            <Small>No your credits do not expire</Small>
          </div>
          <div className="mt-4">
            <Small colorType="primary" displayType="loud">
              Can I get a refund for unused credits?
            </Small>
            <Small>
              Unfortunately, credits are non-refundable. Please ensure you purchase the correct amount needed.
            </Small>
          </div>
        </div>
      </div>
    </div>
  );
};

type PriceBoxProps = {
  price: number;
  credits: number;
  id: string;
  selected: string;
  handlePriceSelection: (id: string) => void;
};

const PriceBox: React.FC<PriceBoxProps> = ({ price, credits, handlePriceSelection, selected, id }) => {
  const isSelected = selected === id;
  return (
    <div>
      <div
        id={id}
        className={`cursor-pointer rounded-lg p-4 h-full grid-flow-row grid-rows-[20px_1fr_1fr] border-solid border ${isSelected ? "border-green-600" : "border-limestone-100"}`}
        onClick={() => handlePriceSelection(id)}
      >
        <div className="flex justify-between">
          <p className="text-semibold">
            {credits} Credit{credits > 1 && "s"}
          </p>
          {isSelected && <CheckCircleIcon className="w-6 h-6" color="green" />}
        </div>
        <div>
          <Small>Price per credit: ${(price / credits).toFixed(2)}</Small>
        </div>

        <div className="mt-6">
          <p className="text-semibold">${price}</p>
        </div>
      </div>
    </div>
  );
};
