import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { QueryClient } from "@tanstack/react-query";
import { getHierarchicalIdentifier } from "@utils/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Badge, Button, Divider, Heading, Paragraph, Small, Tooltip } from "@fronterahealth/frontera-ui-components";

import {
  AiPredictionOfEnum,
  AiSuggestedShortTermGoalsTypeEdge,
  ApiOrganizationAbExportTemplateNameChoices,
  ApiReportTreatmentPlanAiStgPredictionStatusChoices,
  ApiReportTreatmentPlanAiTargetPredictionStatusChoices,
  LongTermGoalType,
  LongTermGoalTypeEdge,
  ReportPageLocationEnums,
  ReportTypeEnums,
  ShortTermGoalType,
  TargetType,
  TargetTypeEdge,
  useAddReportTreatmentPlanSuggestedShortTermGoalsMutation,
  useGoalCategoryConfigQuery,
  useReportLongTermShortTermGoalsQuery,
} from "@api/graphql/types-and-hooks";
import { AiFeedbackDialog } from "@components/AiFeedbackDialog/AiFeedbackDialog";
import { ConfirmGenerationDialog } from "@components/ConfirmGenerationDialog/ConfirmGenerationDialogV2";
import { AddTargetsBank } from "@components/GoalsBank/AddTargetsBank/AddTargetsBankV2";
import { GoalBankShortTermGoalsSaveToFolder } from "@components/GoalsBank/SavedGoals/ShortTermGoals/GoalBankShortTermGoalsSaveToFolder";
import { GoalBankTargetsSaveToFolder } from "@components/GoalsBank/SavedGoals/Targets/GoalBankTargetsSaveToFolder";
import { NoDataCard } from "@components/NoDataCard/NoDataCard";
import { ShortTermGoalCard } from "@components/ShortTermGoalCard/ShortTermGoalCardV2";
import { ShortTermGoalPanel } from "@components/ShortTermGoalPanel/ShortTermGoalPanelV2";
import { ShortTermGoalParentCard } from "@components/ShortTermGoalParentCard/ShortTermGoalParentCardV2";
import { TargetCard } from "@components/TargetCard/TargetCardV2";
import { TargetPanel } from "@components/TargetPanel/TargetPanelV2";
import { WarningCard } from "@components/WarningCard/WarningCard";
import { convertReadableString } from "@components/forms/utils";
import { notifyError, notifyWarn } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/FooterButtonRow";
import { GenerationErrorPage } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/GenerationErrorPage";
import { ReportNotReadyYet } from "@pages/AssessmentReportV2Details/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAdminData } from "@providers/AdminDataProvider";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderV2Provider";

export const ShortTermGoals: React.FC = () => {
  const { assessmentId } = useParams();
  const go = useNavigate();
  const { user } = useAuth0();
  const [queryClient] = useState(() => new QueryClient());
  const { assessmentQuery } = useAssessmentBuilderData();
  const assessmentReport = assessmentQuery?.data?.getReports?.edges?.[0]?.node;
  const learnerId = assessmentReport?.learner?.id || null;
  const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
  const [warnUser, setWarnUser] = useState<boolean>(false);
  const [isTargetPanelOpen, setTargetPanelOpen] = useState<boolean>(false);
  const [selectedLTG, setSelectedLTG] = useState<LongTermGoalType>();
  const [selectedSTG, setSelectedSTG] = useState<ShortTermGoalType | undefined>();
  const [selectedTarget, setSelectedTarget] = useState<TargetType | undefined>();
  const [promptDialogOpen, setPromptDialogOpen] = useState<boolean>(false);
  const [aiFeedbackVote, setAiFeedbackVote] = useState<boolean>(false);
  const [aiFeedbackDialogOpen, setAiFeedbackDialogOpen] = useState<boolean>(false);
  const [selectedAiGoalId, setSelectedAiGoalId] = useState<string | null>(null);
  const addSuggestedShortGoalsMutation = useAddReportTreatmentPlanSuggestedShortTermGoalsMutation({});
  const [showShortTermGoalsSaveToFolderDialog, setShowShortTermGoalsSaveToFolderDialog] = useState<boolean>(false);
  const [showTargetsSaveToFolderDialog, setShowTargetsSaveToFolderDialog] = useState<boolean>(false);
  const { orgnizationDetailsQuery } = useAdminData();

  const isCatalightUser =
    orgnizationDetailsQuery.data?.getUserOrgnizationDetails?.abExportTemplateName ===
      ApiOrganizationAbExportTemplateNameChoices.CatalightAssessmentReportDocxTemplateDocx || false;

  const reportTreatmentPlan = assessmentQuery?.data?.getReports?.edges?.map((edge) => edge?.node)?.[0]
    ?.reportTreatmentPlan;

  const stgStatus = reportTreatmentPlan?.aiStgPredictionStatus;
  const targetStatus = reportTreatmentPlan?.aiTargetPredictionStatus;

  const reportStillGenerating =
    stgStatus !== ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPredicted ||
    targetStatus !== ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPredicted;

  const reportDone =
    stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices.GoalPredictionPredicted &&
    targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices.GoalPredictionPredicted;

  const reportNotYetKickedOff =
    stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices.Pending &&
    targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices.Pending;

  const reportGenerationFailed = stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices.Failure;
  const reportGenerationFailureReason = reportTreatmentPlan?.failureReason;

  const reportLongTermShortTermGoalsQuery = useReportLongTermShortTermGoalsQuery(
    { id: assessmentId ? assessmentId : "<missing-assessmentId>", reportType: ReportTypeEnums.InitialAssessment },
    {
      queryKey: ["get-long-term-short-term-goals-query", learnerId, assessmentId, reportDone],
      enabled: !!assessmentId && !!learnerId && reportDone,
      retry: false,
      refetchOnMount: reportStillGenerating ? false : "always",
      refetchOnWindowFocus: false,
    },
  );

  const { data, error, refetch, isSuccess } = reportLongTermShortTermGoalsQuery;

  const goalCategoryConfig = useGoalCategoryConfigQuery({ organizationId: user?.org_id }, { enabled: !!user?.org_id });
  const useLongTermGoals = reportTreatmentPlan?.useLongTermGoals;
  const goalCategoryName = useLongTermGoals
    ? "Long Term Goal"
    : goalCategoryConfig.data?.goalCategoryConfig?.edges.at(0)?.node?.categoryConfigType;

  useEffect(() => {
    if (error) {
      console.error("Error while fetching LTG list", error);
      notifyError(`Error fetching ${goalCategoryName}`);
    }
  }, [error]);

  const goalList = data?.getReports?.edges?.[0]?.node?.reportTreatmentPlan?.longtermgoalSet?.edges || [];

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Short Term Goals" />;
  }

  if (reportGenerationFailed) {
    return <GenerationErrorPage page={AiPredictionOfEnum.ShortTermGoal} errorMessage={reportGenerationFailureReason} />;
  }

  if (reportStillGenerating) {
    return (
      <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
        <SparklesIcon className="h-12 w-12 text-limestone-200 animate-pulse" />
        <Heading type="h4">Good news! Your Treatment Plan is underway</Heading>
        <Paragraph displayType="normal" colorType="secondary" className="text-center">
          This could take some time to complete. <br />
          You may leave this page and continue working and come back soon
        </Paragraph>
        <ul className="flex flex-col gap-y-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center justify-end">
              <Small displayType="loud">Short Term Goal Generation: </Small>
            </div>
            <div className="flex items-center justify-start">
              {stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices["Pending"] ? (
                <Badge text={"Pending"} appearance={"neutral"} />
              ) : stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices["GoalPredictionPending"] ? (
                <Badge dot text={"Generating"} appearance={"ai"} />
              ) : stgStatus === ApiReportTreatmentPlanAiStgPredictionStatusChoices["GoalPredictionPredicted"] ? (
                <Badge text={"Done"} appearance={"success"} />
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center justify-end">
              <Small displayType="loud">Target Generation: </Small>
            </div>
            <div className="flex items-center justify-start">
              {targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices["Pending"] ? (
                <Badge text={"Pending"} appearance={"neutral"} />
              ) : targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices["GoalPredictionPending"] ? (
                <Badge dot text={"Generating"} appearance={"ai"} />
              ) : targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices["Failure"] ? (
                <Badge dot text={"Error"} appearance={"error"} />
              ) : targetStatus === ApiReportTreatmentPlanAiTargetPredictionStatusChoices["GoalPredictionPredicted"] ? (
                <Badge text={"Done"} appearance={"success"} />
              ) : null}
            </div>
          </div>
        </ul>
        <Button
          appearance="primary"
          text="Return to Assessments"
          onClick={() => {
            go("/assessment-reports-v2");
          }}
        />
      </div>
    );
  }

  const validateGoals = (goals: LongTermGoalTypeEdge[]): boolean => {
    return goals.every((ltgGoal) => {
      const goal = ltgGoal?.node;
      // Check if the long-term goal has at least one short-term goal
      // Allow to generate report without targets attached to short term goals
      if (goal?.shorttermgoalSet.edges.length === 0) {
        return false;
      }
      return true;
    });
  };

  return (
    <>
      {" "}
      {!isCatalightUser ? (
        <ConfirmGenerationDialog
          type={ReportPageLocationEnums.ReportPart_2}
          promptDialogOpen={promptDialogOpen}
          setPromptDialogOpen={setPromptDialogOpen}
        />
      ) : (
        <></>
      )}
      <GoalBankShortTermGoalsSaveToFolder
        key={selectedSTG?.id}
        open={showShortTermGoalsSaveToFolderDialog}
        aiShortTermGoals={[]}
        shortTermGoals={[selectedSTG as ShortTermGoalType]}
        onCloseClick={() => {
          setSelectedSTG(undefined);
          setShowShortTermGoalsSaveToFolderDialog(false);
        }}
        refetch={async () => await queryClient.refetchQueries()}
      />
      <GoalBankTargetsSaveToFolder
        key={selectedTarget?.id}
        open={showTargetsSaveToFolderDialog}
        aiTargets={[]}
        targets={[selectedTarget as TargetType]}
        onCloseClick={() => {
          setSelectedTarget(undefined);
          setShowTargetsSaveToFolderDialog(false);
        }}
        refetch={async () => await queryClient.refetchQueries()}
      />
      <div className="h-full">
        <div className="flex flex-row gap-2 items-center">
          <Heading type="h3">Short Term Goals</Heading>

          <Tooltip
            text="Short Term Goals are the incremental steps toward achieving the Long Term Goals.
                  They are more immediate objectives that can be attained in a shorter time frame."
          ></Tooltip>
        </div>

        <div className="mt-4">
          {goalList.length === 0 && isSuccess ? (
            <NoDataCard
              title={`You have no ${goalCategoryName}s added`}
              description={
                <span>
                  {goalCategoryName}s that you add will end up here. <br />
                  Add a {goalCategoryName} from our suggestions, or create one of your own to get started
                </span>
              }
            >
              {warnUser && (
                <WarningCard content={`You must have at least one ${goalCategoryName} in your treatment plan`} />
              )}
            </NoDataCard>
          ) : (
            <></>
          )}
          {goalList.map((record, ltgIndex) => {
            const aiShortTermGoalList = record?.node?.aisuggestedshorttermgoalsSet?.edges || [];
            return (
              <>
                <div className="w-full flex items-center mt-8 mb-4 ">
                  <Divider className="w-full" borderClassName="border-limestone-100" />
                  <Small displayType="loud" className="mx-4 whitespace-nowrap">
                    {goalCategoryName} {ltgIndex + 1}
                  </Small>
                  <Divider className="w-full" borderClassName="border-limestone-100" />
                </div>
                <ShortTermGoalParentCard
                  useLongTermGoals={useLongTermGoals}
                  learnerId={learnerId ?? "<missing-learner-id>"}
                  reportId={assessmentId}
                  uiGoalId={getHierarchicalIdentifier(ltgIndex)}
                  longTermGoalId={record?.node?.id}
                  longTermGoalBankId={record?.node?.longTermGoalBank?.id}
                  refetch={refetch}
                  key={record?.node?.id}
                  title={record?.node?.goalName || "N/A"}
                  aiGoalList={aiShortTermGoalList as AiSuggestedShortTermGoalsTypeEdge[]}
                  aiGoals={aiShortTermGoalList
                    ?.sort((_, gb) => (gb?.node?.isShortgoalAdded ? 1 : -1))
                    ?.map((goal) => ({
                      body: goal?.node?.description || "",
                      id: goal?.node?.id || "",
                      subtitle: "",
                      title: goal?.node?.goalName || "",
                    }))}
                  // aiGoalsBeingUsed={aiGoalCardsBeingUsed}
                  // TODO: Bring this back when isGoalAdded gets correctly flipped on delete by the FDM
                  aiGoalsBeingUsed={
                    aiShortTermGoalList
                      .filter((g) => g?.node?.isShortgoalAdded)
                      ?.sort((_, gb) => (gb?.node?.isShortgoalAdded ? 1 : -1))
                      .map((g) => g?.node?.id) as string[]
                  }
                  addAiGoals={async (goals) => {
                    try {
                      await addSuggestedShortGoalsMutation.mutateAsync({
                        reportId: assessmentId || "<missing-report-id>",
                        aiSuggestedShortGoalIds: goals.map((goal) => goal.id),
                        longTermGoalId: record?.node?.id || "<missing-ltg-id>",
                      });
                      await refetch();
                    } catch (err) {
                      console.error("Error adding AI goals", err);
                      throw new Error(String(err));
                    }
                  }}
                  subtitle={
                    useLongTermGoals ? convertReadableString(record?.node?.goalType) : record?.node?.description ?? ""
                  }
                  onButtonClick={() => {
                    setSelectedLTG(record?.node as LongTermGoalType);
                    setSelectedSTG(undefined);
                    setSelectedTarget(undefined);
                    setPanelOpen(true);
                  }}
                  onAiGoalUpDownVote={async (goal, vote) => {
                    datadogRum.addAction("ai-vote", {
                      type: "stg",
                      direction: vote ? "up-vote" : "down-vote",
                      field: goal.title,
                    });
                    setAiFeedbackVote(vote);
                    setSelectedAiGoalId(goal.id);
                    setAiFeedbackDialogOpen(true);
                  }}
                >
                  <>
                    {record?.node?.shorttermgoalSet.edges.length ? (
                      <>
                        <div className="flex w-full items-center gap-x-4">
                          <Small displayType="loud">Selected</Small>
                          <Divider borderClassName="border-limestone-100" className="w-full" />
                        </div>
                        <div className="grid snap-x grid-cols-3 gap-4 rounded-md bg-limestone-50 p-2">
                          {record.node.shorttermgoalSet.edges.map((shortGoal, stgIndex) => {
                            return (
                              <ShortTermGoalCard
                                useLongTermGoals={useLongTermGoals}
                                uiGoalId={getHierarchicalIdentifier(ltgIndex, stgIndex)}
                                onSaveToFolder={() => {
                                  setSelectedLTG(record.node as LongTermGoalType);
                                  setSelectedSTG(shortGoal?.node as ShortTermGoalType);
                                  setShowShortTermGoalsSaveToFolderDialog(true);
                                }}
                                key={shortGoal?.node?.id}
                                goalObject={shortGoal?.node as ShortTermGoalType}
                                refetchGoals={async () => {
                                  await refetch();
                                }}
                                onClick={() => {
                                  setSelectedLTG(record.node as LongTermGoalType);
                                  setSelectedSTG(shortGoal?.node as ShortTermGoalType);
                                  setPanelOpen(true);
                                }}
                              >
                                <div className="flex flex-start flex-col h-full overflow-y-auto">
                                  <div className="flex flex-row justify-evenly">
                                    <AddTargetsBank
                                      key={shortGoal?.node?.id}
                                      learnerId={learnerId ?? "<missing-learner-id>"}
                                      shortTermGoalId={shortGoal?.node?.id ?? ""}
                                      shortTermGoalBankId={shortGoal?.node?.shortTermGoalBank?.id}
                                      assessmentId={assessmentId ?? "<missing-assessment-id>"}
                                      onAdd={async () => {
                                        await refetch();
                                      }}
                                      targets={
                                        shortGoal?.node?.targetSet?.edges.filter(
                                          (edge) => !!edge?.node && !edge?.node.aiGenerated,
                                        ) as TargetTypeEdge[]
                                      }
                                    />
                                    <Button
                                      text="+ Custom Target"
                                      buttonAction="action"
                                      appearance="text"
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                        setSelectedSTG(shortGoal?.node as ShortTermGoalType);
                                        setSelectedTarget(undefined);
                                        setTargetPanelOpen(true);
                                      }}
                                    />
                                  </div>

                                  <div>
                                    {shortGoal?.node?.targetSet?.edges.length ? (
                                      <>
                                        {shortGoal.node.targetSet.edges.map((target, targetIndex) => {
                                          return (
                                            <TargetCard
                                              onSaveToFolder={() => {
                                                setSelectedTarget(target?.node as TargetType);
                                                setShowTargetsSaveToFolderDialog(true);
                                              }}
                                              uiTargetId={getHierarchicalIdentifier(ltgIndex, stgIndex, targetIndex)}
                                              key={target?.node?.id}
                                              title={target?.node?.targetName || ""}
                                              targetId={target?.node?.id || "<missing-target-id>"}
                                              refetchGoals={async () => {
                                                await refetch();
                                              }}
                                              onClick={() => {
                                                setSelectedSTG(shortGoal?.node as ShortTermGoalType);
                                                setSelectedTarget(target?.node as TargetType);
                                                setTargetPanelOpen(true);
                                              }}
                                            />
                                          );
                                        })}
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </ShortTermGoalCard>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <NoDataCard
                        title="You have no short term goals added"
                        description={
                          <span>
                            Short term goal that you add will end up here. <br />
                            Add a short term goal from our suggestions, or create one of your own to get started
                          </span>
                        }
                      >
                        {warnUser && (
                          <WarningCard content="You must have at least one short term goal per long term goal in your treatment plan" />
                        )}
                      </NoDataCard>
                    )}
                  </>
                </ShortTermGoalParentCard>
              </>
            );
          })}

          <ShortTermGoalPanel
            title={selectedSTG ? "Edit Short Term Goal" : "Add Short Term Goal"}
            isPanelOpen={isPanelOpen}
            setPanelOpen={setPanelOpen}
            selectedGoal={selectedSTG}
            refetchLongTermGoalsList={refetch}
            longTermGoal={selectedLTG}
          />
          <TargetPanel
            title={selectedTarget ? "Edit Target" : "Add Target"}
            isTargetPanelOpen={isTargetPanelOpen}
            setTargetPanelOpen={setTargetPanelOpen}
            shortTermGoal={selectedSTG}
            selectedTarget={selectedTarget}
            refetchLongTermGoalsList={refetch}
          />
          <AiFeedbackDialog
            isDialogOpen={aiFeedbackDialogOpen}
            setDialogOpen={setAiFeedbackDialogOpen}
            positiveFeedback={aiFeedbackVote}
            objectType="stg"
            objectId={selectedAiGoalId}
          />
        </div>
        <FooterButtonRow
          primaryButtonAction={() => {
            return new Promise<void>((resolve) => {
              if (!validateGoals(goalList as LongTermGoalTypeEdge[]) && useLongTermGoals) {
                setWarnUser(true);
                notifyWarn("Short Term Goals Missing");
                resolve();
              } else {
                setWarnUser(false);
                setPromptDialogOpen(true);
                resolve();
              }
            });
          }}
          skipNavigate={!isCatalightUser}
        />
      </div>
    </>
  );
};
