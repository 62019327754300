import React, { useState } from "react";

import { SearchableDropdown } from "@fronterahealth/frontera-ui-components";

import { CommentThread } from "@pages/VideoSessions/components/Comments/CommentThread";
import { Comment, transformCommentData } from "@pages/VideoSessions/components/Comments/helper";
import { NoData } from "@pages/VideoSessions/components/NoData/NoData";
import { DataLoading } from "@pages/VideoSessions/components/Utils/DataLoading";
import { EMOJI_BUTTONS } from "@pages/VideoSessions/const";
import { useVideoSessionData } from "@providers/VideoSessionProvider";

interface VideoCommentsProps {
  onClick: (time: number) => void;
  activeCommentId?: string | null;
}

export const VideoComments: React.FC<VideoCommentsProps> = ({ onClick, activeCommentId }) => {
  const {
    commentsByMediaIdQuery: { data, isFetching },
    setFilteredCommentType,
    filteredCommentType,
  } = useVideoSessionData();
  const [localActiveCommentId, setLocalActiveCommentId] = useState<string | null>(null);

  const commentsData: Comment[] = data ? transformCommentData(data) : [];

  const effectiveActiveCommentId = activeCommentId || localActiveCommentId;

  const handleActiveCommentChange = (commentId: string | null) => {
    setLocalActiveCommentId(commentId);
  };

  return (
    <div className="w-full lg:p-6 max-[640px]:p-3 flex flex-col gap-4 lg:bg-white bg-limestone-50 max-lg:min-h-[calc(100vh-18rem)]">
      <div className="flex flex-col max-[640px]:gap-4 gap-2">
        {filteredCommentType.length || commentsData.length ? (
          <div className="relative border-b border-gray-100 sm:pb-2 shadow-[0_4px_8px_0px_rgba(0, 0, 0, 0.10)]">
            <SearchableDropdown
              items={EMOJI_BUTTONS.map((item) => ({ id: item.value, label: `${item.emoji} ${item.text}` }))}
              label="Comment Type"
              placeholder=""
              onSelectionChange={(val) => setFilteredCommentType(val)}
              showSearch={false}
              selectedItems={filteredCommentType}
            />
          </div>
        ) : null}
        {isFetching ? (
          <DataLoading text="Activity Loading" />
        ) : commentsData.length ? (
          <div className="lg:overflow-y-auto lg:h-[calc(100vh-18rem)] flex flex-col sm:mt-3">
            <CommentThread
              comments={commentsData}
              onClick={onClick}
              activeCommentId={effectiveActiveCommentId}
              onActiveCommentChange={handleActiveCommentChange}
            />
          </div>
        ) : filteredCommentType.length ? (
          <NoData
            title="No Comments Found"
            description="No comments matched your filters. You can try broadening your filter choices to see activity."
            showBorder={false}
          />
        ) : (
          <NoData
            title="No Activity Yet!"
            description="It looks like no feedback or comments have been added to the video yet. Start the conversation by leaving your first comment, or check back later for updates from others."
          />
        )}
      </div>
    </div>
  );
};
